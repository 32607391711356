export async function getUnreadNotes(user_id) {
     try {
          const response = await fetch("https://www.superbuxx.com:3030/get-notifications/" + user_id, {
               method: "GET",
          });
         
          const res = await response.json();
          return res.unread
               
          
     } catch (error) {
          console.error(error)
          return false
     }
}