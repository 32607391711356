export async function getExchOrderData(uid) {
     try {
          const response = await fetch("https://www.superbuxx.com:3030/get-order-data/" + uid, {
               method: "GET",
               headers: { "Content-Type": "application/json" }
          });
          if (response.ok) {
               const res = await response.json();
               if (res.sucesso) {
                    return res.orderData
               } else {
                    return false
               }
          } else {
               return false
          }
     } catch (error) {
          console.error(error)
          return false
     }
}