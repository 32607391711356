import React from "react";
import { useTranslation } from "react-i18next";

const FaqBet = () => {
     const { t } = useTranslation()
     // Perguntas e respostas
     const faq = [
          {
               ask: t('betask1'),
               res: t('betresp1')
          },
          {
               ask: t('betask2'),
               res: t('betresp2')
          },
          {
               ask: t('betask3'),
               res: t('betresp3')
          },
          {
               ask: t('betask4'),
               res: t('betresp4')
          },
          {
               ask: t('betask5'),
               res: t('betresp5')
          },
          {
               ask: t('betask6'),
               res: t('betresp6')
          }
     ]

     let show = "show"
     let expanded = true

     return (
          <div className="container">
               <div className="row">

                    <div className="col-12">
                         <div className="block-header">
                              <h2 className="block-title">{t('faqtitle')}</h2>
                         </div>
                    </div>

                    <div className="col-12">
                         <div className="accordion" id="common-questions-accordion">
                              {faq.map((item, index) => {
                                   // Inicialize as variáveis show e expanded
                                   show = "";
                                   expanded = false;

                                   return (
                                        <div className="accordion-item" key={index}>
                                             <h2 className="accordion-header" id={`heading${index}`}>
                                                  <button
                                                       className="accordion-button"
                                                       type="button"
                                                       data-bs-toggle="collapse"
                                                       data-bs-target={`#collapse${index}`}
                                                       aria-expanded={expanded}
                                                       aria-controls={`collapse${index}`}
                                                  >
                                                       {item.ask}
                                                       <span className="chevron-icon">
                                                            <img src="../src/assets/icons/chevron-down.svg" alt="Chevron Icon" />
                                                       </span>
                                                  </button>
                                             </h2>
                                             <div
                                                  id={`collapse${index}`}
                                                  className={`accordion-collapse collapse ${show}`}
                                                  aria-labelledby={`heading${index}`}
                                                  data-bs-parent="#common-questions-accordion"
                                             >
                                                  <div className="accordion-body">{item.res}</div>
                                             </div>
                                        </div>
                                   );
                              })}
                         </div>
                    </div>
               </div>
               <div className="row">
                    <div className="col-12">
                         <div className="button-see-all">
                              <button className="btn btn-custom">{t('seeall')}</button>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default FaqBet