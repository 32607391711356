export async function verifyUser(uid) {
     try {
          const response = await fetch("https://www.superbuxx.com:3030/verify-user/" + uid, {
               method: "GET",
          });
          const resposta = await response.json();
          if (resposta.existe == true) {
               return true
          } else {
               return false
          }
     } catch (error) {
          console.error(error)
          return false
     }
}