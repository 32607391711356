import React from "react";

const HowCashback = () => {
     return (

          <div id="como-funciona" className="container text-center mt-5">

               <h2 className="title">COMO FUNCIONA NA SUPER BUXX</h2>

               <div className="cards">
                    <div className="card-content">
                         <div className="icon mb-3">
                              <img src="../src/assets/images/como-funciona-1.png" alt="Register Icon" className="img-fluid" />
                         </div>
                         <h5 className="card-title">INSCREVA-SE NA SUPERBUXX</h5>
                         <p className="card-text">Crie sua conta na plataforma Superbuxx para começar a aproveitar as ofertas de cashback.</p>
                    </div>
                    <div className="card-divider">
                         <div className="card-divider-icon">
                              <img src="../src/assets/icons/chevron-rigth.svg" alt="" />
                         </div>
                    </div>
                    <div className="card-content">
                         <div className="icon mb-3">
                              <img src="../src/assets/images/como-funciona-2.png" alt="Earn Buxx Icon" className="img-fluid" />
                         </div>
                         <h5 className="card-title">COMPRE ONLINE</h5>
                         <p className="card-text">Faça suas compras em lojas parceiras utilizando os links fornecidos pelo Superbuxx. Isso pode incluir desde compras de eletrônicos até assinaturas de serviços e até mesmo cashback filme.</p>
                    </div>
                    <div className="card-divider">
                         <div className="card-divider-icon">
                              <img src="../src/assets/icons/chevron-rigth.svg" alt="" />
                         </div>
                    </div>
                    <div className="card-content">
                         <div className="icon mb-3">
                              <img src="../src/assets/images/como-funciona-3.png" alt="" className="img-fluid" />
                         </div>
                         <h5 className="card-title">RECEBA PARTE DO DINHEIRO DE VOLTA</h5>
                         <p className="card-text">Após a confirmação da compra, uma porcentagem do valor gasto é devolvida para você na forma de cashback dinheiro de volta. Esse valor pode ser acumulado e retirado para sua conta bancária ou ser utilizado em futuras compras.</p>
                    </div>
               </div>
          </div>
     )
}

export default HowCashback