// Formulário de edição de dados do usuário
import { AuthContext } from "../../contexts/AuthContext";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { getUserData } from "../../components/functions/GetUserData";
import Cropper from "react-easy-crop";
import "../../helpers/cropImage";
import { getCroppedImg } from "../../helpers/cropImage";
import { toast } from "react-toastify";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { sendPasswordResetEmail } from "firebase/auth";
import { updateMailStatus } from "../../components/functions/UpdateMailStatus";
import { paisById } from "../../components/functions/GetPaisData";
import { useTranslation } from "react-i18next";
import SeoTags from "../common/SeoTags";

const UserAccount = props => {
     const { t } = useTranslation()
     const { usuario } = useContext(AuthContext)
     const { uid, email } = usuario
     const statusMail = usuario.emailVerified
     const [mailStatusLabel, setMailStatusLabel] = useState("")
     const [showModal, setShowModal] = useState(false)

     const [username, setUserName] = useState("")
     const [nickname, setNickname] = useState("")
     const [dtbirth, setDtBirth] = useState("")
     const [userData, setUserData] = useState("")
     const [imageSrc, setImageSrc] = useState('/image/icons/avatar.png')
     const [imageData, setImageData] = useState({ name: '', url: '' })
     const [displayImage, setDisplayImage] = useState("")
     const [avatarPath, setAvatarPath] = useState("")
     const [paypal_link, setPaypalLink] = useState("")
     const [gender, setGender] = useState(0)
     const [idPais, setIdPais] = useState("")
     const [nomePais, setNomePais] = useState("")
     const [saldo, setSaldo] = useState(0)

     const [crop, setCrop] = useState({ x: 0, y: 0 })
     const [zoom, setZoom] = useState(1)
     const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
     const [croppedImage, setCroppedImage] = useState(null)
     const [isCropping, setIsCropping] = useState(false)
     const [btConfirmaStatus, setBtconfirmaStatus] = useState(true)

     useEffect(() => {
          if (statusMail === true) {
               var labelMail = <span style={{ color: 'green', fontWeight: 'bold' }}>{t('verificado')}</span>
               // atualiza tabela 'users'
               updateMailStatus(uid);
          } else {
               setShowModal(true)
               var labelMail = <span style={{ color: 'red', fontWeight: 'bold' }}>{t('naoverificado')}</span>
          }
          setMailStatusLabel(labelMail);

     }, [statusMail])

     const onCropComplete = (croppedArea, croppedAreaPixels) => {
          setCroppedAreaPixels(croppedAreaPixels)
     }

     const updateCroppedImg = async () => {
          try {
               const croppedImage = await getCroppedImg(
                    imageData.url,
                    croppedAreaPixels
               )
               setImageSrc(croppedImage);
               setIsCropping(false)
               setCroppedImage(croppedImage)
               uploadCroppedImage(croppedImage)
               setBtconfirmaStatus(true)
          } catch (e) {
               console.error(e)
          }
     }

     const handleImageChange = (e) => {
          const file = e.target.files[0];
          setIsCropping(true)
          setBtconfirmaStatus(false)
          if (file && file.size <= 2048 * 1024) { // Verifica se o arquivo é menor que 500KB
               const reader = new FileReader();
               reader.onloadend = () => {
                    //setImagePreview(reader.result);
                    setImageData({ name: file.name, url: reader.result })
                    setDisplayImage(reader.result)
               };
               reader.readAsDataURL(file);
          } else {
               console.log('O arquivo é muito grande');
          }
     };

     const uploadCroppedImage = async (croppedImage) => {
          const formData = new FormData();
          formData.append('uid', uid);

          // Converte a URL do objeto da imagem recortada em um Blob
          const response = await fetch(croppedImage);
          const blob = await response.blob();

          // Adiciona o Blob ao FormData
          formData.append('image', blob, 'croppedImage.jpg');

          // Envia o FormData para o servidor
          const imageResponse = await fetch("https://www.superbuxx.com:3030/upload-avatar", {
               method: "POST",
               body: formData,
          });
     }

     async function gravaDados(uid, user_name, nickname, dt_birth, paypal_link, gender) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/user-update", {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ uid, user_name, nickname, dt_birth, paypal_link, gender }),
               });
               toast.success(t('userwrng1'), { theme: "dark" });
          } catch (error) {
               console.error(error)
               // Grava dados em log
          }
     }

     // Restaura dados do User
     useEffect(() => {
          getUserData(uid).then((data) => {
               setUserData(data);
               if (data) {
                    setUserName(data.user_name || '');
                    setNickname(data.nickname || '');
                    setDtBirth(data.dt_birth || '');
                    setAvatarPath(data.avatar_path || '');
                    setPaypalLink(data.paypal_link || '');
                    setGender(data.gender || 0);
                    setIdPais(data.id_country || '');
                    setSaldo(data.bux_balance || 0);
               }
          });
     }, []);

     useEffect(() => {
          if (avatarPath) {
               let imageUrl = 'https://www.superbuxx.com:3030' + avatarPath;
               setImageSrc(imageUrl);
          }
     }, [avatarPath]);

     useEffect(() => {
          if (idPais) {
               paisById(idPais)
                    .then((pais) => {
                         setNomePais(pais.nome);
                    })
                    .catch((error) => {
                         console.error('Erro ao buscar o país:', error);
                    });
          }
     }, [idPais]);

     const recuperaSenha = async () => {
          // Antes de tudo, verifica se email está cadastrado
          const auth = getAuth();
          try {
               await sendPasswordResetEmail(auth, email);
               // setSignupres("Verifique seu email para recuperar sua senha.");
               toast.success(t('userwrng2'), { theme: "dark" });
          } catch (error) {
               console.log(error);
               const errorCode = error.code;
               const errorMessage = error.message;
               toast.warn(t('userwrng3'), { theme: "dark" });
               // setSignupres(errorCode + ": " + errorMessage);
               // Grava dados em log (api única)
          }
     }

     return (
          <Fragment>
               <SeoTags
                    url="https://www.superbuxx.com/useraccount"
               />
               <div className="container-fluid banner-header text-white text-center py-3">
                    <h1>{t('userprofiletitle')}</h1>
               </div>
               <div className="container-fluid container-spacing">
                    <form encType="multipart/form-data">
                         <div className="row">
                              <div className="col-md-4 container-profile">
                                   <div className="profile-img text-center">
                                        <img src={imageSrc} alt="Profile Picture" />
                                   </div>
                                   <div className="profile-info">
                                        <h3>{username}</h3>

                                        <div className="exibe-saldo-useraccount mb-3">
                                             <img src="/image/icons/moeda.webp" alt="Buxx" style={{ width: "25px", height: "auto", marginTop: "0" }} />
                                             <span style={{ marginLeft: "0.6rem", color: "white" }}>{saldo} BUXX</span>
                                        </div>

                                        <input
                                             className="form-control sbx-group-input"
                                             type="file"
                                             name="avatar"
                                             id="avatar"
                                             accept=".jpg,.png,.gif,.webp"
                                             onChange={handleImageChange}
                                             style={{ display: 'none' }}
                                        />
                                        <label htmlFor="avatar" className="mt-3 ms-0">
                                             <span className="btn btn-to-check mb-0">{t('uploadavatar')}</span>
                                        </label>

                                        <p className="text-center mt-3">{nomePais}</p>
                                        <hr className="full-width-hr mb-2" />
                                   </div>
                                   <div className="profile-links">
                                        <span onClick={recuperaSenha} className="sbx-custom-link">{t('wantchangepsw')}</span>
                                        {/* <a href="/verifycell">{t('wantupdtmycell')}</a> */}
                                   </div>
                              </div>
                              <div className="col-md-4 sbx-container-form">
                                   {/* <form encType="multipart/form-data"> */}
                                   <div className="col-12 mb-3">
                                        <div className="input-field">
                                             <label htmlFor="exampleFormControlInput1" className="form-label">{t('nameilabel')}</label>
                                             <input
                                                  type="name"
                                                  className="form-control"
                                                  id="exampleFormControlInput1"
                                                  name="username"
                                                  placeholder={t('namepholder')}
                                                  value={username}
                                                  onChange={(e) => setUserName(e.target.value)}
                                             />
                                        </div>
                                   </div>
                                   <div className="col-12 mb-3">
                                        <div className="input-field">
                                             <label htmlFor="exampleFormControlInput1" className="form-label">{t('nicknameilabel')}</label>
                                             <input
                                                  type="text"
                                                  className="form-control"
                                                  id="exampleFormControlInput1"
                                                  name="nickname"
                                                  placeholder={t('nicknameilabel')}
                                                  maxLength={15}
                                                  value={nickname}
                                                  onChange={(e) => setNickname(e.target.value)}
                                             />
                                        </div>
                                   </div>
                                   <div className="col-12 mb-3">
                                        <div className="input-field">
                                             <label htmlFor="exampleFormControlInput1" className="form-label">{t('birthdayilabel')}</label>
                                             <input type="date"
                                                  className="form-control"
                                                  id="exampleFormControlInput1"
                                                  name="dtbirth"
                                                  value={dtbirth}
                                                  onChange={(e) => setDtBirth(e.target.value)}
                                             />
                                        </div>
                                   </div>
                                   <div className="col-12 mb-3">
                                        <label htmlFor="gender" className="form-label">{t('genderilabel')}</label>
                                        <select
                                             name="gender"
                                             className="form-select custom-select form-select-gender"
                                             id="gender"
                                             value={gender}
                                             onChange={(e) => setGender(e.target.value)}
                                        >
                                             <option value="0">{t('opcional')}</option>
                                             <option value="1">{t('female')}</option>
                                             <option value="2">{t('male')}</option>
                                             <option value="3">{t('other')}</option>
                                        </select>
                                   </div>
                                   <div className="col-12 mb-3">
                                        <div className="input-field">
                                             <label htmlFor="exampleFormControlInput1" className="form-label">{t('email')} {mailStatusLabel}</label>
                                             <input
                                                  type="email"
                                                  className="form-control"
                                                  id="exampleFormControlInput1"
                                                  name="emai"
                                                  value={email}
                                                  disabled={true}
                                             />
                                        </div>
                                   </div>
                                   {/* </form> */}
                              </div>
                              <div className="col-md-4 sbx-container-img mb-5">
                                   <div className="sbx-images-container">
                                        <p>{t('profileimage')}</p>
                                        <div className="sbx-images-box">
                                             <div className="row">
                                                  <div className="col">
                                                       {isCropping ? (
                                                            <div className="mb-3 col-sm">
                                                                 <div className="crop-container">
                                                                      <Cropper
                                                                           image={imageData.url}
                                                                           crop={crop}
                                                                           zoom={zoom}
                                                                           aspect={1 / 1}
                                                                           onCropChange={setCrop}
                                                                           onCropComplete={onCropComplete}
                                                                           onZoomChange={setZoom}
                                                                      />
                                                                 </div>
                                                                 <div className="crop-controls">
                                                                      <input
                                                                           type="range"
                                                                           value={zoom}
                                                                           min={1}
                                                                           max={3}
                                                                           step={0.1}
                                                                           aria-labelledby="Zoom"
                                                                           onChange={(e) => {
                                                                                setZoom(e.target.value)
                                                                           }}
                                                                           className="zoom-range"
                                                                      />
                                                                 </div>
                                                                 <div>
                                                                      <button type="button" className="sbx-label-file w-100" onClick={updateCroppedImg} disabled={btConfirmaStatus} >{t('confirmlink')}</button>
                                                                 </div>
                                                            </div>
                                                       ) : (
                                                            <div className="col-sm mb-3">
                                                                 <div className="crop-container">
                                                                      <img src={imageSrc} className="w-100" />
                                                                 </div>
                                                            </div>
                                                       )}

                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div className="container-sm">
                              <button type="button" className="btn btn-light col-12 mt-2" onClick={() => gravaDados(uid, username, nickname, dtbirth, paypal_link, gender)}>{t('savebt')}</button>
                         </div>
                    </form>
               </div>
          </Fragment>
     )
}

export default UserAccount