import React, { useEffect, useState, useRef } from "react";
import OfferCardOWs from "../../components/misc/OfferCardOWs";
import { getDevice } from "../../components/functions/GetDevice";
import { AuthContext } from "../../contexts/AuthContext";
import { useContext } from "react";
import { Fragment } from "react";
import Parceiros from "../common/Parceiros";
import HowBet from "../common/HowBet";
import Stats from "../common/Stats";
import AboutBet from "../common/AboutBet";
import DiveBet from "../common/DiveBet";
import PageNavigator from "../../components/pagination/PageNavigator";
import SbxSpinner from "../common/SbxSpinner";
import SeoTags from "../common/SeoTags";
import FaqBet from "../common/FaqBet";
import { useTranslation } from "react-i18next";
import ShowWarning from "./ShowWarning";
import { getTorox } from "../../components/functions/GetTorox";


const ToroxTest = () => {
     const { t } = useTranslation()
     // const { idIdioma } = useContext(AuthContext)
     const { isoPais } = useContext(AuthContext)
     const [currentPage, setCurrentPage] = useState(0)
     const itemsPerPage = 1000
     const listRef = useRef(null)
     let totalPages = 1
     
     // TAGS SEO
     const title = t('test_torox_title')
     const description = t('test_torox_desc')

     const [ofertas, setOfertas] = useState([]);

     useEffect(() => {
          if (isoPais) {
               getTorox(isoPais, 'all', 'survey', false, false, 'amount', false)
                    .then((offerData) => {
                         setOfertas(offerData);
                    })
                    .catch((error) => {
                         console.error("Erro ao buscar dadosde Torox:", error);
                    });
          }
     }, [isoPais]);


     const handlePageChange = (page) => {
          setCurrentPage(page);
          if (listRef.current) {
               listRef.current.scrollIntoView({ behavior: 'smooth' });
          }
     };

     const handleNextClick = () => {
          if (currentPage + 1 < totalPages) {
               setCurrentPage(currentPage + 1);
          }
          if (listRef.current) {
               listRef.current.scrollIntoView({ behavior: 'smooth' });
          }
     };

     const handlePreviousClick = () => {
          if (currentPage > 0) {
               setCurrentPage(currentPage - 1);
          }
          if (listRef.current) {
               listRef.current.scrollIntoView({ behavior: 'smooth' });
          }
     };

     return (
          <Fragment>
               <SeoTags
                    title={title}
                    description={description}
                    url="https://www.superbuxx.com/toroxtest"
               />
               <div className="container-fluid banner-header text-white text-center py-3">
                    <h1>{t('test_torox_title')}</h1>
                    <div className="paragrafo-homepage-log-out">
                         <p>{t('test_torox_desc')}</p>
                    </div>
               </div>
               <div className="container spancing-banner-maior" ref={listRef}>
                    <ul className="row cards-list">
                         {ofertas === null ? (
                              <SbxSpinner />
                         ) : (
                              <>
                                   {ofertas === undefined ? (
                                        <ShowWarning
                                             nomeCategoria={"Torox"}
                                        />

                                   ) : (
                                        ofertas
                                             .slice(
                                                  currentPage * itemsPerPage,
                                                  currentPage * itemsPerPage + itemsPerPage
                                             )
                                             .map((ad, index) => (

                                                  <li key={index} className="col-12 col-xl-3 col-lg-6 mb-4">

                                                       <OfferCardOWs
                                                            cor="w"
                                                            oName={ad.offer_name}
                                                            creative={ad.image_url}
                                                            info={ad.offer_desc}
                                                            payout={ad.amount}
                                                            offer_id={ad.offer_id}
                                                            os={ad.device || ad.platform}
                                                            url={ad.offer_url_easy}
                                                       />
                                                  </li>
                                             )))}
                              </>
                         )}
                    </ul>
                    <PageNavigator
                         totalPages={totalPages}
                         currentPage={currentPage}
                         handlePreviousClick={handlePreviousClick}
                         handleNextClick={handleNextClick}
                         handlePageChange={handlePageChange}
                    />

                    <div className="container">
                         <div className="row parceiro-container spacing-box-top">
                              <div className="title-parceiros">
                                   <h2>{t('howbettitle')}</h2>
                              </div>
                              <Parceiros />
                         </div>
                    </div>
                    <HowBet />
                    <Stats />
                    <AboutBet />
                    <FaqBet />
                    <DiveBet />
               </div>
          </Fragment >
     );
};

export default ToroxTest;
