export async function getTorox(isoPais, platform, payoutType, verticalId, totalOffers, orderBy, orderDirection) {
     try {
          // Retorna campanhas com categoria 7 (destaques) e idioma português (3)
          const response = await fetch(`https://www.superbuxx.com:3030/get-torox/${isoPais}/${platform}/${payoutType}/${verticalId}/${totalOffers}/${orderBy}/${orderDirection}`, {
               method: "GET",
               headers: { "Content-Type": "application/json" }
          });

          const res = await response.json();
          return res

          // if (response.ok) {
          //      const res = await response.json();
          //      console.log("Res: ", res.adsTrans)
          //      return res;
          // } else {
          //      return false;
          // }
     } catch (error) {
          console.error(error)
          return false
     }
}