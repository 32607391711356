import { AuthContext } from "../../contexts/AuthContext";
import React, { useContext, useEffect, useState, useCallback, Fragment } from "react";
import { getUserHistory } from "../../components/functions/GetUserHistoryData";
import HistoryList from "../../components/data/HistoryLists";
import UserClickList from "../../components/data/UserClickList";
import { getUserClickData } from "../../components/functions/GetUserClickData";
import { useTranslation } from "react-i18next";

const UserHistory = props => {
     const {t} = useTranslation()
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario

     const [txn, setTxn] = useState([])
     const [hits, setHits] = useState([])

     const [dtIni, setDtIni] = useState("")
     const [dtFim, setDtFim] = useState("")
     const [campanha, setCampanha] = useState("")
     const [dtIni2, setDtIni2] = useState("")
     const [dtFim2, setDtFim2] = useState("")
     const [campanha2, setCampanha2] = useState("")

     const [activeKey, setActiveKey] = useState('confirmado');


     const [selectedStartDate, setSelectedStartDate] = useState(null)
     const [selectedEndDate, setSelectedEndDate] = useState(null)
     const [selectedStartDate2, setSelectedStartDate2] = useState(null)
     const [selectedEndDate2, setSelectedEndDate2] = useState(null)

     const resetFilters = useCallback(() => {
          setSelectedStartDate(null);
          setSelectedEndDate(null);
          setCampanha('');
          setDtIni("")
          setDtFim("")
     }, []);

     const resetFilters2 = useCallback(() => {
          setSelectedStartDate2(null);
          setSelectedEndDate2(null);
          setCampanha2('');
          setDtIni2("")
          setDtFim2("")
     }, []);



     useEffect(() => {
          getUserHistory(uid)
               .then((resultado) => {

                    //Filtrar por campanha
                    const filterByCampanha = resultado.filter(key => {
                         const campanhaLowerCase = campanha.toLowerCase();
                         const eventNameLowerCase = key.offer_name.toLowerCase();

                         return eventNameLowerCase.includes(campanhaLowerCase);
                    });

                    //Filtrar por data
                    const filterByDate = filterByCampanha.filter(key => {
                         const txnDate = new Date(key.offer_complete_time).setHours(0, 0, 0, 0)
                         const startDate = selectedStartDate ? new Date(selectedStartDate + 'T00:00:00').getTime() : null;
                         const endDate = selectedEndDate ? new Date(selectedEndDate + 'T23:59:59') : null;

                         // Caso não haja data inicial ou data inicial esteja preenchida e não haja data final
                         if (!startDate || (startDate && !endDate)) {
                              return (!startDate || txnDate === startDate);
                         }

                         // Caso haja data inicial e data final
                         return (txnDate >= startDate && txnDate <= endDate);
                    })

                    setTxn(filterByDate)

               })
               .catch((error) => {
                    console.error('Erro ao recuperar registros: ', error)
               })

     }, [campanha, selectedStartDate, selectedEndDate, resetFilters])


     useEffect(() => {
          getUserClickData(uid)
               .then((resultado) => {
                    console.log("Resultado: ", resultado)
                    //Filtrar por campanha
                    const filterByCampanha = resultado.filter(key => {
                         const campanhaLowerCase = campanha2.toLowerCase();
                         const eventNameLowerCase = key.offer_name.toLowerCase();
                         return eventNameLowerCase.includes(campanhaLowerCase);
                    });

                    // Filtrar por data
                    const filterByDate = filterByCampanha.filter(key => {
                         const txnDate = new Date(key.click_time).setHours(0, 0, 0, 0)
                         const startDate = selectedStartDate2 ? new Date(selectedStartDate2 + 'T00:00:00').getTime() : null;
                         const endDate = selectedEndDate2 ? new Date(selectedEndDate2 + 'T23:59:59') : null;

                         // Caso não haja data inicial ou data inicial esteja preenchida e não haja data final
                         if (!startDate || (startDate && !endDate)) {
                              return (!startDate || txnDate === startDate);
                         }

                         // Caso haja data inicial e data final
                         return (txnDate >= startDate && txnDate <= endDate);
                    })

                    setHits(filterByDate)
               })
     }, [selectedStartDate2, selectedEndDate2, resetFilters2, campanha2])

     return (
          <Fragment>
               <div className="container-fluid banner-header text-white text-center py-3">
                    <h1>{t('historyacttitleh1')}</h1>
                    <div className="row">
                         <div className="buttons-header">
                              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                   <li className="nav-item me-3 mb-2" role="presentation">
                                        <button className="btn btn-header-1 text-uppercase active" id="pills-completo-tab" data-bs-toggle="pill" data-bs-target="#pills-completo" type="button" role="tab" aria-controls="pills-completo" aria-selected="true">{t('completebt')}</button>
                                   </li>
                                   <li className="nav-item" role="presentation">
                                        <button className="btn btn-header-2 text-uppercase" id="pills-pendente-tab" data-bs-toggle="pill" data-bs-target="#pills-pendente" type="button" role="tab" aria-controls="pills-pendente" aria-selected="false">{t('pendingbt')}</button>
                                   </li>
                              </ul>
                         </div>
                    </div>
               </div>
               <div className="tab-content container-fluid container-spacing" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-completo" role="tabpanel" aria-labelledby="pills-completo-tab" tabindex="0">
                         <div className="row align-items-end mb-4">
                              <div className="col-12 col-md-3">
                                   <div className="input-field">
                                        <label htmlFor="formControlInput" className="form-label">{t('dtini')}</label>
                                        <input
                                             className="form-control"
                                             type="date"
                                             name="dt-ini"
                                             value={dtIni || ""}
                                             onChange={(e) => { setSelectedStartDate(e.target.value); setDtIni(e.target.value) }}
                                        />
                                   </div>
                              </div>
                              <div className="col-12 col-md-3">
                                   <div className="input-field">
                                        <label htmlFor="formControlInput" className="form-label">{t('dtfim')}</label>
                                        <input
                                             className="form-control"
                                             type="date"
                                             name="dt-fim"
                                             value={dtFim || ""}
                                             onChange={(e) => { setSelectedEndDate(e.target.value); setDtFim(e.target.value) }}
                                             disabled={!selectedStartDate}
                                        />

                                   </div>
                              </div>
                              <div className="col-12 col-md-3">
                                   <div className="input-field">
                                        <label htmlFor="formControlInput" className="form-label">{t('byoffer')}</label>
                                        <input
                                             className="form-control"
                                             type="text"
                                             name="offername"
                                             id="offername"
                                             value={campanha || ""}
                                             onChange={(e) => setCampanha(e.target.value)}
                                        />
                                   </div>
                              </div>
                              <div className="col-12 col-md-3">
                                   <button type="button" className="buxx-btn w-100 bt-clear-filter" onClick={resetFilters}>{t('clsfilters')}</button>
                              </div>
                         </div>
                         <HistoryList transactions={txn} />
                    </div>

                    <div className="tab-pane fade" id="pills-pendente" role="tabpanel" aria-labelledby="pills-pendente-tab" tabindex="0">
                         <div className="row align-items-end mb-4">
                              <div className="col-12 col-md-3">
                                   <div className="input-field">
                                        <label htmlFor="formControlInput" className="form-label">{t('dtini')}</label>
                                        <input
                                             className="form-control"
                                             type="date"
                                             name="dt-ini2"
                                             value={dtIni || ""}
                                             onChange={(e) => { setSelectedStartDate2(e.target.value); setDtIni2(e.target.value) }}
                                        />
                                   </div>
                              </div>
                              <div className="col-12 col-md-3">
                                   <div className="input-field">
                                        <label htmlFor="formControlInput" className="form-label">{t('dtfim')}</label>
                                        <input
                                             className="form-control"
                                             type="date"
                                             name="dt-fim"
                                             value={dtFim || ""}
                                             onChange={(e) => { setSelectedEndDate2(e.target.value); setDtFim2(e.target.value) }}
                                             disabled={!selectedStartDate2}
                                        />

                                   </div>
                              </div>
                              <div className="col-12 col-md-3">
                                   <div className="input-field">
                                        <label htmlFor="formControlInput" className="form-label">{t('byoffer')}</label>
                                        <input
                                             className="form-control"
                                             type="text"
                                             name="offername2"
                                             id="offername2"
                                             value={campanha2 || ""}
                                             onChange={(e) => setCampanha2(e.target.value)}
                                        />
                                   </div>
                              </div>
                              <div className="col-12 col-md-3">
                                   <button type="button" className="buxx-btn w-100 bt-clear-filter" onClick={resetFilters2}>{t('clsfilters')}</button>
                              </div>
                         </div>
                         <UserClickList clicks={hits} />
                    </div>
               </div>

          </Fragment>
     )
}

export default UserHistory