export async function getSwaarm(idCategoria, idIdioma, device, iso) {
     try {
          // Retorna campanhas com categoria 7 (destaques) e idioma português (3)
          const response = await fetch(`https://www.superbuxx.com:3030/get-swrm-categoria/${idCategoria}/${idIdioma}/${device}/${iso}`, {
               method: "GET",
               headers: { "Content-Type": "application/json" }
          });

          const res = await response.json();
          return res

          // if (response.ok) {
          //      const res = await response.json();
          //      console.log("Res: ", res.adsTrans)
          //      return res;
          // } else {
          //      return false;
          // }
     } catch (error) {
          console.error(error)
          return false
     }
}