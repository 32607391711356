import React, { Fragment, useEffect, useState } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const UserClickList = ({ clicks }) => {
     const {t} = useTranslation()
     return (
          <Fragment>
               <div class="buxx-table">
                    <table class="custom-table">
                         <thead>
                              <tr>
                                   <th>{t('date')}</th>
                                   <th>{t('idlabel')}</th>
                                   <th>{t('offer')}</th>
                                   <th>{t('activity')}</th>
                                   <th>{t('supportmenu')}</th>
                              </tr>
                         </thead>
                         <tbody className="sbx-fs-sm">
                              {clicks.sort((a, b) => new Date(b.click_time) - new Date(a.click_time)).map((txn) => (
                                   <tr key={txn.offer_id}>
                                        <td>{format(new Date(txn.click_time), 'dd-MM-yy')}</td>
                                        <td>{txn.offer_id}</td>
                                        <td>{txn.offer_name}</td>
                                        <td>{t('click')}</td>
                                        <td>
                                             {txn.hour_left === 0 ?
                                                  <small><a href={`ticket/${txn.offer_id}`}>{t('needhelplink')}</a></small> :
                                                  <small>{t('disponivelem')} {txn.hour_left}h</small>
                                             }
                                        </td>
                                   </tr>
                              ))}
                         </tbody>
                    </table>
               </div>

          </Fragment >
     )
}
export default UserClickList;