import React from "react";
import { useTranslation } from "react-i18next";

const HowBet = () => {
     const { t } = useTranslation()
     return (

          <div id="como-funciona" className="container text-center mt-5">

               <h2 className="title">{t('howitworksbettitle')}</h2>

               <div className="cards">
                    <div className="card-content">
                         <div className="icon mb-3">
                              <img src="../src/assets/images/como-funciona-1.png" alt="Register Icon" className="img-fluid" />
                         </div>
                         <h5 className="card-title">{t('howbettitle1')}</h5>
                         <p className="card-text">{t('howbettxt1')}</p>
                    </div>
                    <div className="card-divider">
                         <div className="card-divider-icon">
                              <img src="../src/assets/icons/chevron-rigth.svg" alt="" />
                         </div>
                    </div>
                    <div className="card-content">
                         <div className="icon mb-3">
                              <img src="../src/assets/images/como-funciona-2.png" alt="Earn Buxx Icon" className="img-fluid" />
                         </div>
                         <h5 className="card-title">{t('howbettitle2')}</h5>
                         <p className="card-text">{t('howbettxt2')}</p>
                    </div>
                    <div className="card-divider">
                         <div className="card-divider-icon">
                              <img src="../src/assets/icons/chevron-rigth.svg" alt="" />
                         </div>
                    </div>
                    <div className="card-content">
                         <div className="icon mb-3">
                              <img src="../src/assets/images/como-funciona-3.png" alt="" className="img-fluid" />
                         </div>
                         <h5 className="card-title">{t('howbettitle3')}</h5>
                         <p className="card-text">{t('howbettxt3')}</p>
                    </div>
               </div>
          </div>
     )
}

export default HowBet