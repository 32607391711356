//   Componente que retorna dados do usuário prontos para serem exibidos na NAVBAR
import { getUserData } from "./GetUserData";
import { getUnreadNotes } from "./GetNotificationData";

export async function getUserDetail(uid) {

     try{
          const data = await getUserData(uid)
          const avatarPath = data.avatar_path || '';
          const saldoBuxx = data.bux_balance || 0;
          let imageUrl

          if (avatarPath) {
               imageUrl = 'https://www.superbuxx.com:3030' + avatarPath;
          } else {
               imageUrl = '/image/icons/avatar.png'
          }

          const uNotes = await getUnreadNotes(uid)

          const res = [
               {
               imageUrl: imageUrl,
               saldoBuxx: saldoBuxx,
               uNotes: uNotes
               }
          ];
          return res

     } catch (erro) {
          console.log("Falha: ", erro)
     }
}