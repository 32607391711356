import React, { useContext, useState, useEffect, Fragment, useRef } from 'react';
import { getAuth, signOut } from "firebase/auth";
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getUserDetail } from '../functions/getUserNavDetails';
import { useLocation } from 'react-router-dom';
import { updateMailStatus } from '../functions/UpdateMailStatus';
import { getUserBlackListData } from '../functions/GetUserBlackListData';
import { getIsIpBlackList } from '../functions/GetIsIpBlacklist';
import { getIpAddr } from '../../helpers/users';
import LanguageSelector from '../languageselector/LanguageSelector';
import { getIpGeoData } from '../../helpers/users';
import { updateCountryAndUser } from '../functions/UpdateUserCountry';
import { useTranslation } from 'react-i18next';

function MainNav() {
     const { t } = useTranslation()
     const auth = getAuth();

     // [AGO 2024] Verifica se ip do user está na black list, se sim redireciona
     function ipVerify() {
          const ip = getIpAddr()
          ip.then((userIp) => {
               const isOk = getIsIpBlackList(userIp.ip)
               isOk.then((isIpOk) => {
                    if (isIpOk.isIpOk == false) {
                         window.location.href = "/user-error.html";
                    }
               })
          })
     }
     ipVerify()

     const { usuario, setUsuario, isMailVerify, idPais } = useContext(AuthContext)
     // const isCellVerify = JSON.parse(localStorage.getItem('isCell'))

     const [userDetail, setUserDetail] = useState(false)
     const [saldo, setSaldo] = useState(0)
     const [avatar, setAvatar] = useState('/image/icons/avatar.png')
     const [notRead, setNotRead] = useState(0)
     const { uid } = usuario || {}
     const [showNoIdPais, setShowNoIdPais] = useState(false)
     const navigate = useNavigate();

     // [AGO 2024] Verifica se id do país está correto
     useEffect(() => {
          if (idPais !== "loading") {
               if (idPais == null || idPais == false) {
                    // Dados do país nao foram capturados, exibir modal
                    setShowNoIdPais(true)
               }
          }
     }, [idPais])

     // [AGO 2024] Verifica permissão do user
     if (uid) {
          const permissionStatus = getUserBlackListData(uid)
          permissionStatus.then((valor) => {
               if (valor.usePermission == false) {
                    window.location.href = "/user-error.html";
               }
          });
     }

     // Verifica Ip e afere país do User
     const hadleCheckCountry = () => {
          const ip = getIpAddr()
          ip.then((userIp) => {
               if (userIp.ip !== null) {
                    // Consulta dados geográficos do ip na API
                    const geoData = getIpGeoData(userIp.ip)
                    geoData.then((geoDataResult) => {
                         // identificar se há mensagem de erro no retorno
                         if (geoDataResult.success === false) {
                              // fecha modal e redireciona para tela de erro
                              setShowNoIdPais(false)
                              navigate('/noipdefined')
                         } else {
                              // continuar aqui
                              const isoCountry = geoDataResult.countryCode
                              const nameCountry = geoDataResult.countryName
                              const phoneCode = geoDataResult.phoneCode
                              const resp = updateCountryAndUser(uid, isoCountry, nameCountry, phoneCode)
                              resp.then((res) => {
                                   if (res.sucesso == true) {
                                        // se ok, direciona para página de saída para ok, onde exibe msg mostrando país atribuído
                                        setShowNoIdPais(false)
                                        setShowModal(false)
                                        navigate("/countryupdated");
                                   } else {
                                        // se bad, direciona para página de noip
                                        setShowNoIdPais(false)
                                        setShowModal(false)
                                        navigate('/noipdefined')
                                   }
                              })
                         }
                    })
               }
          })
     }

     // [AGO 2024] - User é verificado?
     const location = useLocation()
     const [showModal, setShowModal] = useState(false)
     const localSt = localStorage.getItem('user')
     const [msgAvisoMail, setMsgAvisoMail] = useState(false)
     // const [msgAvisoCell, setMsgAvisoCell] = useState(false)
     const [btVerMail, setBtVerMail] = useState(false)
     // const [btVerCell, setBtVerCell] = useState(false)

     useEffect(() => {
          if (localSt) {
               const localData = JSON.parse(localSt)
               const isMailVerified = localData.emailVerified

               if (isMailVerified == true) {
                    setShowModal(false)
                    if (!isMailVerify) {
                         updateMailStatus(uid)
                    }
               } else {
                    if (location.pathname !== "/verifyemail") {
                         // Testa condição para exibir mensagem adequada
                         if (!isMailVerified) {
                              setMsgAvisoMail(
                                   <p>{t('nomailvery')}</p>
                              )
                              setBtVerMail(true)
                         } else {
                              setMsgAvisoMail("")
                              setBtVerMail(false)
                         }

                         setShowModal(true)
                    }
               }
          }
     }, [localSt])

     useEffect(() => {
          if (usuario) {
               getUserDetail(uid).then((data) => {
                    setUserDetail(data)
               })
          }
     }, [uid])

     useEffect(() => {
          if (userDetail) {
               setSaldo(userDetail[0].saldoBuxx)
               setAvatar(userDetail[0].imageUrl)
               setNotRead(userDetail[0].uNotes)
          }
     }, [userDetail])

     const handleClose = () => {
          if (showModal) {
               setShowModal(false);
          }
     };

     const handleCloseNoIdPais = () => {
          setShowNoIdPais(false)
     }

     function logout() {
          localStorage.removeItem('user');
          localStorage.removeItem('sbxclickid');
          localStorage.removeItem('isCell');
          signOut(auth).then(() => {
               setUsuario(null)
               if (window.location.pathname === '/') {
                    window.location.reload();
               } else {
                    navigate("/");
               }
          }).catch((error) => {
               //console.error("Erro ao fazer logout:", error);
               // Gravar erro em log
          });
     }

     return (
          <Fragment>
               {
                    showModal && (
                         <div class="toast-container">
                              <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                                   <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={handleClose}></button>
                                   <div className="toast-body">
                                        <div className="icon">
                                             <img src="../src/assets/icons/toast-warning.svg" alt="" />
                                        </div>
                                        <strong>{t('nessaction')}</strong>
                                        {msgAvisoMail}
                                        <p>{t('modalverifyphonetxt')}</p>
                                        {btVerMail && (
                                             <a href="/verifyemail" style={{ textDecoration: "none" }}><button type='button' className="buxx-btn">{t('verifymailbt')}</button></a>
                                        )}

                                   </div>
                              </div>
                         </div>
                    )
               }

               {
                    showNoIdPais && (
                         <div class="toast-container">
                              <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                                   <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={handleCloseNoIdPais}></button>
                                   <div className="toast-body">
                                        <div className="icon">
                                             <img src="../src/assets/icons/toast-warning.svg" alt="" />
                                        </div>
                                        <strong>{t('modalnoiptitle')}</strong>
                                        <p>{t('modalnoiptxt1')}</p>
                                        <p>{t('modalnoiptxt2')}</p>
                                        <div className="row">
                                             <div className="col">
                                                  <button className='buxx-btn' type='button' onClick={hadleCheckCountry}>{t('verifylocationbt')}</button>
                                             </div>
                                             <div className="col">
                                                  <a href="mailto:contact@superbuxx.com" style={{ textDecoration: 'none' }}><button className='buxx-btn' onClick={handleCloseNoIdPais}>{t('needhelplink')}</button></a>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>

                    )
               }
               <nav className="navbar navbar-expand-xxl main-header">
                    <div className="container-fluid container-spacing">
                         <div className="d-flex align-items-center left-container">
                              <a className="navbar-brand" href="/">
                                   <img className="mix-blend" src="/image/logos/logo-nav.png" alt="Super Buxx Logo" width="112px" height="50px" />
                              </a>
                              {/* <div className="dropdown-nav header-dropdown-button">
                                   <a
                                        className="nav-link dropdown-toggle text-white"
                                        href="#"
                                        id="userDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                   >
                                        <img src="../src/assets/icons/ios.svg" alt="" className="nav-icons" />
                                        <span> iOS</span>
                                   </a>
                                   <ul className="dropdown-menu" aria-labelledby="userDropdown">
                                        <li>
                                             <a className="dropdown-item" href="#">
                                                  <img src="../src/assets/icons/android.svg" alt="" className="nav-icons" />
                                                  <span> Android</span>
                                             </a>
                                        </li>
                                        <li>
                                             <a className="dropdown-item" href="#">
                                                  <img src="../src/assets/icons/icon-web.svg" alt="" className="nav-icons" />
                                                  <span> Web</span>
                                             </a>
                                        </li>
                                        <li>
                                             <a className="dropdown-item" href="#">
                                                  <img src="../src/assets/icons/list.svg" alt="" className="nav-icons" />
                                                  <span> {t('todos')}</span>
                                             </a>
                                        </li>
                                   </ul>
                              </div> */}

                              <div className="user-options-mobile">
                                   <div className="d-flex align-items-center ms-auto">
                                        {usuario ? (
                                             <>
                                                  <div className="d-flex align-items-center me-3">
                                                       <button className="btn balance-button">
                                                            <div className="circle me-2">
                                                                 <img style={{ maxWidth: '100%' }} src="/image/icons/moeda.webp" />
                                                            </div>
                                                            {saldo} BUXX
                                                       </button>
                                                  </div>
                                                  <div className="d-flex align-items-center me-3">
                                                       <div className="dropdown ms-2">
                                                            <a className="nav-link dropdown-toggle p-0 text-white" href="#" id="profileDropdown"
                                                                 role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                 <img src={avatar} alt="Profile" className="rounded-circle" width="48px" height="48px" />
                                                            </a>
                                                            <ul className="dropdown-menu" aria-labelledby="profileDropdown">
                                                                 <li>
                                                                      <div className="dropdown-item">
                                                                           <img src="/image/icons/moeda.webp" alt="Buxx" style={{ width: "25px" }} />
                                                                           <span style={{ marginLeft: "0.6rem", color: "white" }}>{saldo} BUXX</span>
                                                                      </div>
                                                                 </li>
                                                                 <li><hr className="dropdown-divider" style={{ border: "1px solid gray", opacity: 0.3 }} /></li>
                                                                 <li><a className="dropdown-item" href="/useraccount">{t('youprofilemenu')}</a></li>
                                                                 {/* <li><a className="dropdown-item" href="/verifycell">{t('verifycellmenu')}</a></li> */}
                                                                 {
                                                                      idPais === 1 ? (
                                                                           <li><a className="dropdown-item" href="/brpayinfo">{t('pixkeymenu')}</a></li>
                                                                      ) : (null)
                                                                 }
                                                                 {
                                                                      idPais !== 1 ? (
                                                                           <li><a className="dropdown-item" href="/paypalinfo">{t('paypalmenu')}</a></li>
                                                                      ) : (null)
                                                                 }
                                                                 <li><a className="dropdown-item" href="/exchangebuxx">{t('exchbuxxmenu')}</a></li>
                                                                 <li><a className="dropdown-item" href="/userexchangehistory">{t('exchhistmenu')}</a></li>
                                                                 <li><a className="dropdown-item" href="/userhistory">{t('activhistmenu')}</a></li>
                                                                 <li><a className="dropdown-item" href="/notifications">{t('notificationsmenu')}</a></li>
                                                                 <li><a className="dropdown-item" href="/tickets">{t('supportmenu')}</a></li>
                                                                 <li><a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={logout}>{t('logout')}</a></li>
                                                            </ul>
                                                       </div>
                                                  </div>
                                                  <div className="position-relative">
                                                       <a href="/notifications" className="nav-link p-0 notification-button">
                                                            <div className="icon-container">
                                                                 <img src="../src/assets/icons/bell-notification.svg" alt="" />
                                                                 {notRead > 0 ? (
                                                                      <span className="notification-count">{notRead}</span>
                                                                 ) : null}
                                                            </div>
                                                       </a>
                                                  </div>
                                             </>
                                        ) : null}

                                   </div>
                              </div>
                              <div className="user-options-mobile">
                                   <LanguageSelector />
                              </div>
                              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                                   aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                   <span className="navbar-toggler-icon"></span>
                              </button>
                         </div>

                         <div className="user-options">
                              <div className="d-flex align-items-center ms-auto">
                                   <div className="login-button">
                                        <LanguageSelector />
                                   </div>
                                   {usuario ? (
                                        <>
                                             <div className="d-flex align-items-center me-3">
                                                  <div className="dropdown ms-2">
                                                       <a className="nav-link dropdown-toggle p-0 text-white" href="#" id="profileDropdown"
                                                            role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <img src={avatar} alt="Profile" className="rounded-circle" width="48px" height="48px" />
                                                       </a>
                                                       <ul className="dropdown-menu" aria-labelledby="profileDropdown">
                                                            <li><a className="dropdown-item" href="/useraccount">{t('youprofilemenu')}</a></li>
                                                            {/* <li><a className="dropdown-item" href="/verifycell">{t('verifycellmenu')}</a></li> */}
                                                            {
                                                                 idPais === 1 ? (
                                                                      <li><a className="dropdown-item" href="/brpayinfo">{t('pixkeymenu')}</a></li>
                                                                 ) : (null)
                                                            }
                                                            {
                                                                 idPais !== 1 ? (
                                                                      <li><a className="dropdown-item" href="/paypalinfo">{t('paypalmenu')}</a></li>
                                                                 ) : (null)
                                                            }
                                                            <li><a className="dropdown-item" href="/exchangebuxx">{t('exchbuxxmenu')}</a></li>
                                                            <li><a className="dropdown-item" href="/userexchangehistory">{t('exchhistmenu')}</a></li>
                                                            <li><a className="dropdown-item" href="/userhistory">{t('activhistmenu')}</a></li>
                                                            <li><a className="dropdown-item" href="/notifications">{t('notificationsmenu')}</a></li>
                                                            <li><a className="dropdown-item" href="/tickets">{t('supportmenu')}</a></li>
                                                            <li><a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={logout}>{t('logout')}</a></li>
                                                       </ul>
                                                  </div>
                                             </div>
                                             <div className="d-flex align-items-center me-3">
                                                  <button className="btn balance-button">
                                                       <div className="circle me-2">
                                                            <img style={{ maxWidth: '100%' }} src="/image/icons/moeda.webp" />
                                                       </div>
                                                       {saldo} BUXX
                                                  </button>
                                             </div>
                                             <div className="position-relative">
                                                  <a href="/notifications" className="nav-link p-0 notification-button">
                                                       <div className="icon-container">
                                                            <img src="../src/assets/icons/bell-notification.svg" alt="" />
                                                            {notRead > 0 ? (
                                                                 <span className="notification-count">{notRead}</span>
                                                            ) : null}

                                                       </div>
                                                  </a>
                                             </div>
                                        </>
                                   ) : (
                                        <div className="login-button">
                                             <a href="/login" style={{ textDecoration: "none" }}><button className="" type="button">Login</button></a>
                                        </div>
                                   )}

                              </div>
                         </div>


                         <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                              <ul className="navbar-nav flex-grow-1">

                                   <div className="dropdown ms-2">
                                        <a className="nav-link dropdown-toggle p-0 text-white" href="#" id="profileDropdown"
                                             role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                             {t('offersmenu')}
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="profileDropdown">
                                             <li><a className="dropdown-item" href="/torox">Torox</a></li>
                                             <li><a className="dropdown-item" href="/adgem">Adgem</a></li>
                                             <li><a className="dropdown-item" href="/revu">Revu</a></li>
                                             <li><a className="dropdown-item" href="/notik">Notik</a></li>
                                             <li><a className="dropdown-item" href="/revtoo">Revtoo</a></li>
                                             <li><a className="dropdown-item" href="/appsprize">Apps Prize</a></li>
                                             <li><a className="dropdown-item" href="/mychips">MyChips</a></li>
                                             <li><a className="dropdown-item" href="/adgate">AdGate</a></li>
                                        </ul>
                                   </div>



                                   <li className="nav-item">
                                        <a className="nav-link" href="/popular">{t('popular')}</a>
                                   </li>
                                   <li className="nav-item">
                                        <a className="nav-link" href="/express">{t('expresstitleh1')}</a>
                                   </li>
                                   {/* <li className="nav-item">
                                        <a className="nav-link" href="/cashback">{t('cashback')}</a>
                                   </li> */}
                                   <li className="nav-item">
                                        <a className="nav-link" href="/bet">{t('bet')}</a>
                                   </li>
                                   <li className="nav-item">
                                        <a className="nav-link" href="/games">{t('games')}</a>
                                   </li>
                                   <li className="nav-item">
                                        <a className="nav-link" href="/pesquisas">{t('surveys')}</a>
                                   </li>
                                   <li className="nav-item">
                                        <a className="nav-link" href="/todas">{t('all')}</a>
                                   </li>
                              </ul>

                              {!usuario ? (
                                   <div className="login-mobile-button">
                                        <a href="/login" style={{ textDecoration: "none" }}><button className="" type="button">Login</button></a>
                                   </div>
                              ) : (null)}

                         </div>

                    </div>
               </nav>
          </Fragment>
     );
}

export default MainNav;
