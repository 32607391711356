import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { getPayout } from '../functions/GetPayoutByPais';
import { AuthContext } from '../../contexts/AuthContext';
import { useContext } from 'react';
import { addOfferImpression } from '../functions/addOfferImpression';
import { useTranslation } from 'react-i18next';

export default props => {
     const { t } = useTranslation()
     let controle = 1
     const cor = props.cor
     let oName = props.oName
     const offer_id = props.offer_id
     const info = props.info
     const os = props.os
     let iconOs
     let labelOs
     let creative = props.creative
     const [buxxTotal, setBuxxTotal] = useState("")
     const navigate = useNavigate()
     const { usuario } = useContext(AuthContext);
     const { idPais } = useContext(AuthContext);
     const { isoPais } = useContext(AuthContext)

     // Tratamento de exibição
     if (!oName) {
          oName = "Campanha"
     }

     // Device
     if (os == "ANDROID") {
          iconOs = "/image/icons/android.svg"
          labelOs = "ANDROID"
     }
     if (os == "WEB" || os == "ALL") {
          iconOs = "/image/icons/icon-web.svg"
          labelOs = "WEB"
     }
     if (os == "IOS") {
          iconOs = "/image/icons/ios.svg"
          labelOs = "iOS"
     }

     //Recupera payout da oferta x target pais
     useEffect(() => {
          getPayout(offer_id, isoPais)
               .then((res) => {
                    if (res) {
                         setBuxxTotal(res.buxxTotal)
                    }
               })
               .catch((erro) => {
                    console.error(erro)
               })

          if (controle = 1) {
               addOfferImpression(offer_id, idPais)
               controle = controle + 1
          }
     }, [offer_id, isoPais, idPais])

     function handleClick() {
          if (usuario) {
               navigate(`/offerdetail/${offer_id}`)
          } else {
               window.location.href = '/login';
          }
     }

     return (
          <div className="card-campain" onClick={handleClick}>
               <div className="card-image">
                    <div className="os-badge">
                         <img src={iconOs} alt="" />
                         <span>{labelOs}</span>
                    </div>
                    <div className="background-image">
                         <img src={creative} alt="" title="" className="img-fluid" />
                    </div>
               </div>
               <div className="card-info">
                    <div className="os-badge">
                         <img src={iconOs} alt="" />
                         <span>{labelOs}</span>
                    </div>
                    <h3>{oName}</h3>
                    <p>{info}</p>
                    <button className="btn btn-custom">{t('earn')} {buxxTotal} BUXX</button>
               </div>
          </div>

     )

}