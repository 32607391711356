import { Routes, Route } from "react-router-dom";
import Home from "../../views/Home";
import About from "../../views/About";
import SignUp from "../../views/signup/Signup";
import NoUser from "../../views/nouser/NoUser";
import RequireAuth from "../../contexts/RequireAuth";
import OfferDetail from "../../views/offerdetail/offerdetail";
import Popular from "../../views/categorias/Popular";
import Cashback from "../../views/categorias/Cashback";
import Bet from "../../views/categorias/Bet";
import Games from "../../views/categorias/Games";
import Pesquisas from "../../views/categorias/Pesquisas";
import Todas from "../../views/categorias/Todas";
import Pagina1 from "../../views/pagina1/Pagina1";
import Debugger from "../../views/categorias/Debugger";
import SignUpMail from "../logincards/SignUpMail";
import Login from "../logincards/Login";
import NoIpDefinedMsg from "../../views/common/NoIpDefinedMsg";
import CountryUpdated from "../../views/common/CountryUpdated";
import Express from "../../views/categorias/Express";
import Faq from "../../views/common/Faq";
import Terms from "../../views/common/Terms";

const Content = () => {
     return (
          <main>
               <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/nouser" element={<NoUser />} />
                    <Route path="/offerdetail/:offer_id" element={<OfferDetail />} />
                    <Route path="/popular" element={<Popular />} />
                    <Route path="/cashback" element={<Cashback />} />
                    <Route path="/bet" element={<Bet />} />
                    <Route path="/games" element={<Games />} />
                    <Route path="/pesquisas" element={<Pesquisas />} />
                    <Route path="/todas" element={<Todas />} />
                    <Route path="/ua" element={<Pagina1 />} />
                    <Route path="/*" element={<RequireAuth />} />
                    <Route path="/debugger" element={<Debugger />} />
                    <Route path="/signupmail" element={<SignUpMail />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/noipdefined" element={<NoIpDefinedMsg />} />
                    <Route path="/countryupdated" element={<CountryUpdated />} />
                    <Route path="/express" element={<Express />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/terms" element={<Terms />} />
                    
               </Routes>
          </main>
     )
}

export default Content