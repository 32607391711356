import React from "react";
import Truncate from "./Truncate";
import { useTranslation } from "react-i18next";

const DiveBet = () => {
     const { t } = useTranslation()
     const text01 = (
          <>
               <h5 className="card-subtitle">{t('deepbettitle1')}</h5>
               <p className="card-text">{t('deepbettxt1p1')}</p>
               <p className="card-text">{t('deepbettxt1p2')}</p>
               <p className="card-text">{t('deepbettxt1p3')}</p>
          </>
     )
     const text02 = (
          <>
               <h5 className="card-subtitle">{t('deepbettitle2')}</h5>
               <p className="card-text">{t('deepbettxt2p1')}</p>
               <p className="card-text">{t('deepbettxt2p2')}</p>
               <p className="card-text">{t('deepbettxt2p3')}</p>
          </>
     )
     const text03 = (
          <>
               <h5 className="card-subtitle">{t('deepbettitle3')}</h5>
               <p className="card-text">{t('deepbettxt3p1')}</p>
               <p className="card-text">{t('deepbettxt3p2')}</p>

          </>
     )
     return (
          <div className="container spacing-box-top" id="mergulhe">
               <div className="row">
                    <div className="col-12">
                         <div className="text-center card-title ">
                              <h2 className="block-title">{t('divedeepertitleh1')}</h2>
                         </div>
                    </div>
               </div>
               <div className="row">
                    <div className="col-12 col-lg-4 mb-5">
                         <Truncate text={text01} maxLength={150} />
                    </div>


                    <div className="col-12 col-lg-4 mb-5">
                         <Truncate text={text02} maxLength={150} />
                    </div>


                    <div className="col-12 col-lg-4 mb-5">
                         <Truncate text={text03} maxLength={150} />
                    </div>

               </div>
          </div>
     )
}

export default DiveBet