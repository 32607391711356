
export async function paisById(id_pais) {
     
     // Busca dados do país através do id_pais gravado na tabela do usuário
     try {
          const response = await fetch("https://www.superbuxx.com:3030/pais-by-id/" + id_pais, {
               method: "GET",
               headers: { "Content-Type": "application/json" }
          });
          if (response.ok) {
               const res = await response.json();
               if (res.sucesso) {
                    return res.cData
               } else {
                    return false
               }
          } else {
               return false
          }
     } catch (error) {
          console.error(error)
     }
}