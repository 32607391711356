import { getCountry } from "./GetCountry";

async function updateUser(uid2, id_pais) {
     try {
          const response = await fetch("https://www.superbuxx.com:3030/user-update-pais", {
               method: "PUT",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({ uid2, id_pais }),
          });
     } catch (error) {
          console.log(error)
     }
}

export async function updateUserCountry(uid) {
     const geodata = await getCountry();
     const { country_code, country_name } = geodata || {};

     // Busca id de país na tabela "países"
     try {
          // const response = await fetch("http://localhost:3030/get-pais/AU/Australia", {
          const response = await fetch("https://www.superbuxx.com:3030/get-pais/" + country_code + "/" + country_name, {
               method: "GET",
               headers: { "Content-Type": "application/json" }
          });
          if (response.ok) {
               const data = await response.json();
               const id_pais = data.cData.id_pais;
               await updateUser(uid, id_pais)
          }
          return response
     } catch (error) {
          console.error(error)
     }
}

export async function updateCountryAndUser(uid, iso, countryName, phoneCode) {
     try {
          const response = await fetch("https://www.superbuxx.com:3030/update-country-and-user", {
               method: "POST",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({ uid, iso, countryName, phoneCode }),
          });
          if (response.ok) {
               // Se a resposta for bem-sucedida (status 200), leia o corpo da resposta
               const data = await response.json();
               return data;
          } else {
               return null; // Ou qualquer outro tratamento de erro que você desejar
          }
     } catch (error) {
          console.log(error)
     }

}