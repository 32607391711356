// ModalContext.jsx
import React, { createContext, useState } from 'react';

export const ModalContext = createContext();

export function ModalProvider({ children }) {
     const [modalShow, setModalShow] = useState(false);
     const [exibirJoin, setExibirJoin] = useState(true);
     
     const handleConectar = () => {
          setModalShow(false);
     };

     return (
          <ModalContext.Provider value={{ modalShow, setModalShow, handleConectar, exibirJoin }}>
               {children}
          </ModalContext.Provider>
     );
}
