import "./offerwalls.css"
import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import md5 from "md5";
import { getOfferName } from "../../components/functions/GetOfferData";

const Swaarm = props => {
     const [swaarmAds, setSwaarmAds] = useState(null)
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario || {}

     async function getSwaarm() {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/swaarm", {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
               });
               if (response.ok) {
                    const res = await response.json();
                    setSwaarmAds(res.ads);
               } else {
                    setSwaarmAds(false);
               }
          } catch (error) {
               console.error(error)
               return false
          }
     }

     useEffect(() => {
          getSwaarm();
     }, []);
     console.log(swaarmAds)

     function getSbxUid(url) {
          const timestamp = new Date().getTime().toString();
          const sbxUid = md5(timestamp + url)
          return sbxUid
     }

     return (
          <div className="mt-5">
               <div className="offerwall">
                    <h1 className="text-center mb-3">Swaarm testing</h1>
                    {swaarmAds === null ? (
                         <p>Carregando...</p>
                    ) : swaarmAds ? ( // Verifica se swaarmAds é verdadeiro
                         // Exibe os itens do array swaarmAds
                         <div>
                              {swaarmAds.map((ad, index) => (
                                   <div key={index} className="sbx-offer-card">
                                        <h5><strong>Name: {getOfferName(ad.name)}</strong></h5>
                                        <p>
                                             <strong>Click URL: </strong> <a href={ad.click_url + "&unique2=" + uid + "&unique1=" + getSbxUid(ad.click_url)} target="_blank">{ad.click_url + "&unique2=" + uid + "&unique1=" + getSbxUid(ad.click_url)}</a><br />
                                             <strong>Payout: </strong> {ad.payout}<br />
                                             <strong>Moeda: </strong> {ad.payout_currency}<br />
                                             <strong>App Id: </strong> {ad.id}<br />
                                             <strong>Additional Info: </strong> {ad.additional_information}<br />
                                             <strong>Event Name: </strong> {ad.eventTypes.name}<br />
                                        </p>
                                        <img src={ad.creatives_url} width="60px"  className="mb-5"/>
                                        {/* Adicione outras informações que deseja exibir */}
                                   </div>
                              ))}
                         </div>
                    ) : (
                         <p>Falha ao carregar os dados da API.</p>
                    )}
               </div>

          </div>
     )

}
export default Swaarm