import React, { Fragment, useEffect, useState } from "react";
import { format } from "date-fns";
import { Table, Modal, Button, Col, Row } from "react-bootstrap";
import plusbt from "/image/icons/plusbt.png"
import { getProvider } from "../functions/GetProvider";
import { useTranslation } from "react-i18next";

const HistoryList = ({ transactions }) => {
     const {t} = useTranslation()
     const [modalShow, setModalShow] = useState(false);
     const [selectedTxn, setSelectedTxn] = useState(null);

     const [provName, setProvName] = useState("")
     const [postBckId, setPostBckId] = useState("")
     const [buxxPg, setBuxxPg] = useState("")
     const [beginTime, setBeginTime] = useState("")
     const [completeTime, setCompleteTime] = useState("")
     const [rangeTime, setRangeTime] = useState("")
     const [eName, setEName] = useState("")
     const [eId, setEId] = useState("")

     const sortedTxn = [...transactions].sort((a, b) => {
          const timeA = new Date(a.offer_complete_time).getTime();
          const timeB = new Date(b.offer_complete_time).getTime();
          return timeB - timeA; // Ordena do mais recente para o mais antigo
     });

     function timestampParaData(timestamp) {
          return new Date(timestamp * 1000); // Multiplicando por 1000 para converter segundos para milissegundos
     }

     function ExibeModal(txn) {

          // Tratamento de strings para exibição
          let offerBeginTime = txn.offer_begin_time;
          let offerCompleteTime = txn.offer_complete_time;
          let eventName = txn.event_name;
          let eventId = txn.event_id;

          const providerName = getProvider(txn.provider_id)

          if (!offerBeginTime) {
               offerBeginTime = "---"
          } else {
               offerBeginTime = format(new Date(txn.offer_begin_time), 'dd-MM-yyyy | hh:mm a')
          }

          if (!offerCompleteTime) {
               offerCompleteTime = "---"
          } else {
               offerCompleteTime = format(new Date(txn.offer_complete_time), 'dd-MM-yyyy | hh:mm a')
          }

          if (!eventName) {
               eventName = "---"
          }

          if (!eventId) {
               eventId = "---"
          }

          // calcula duração da campanha
          if (offerBeginTime !== "---" && offerCompleteTime !== "---") {
               // Convertendo as strings para objetos de data
               var dataInicial = timestampParaData(Date.parse(txn.offer_begin_time) / 1000);
               var dataFinal = timestampParaData(Date.parse(txn.offer_complete_time) / 1000);

               // Calculando a diferença em milissegundos
               var diferencaEmMilissegundos = dataFinal.getTime() - dataInicial.getTime();

               // Convertendo a diferença de milissegundos para horas, minutos e segundos
               var segundos = Math.floor(diferencaEmMilissegundos / 1000);
               var minutos = Math.floor(segundos / 60);
               var horas = Math.floor(minutos / 60);

               // Formatar a diferença de tempo
               var duration =
                    horas.toString().padStart(2, '0') + ":" +
                    (minutos % 60).toString().padStart(2, '0') + ":" +
                    (segundos % 60).toString().padStart(2, '0');

          } else {
               var duration = "---"
          }
          setProvName(providerName)
          setPostBckId(txn.postback_id)
          setBuxxPg(txn.sbx_amount)
          setBeginTime(offerBeginTime)
          setCompleteTime(offerCompleteTime)
          setEName(eventName)
          setEId(eventId)
          setRangeTime(duration)

          // atribui estados das variáveis de exibição

          setModalShow(true)       
          

     }

     const handleCloseModal = () => {
          setModalShow(false);
     };

     return (
          <Fragment>
               <div className="buxx-table">
                    <table className="custom-table">
                         <thead>
                              <tr>
                                   <th>
                                        <div className="table-label">{t('date')}</div>
                                   </th>
                                   <th>
                                        <div className="table-label">{t('offer')}</div>
                                   </th>
                                   <th>
                                        <div className="table-label">{t('activity')}</div>
                                   </th>
                                   <th>
                                        <div className="table-label">Buxx</div>
                                   </th>
                                   <th>
                                        <div className="table-label no-border">{t('details')}</div>
                                   </th>
                              </tr>
                         </thead>

                         <tbody>
                              {sortedTxn.map((txn) => (
                                   <tr key={txn.postback_id}>
                                        <td>{format(new Date(txn.offer_complete_time), 'dd-MM-yy | HH:mm')}</td>
                                        <td>{txn.offer_name}</td>
                                        <td>{txn.event_name}</td>
                                        <td>{txn.sbx_amount}</td>
                                        <td className="text-center"><button className="btn" type="button" onClick={(e) => ExibeModal(txn)}><img src={plusbt}></img></button> </td>
                                   </tr>
                              ))}
                         </tbody>
                    </table>
               </div>

               {modalShow && (
                    <div
                         className="modal fade show"
                         id="notificationModal"
                         tabIndex="-1"
                         aria-labelledby="notificationModalLabel"
                         aria-hidden="true"
                         style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    >
                         <div className="modal-dialog modal-lg">
                              <div className="modal-content" style={{ backgroundColor: "#333" }}>
                                   <div className="modal-header">
                                        <h5 className="modal-title" id="notificationModalLabel">
                                             {t('actvdetails')}
                                        </h5>
                                        <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseModal}></button>
                                   </div>
                                   <div className="modal-body">
                                        <div className="row">
                                             <div className="col-sm">
                                                  <p>
                                                       {t('provider')} <br />
                                                       <strong>{provName}</strong>
                                                  </p>
                                             </div>
                                             <div className="col-sm">
                                                  <p>
                                                       {t('idactvity')} <br />
                                                       <strong>{postBckId}</strong>
                                                  </p>
                                             </div>
                                             <div className="col">
                                                  <p>
                                                       {t('statusminus')} <br />
                                                       <strong>{t('concluido')}</strong>
                                                  </p>
                                             </div>
                                             <div className="col">
                                                  <p>
                                                       Buxx <br />
                                                       <strong>{buxxPg}</strong>
                                                  </p>
                                             </div>
                                        </div>
                                        <div className="row">
                                             <div className="col-sm">
                                                  <p>
                                                       {t('inicio')} <br />
                                                       <strong>{beginTime}</strong>
                                                  </p>
                                             </div>
                                             <div className="col-sm">
                                                  <p>
                                                       {t('fim')} <br />
                                                       <strong>{completeTime}</strong>
                                                  </p>
                                             </div>
                                             <div className="col-sm">
                                                  <p>
                                                       {t('spendtime')} <br />
                                                       <strong>{rangeTime}</strong>
                                                  </p>
                                             </div>
                                        </div>
                                        <div className="row">
                                             <div className="col-sm">
                                                  <p>
                                                       {t('eventminus')} <br />
                                                       <strong>{eName}</strong>
                                                  </p>
                                             </div>
                                             <div className="col-sm">
                                                  <p>
                                                       {t('eventid')} <br />
                                                       <strong>{eId}</strong>
                                                  </p>
                                             </div>
                                             
                                        </div>

                                   </div>

                              </div>

                         </div>
                    </div>

               )
               }

          </Fragment >
     )
}
export default HistoryList;