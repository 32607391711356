import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext, useState, useEffect } from "react";
import { getUserData } from "../components/functions/GetUserData";
import { paisById } from "../components/functions/GetPaisData";
import { getIdIdioma } from "../components/functions/GetIdIdioma";
import { getUserGeoData } from "../helpers/users";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

     const [usuario, setUsuario] = useState(false);
     const [loading, setLoading] = useState(true);
     const [idPais, setIdPais] = useState("loading");
     const [idIdioma, setIdIdioma] = useState(false);
     const [isMailVerify, setIsMailVerify] = useState(false)
     const [isCellVerify, setIsCellVerify] = useState(null)
     const [isoPais, setIsoPais] = useState(false)
     const auth = getAuth();

     // Leia o usuário do localStorage quando o componente for montado
     useEffect(() => {
          const user = localStorage.getItem('user');
          if (user) {
               setUsuario(JSON.parse(user));
          }

          // ###### LÓGICA DO TRACKING
          if (!user) {
               const queryString = window.location.search;
               const params = new URLSearchParams(queryString);
               const sbxClickId = params.get('sbxclickid');
               if (sbxClickId) {
                    localStorage.setItem('sbxclickid', sbxClickId)
               }
          }
          // ###### FIM LÓGICA

          setLoading(false);
     }, []);

     useEffect(() => {
          return auth.onAuthStateChanged((userloggedin) => {
               if (userloggedin) {
                    localStorage.setItem('user', JSON.stringify(userloggedin));
                    setUsuario(userloggedin);
               } else {
                    localStorage.removeItem('user');
                    setUsuario(null);
               }
          });
     }, [auth]);

     if (loading) {
          return null;
     } else {
          if (usuario) {
               getUserData(usuario.uid)
                    .then((resultado) => {
                         setIsMailVerify(resultado.is_mail_verified)
                         setIsCellVerify(resultado.is_cell_verified)
                         localStorage.setItem('isCell', resultado.is_cell_verified)
                         setIdPais(resultado.id_country)
                         paisById(resultado.id_country)
                              .then((result) => {
                                   setIdIdioma(result.id_idioma)
                                   setIsoPais(result.iso_a3)
                              })
                    })
          } else {
               const language = window.navigator.language;
               const langIsoCode = language.slice(0, 2).toUpperCase();
               getIdIdioma(langIsoCode)
                    .then((res) => {
                         setIdIdioma(res)
                    })

               getUserGeoData()
                    .then((res) => {
                         setIsoPais(res.countryCode)
                    })
          }
     }

     return (
          <AuthContext.Provider value={{ usuario, setUsuario, idPais, setIdPais, idIdioma, setIdIdioma, isMailVerify, isCellVerify, isoPais }}>
               {children}
          </AuthContext.Provider>
     );
}