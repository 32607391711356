export async function getOpenTicketData(uid, offer_id) {
     try {
          const response = await fetch("https://www.superbuxx.com:3030/get-header-ticket/" + uid + "/" + offer_id, {
               method: "GET",
               headers: { "Content-Type": "application/json" }
          });
          if (response.ok) {
               const res = await response.json();
               return res
          } else {
               const res = await response.json();
               return res
          }
     } catch (error) {
          console.error(error)
          return false
     }
}