// Responsável pela barra de navegação das páginas de categoria
import React from "react";
import { Fragment } from "react";

export default props => {
     const totalPages = props.totalPages
     const currentPage = props.currentPage
     const handlePreviousClick = props.handlePreviousClick
     const handleNextClick = props.handleNextClick
     const handlePageChange = props.handlePageChange
     return (
          <Fragment>
               {totalPages > 1 && (
                    <div className="row">
                         <div className="col-12"><div className="container text-center">
                              <nav aria-label="Page navigation">
                                   <ul className="pagination justify-content-center">
                                        <li className="page-item">
                                             <a className="page-link" aria-label="Previous" onClick={handlePreviousClick}>
                                                  <img src="../src/assets/icons/icon-arrow-pagination-left.svg" alt="" />
                                             </a>
                                        </li>
                                        {[...Array(totalPages)].map((_, index) => (
                                             <li
                                                  key={index}
                                                  className="page-item"
                                             >
                                                  <span className={`page-link ${index === currentPage ? "active" : ""}`} onClick={() => handlePageChange(index)}>{index + 1}</span>
                                             </li>
                                        ))}
                                        <li className="page-item">
                                             <a className="page-link" aria-label="Next" onClick={handleNextClick}>
                                                  <img src="../src/assets/icons/icon-arrow-pagination-right.svg" alt="" />
                                             </a>
                                        </li>
                                   </ul>
                              </nav>
                         </div>
                         </div>
                    </div>
               )}
          </Fragment>
     )
}