import "./offerwalls.css"
import React, { Fragment, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { recLastActv } from "../../components/functions/RecLastActv";
import { useEffect } from "react";
import SeoTags from "../common/SeoTags";

const RevuOfferWall = props => {
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario
     const url = "https://publishers.revenueuniverse.com/wallresp/951/offers?uid=" + uid

     useEffect(() => {
          if (uid) {
               // Registra ação
               recLastActv(uid, "Entrou ow Revu")
          }
     }, [uid])

     return (
          <Fragment>
               <SeoTags
                    title="Revu Offerwall"
                    description="Revu Offerwall"
                    url="https://www.superbuxx.com/revu"
               />
               <div className="mt-5">
                    <div className="offerwall">
                         <iframe
                              src={url}
                              style={{
                                   height: "100vh",
                                   width: "100%",
                                   border: 0,
                                   frameborder: 0,
                                   scrolling: "yes"
                              }}
                         >
                         </iframe>
                    </div>
               </div>
          </Fragment>
     )
}
export default RevuOfferWall