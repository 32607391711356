import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { ModalProvider } from "../../contexts/ModalContext";
import { getAuth, signOut } from "firebase/auth";
import MainNav from "./Navbar";

const Header = () => {
     // Verificações de inicialização
     const { usuario } = useContext(AuthContext);
     const {uid} = usuario || {}

     let classe, linkTo, funcao, label;
     if(usuario) {
          classe = "nav-sbx-me nav-bt";
          linkTo = "";
          funcao = logout;
          label = "Logout";
     } else {
          classe = "nav-sbx-me nav-bt";
          linkTo = "";
          funcao = "() => setModalShow(true)"
          label = "Login";
     }

     const { setUsuario } = useContext(AuthContext)
     function logout() {
          setUsuario(null);
          localStorage.removeItem('user');
          const auth = getAuth();
          signOut(auth);
     }

     return (
          <ModalProvider>
               <MainNav classe={classe} linkTo={linkTo} funcao={funcao} label={label} />
          </ModalProvider>
     )
}

export default Header
