import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Truncate = ({ text, maxLength }) => {
     const [expanded, setExpanded] = useState(false);
     const {t} = useTranslation()

     const truncateText = (text, maxLength) => {
          let totalLength = 0;
          const truncated = [];

          React.Children.forEach(text.props.children, child => {
               if (typeof child === 'string') {
                    if (totalLength + child.length > maxLength) {
                         truncated.push(child.slice(0, maxLength - totalLength));
                         totalLength = maxLength;
                    } else {
                         truncated.push(child);
                         totalLength += child.length;
                    }
               } else {
                    const childLength = child.props.children
                         ? React.Children.toArray(child.props.children).reduce((acc, c) => acc + (typeof c === 'string' ? c.length : 0), 0)
                         : 0;

                    if (totalLength + childLength > maxLength) {
                         const newChildren = truncateText(child, maxLength - totalLength);
                         truncated.push(React.cloneElement(child, {}, newChildren));
                         totalLength = maxLength;
                    } else {
                         truncated.push(child);
                         totalLength += childLength;
                    }
               }
          });

          return truncated;
     };

     const truncatedText = truncateText(text, maxLength);
     const displayText = expanded ? text : <>{truncatedText}</>;

     const toggleExpand = () => {
          setExpanded(!expanded);
     };

     return (
          <div>
               {displayText}
               <span onClick={toggleExpand} style={{ cursor: 'pointer', color: 'white', textDecoration: 'underline' }} className="btn-mergulhe">
                    {expanded ? t('vermenos') : t('vermais')}
               </span>
               <img src="../src/assets/icons/chevron-mergulhe-btn.svg" alt="" />
          </div>
     );
};

export default Truncate;
