import "./Dicas.css";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

function DicaErro({titulo, msg, urlLabel, placement}) {
     return (
          <>
               <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                         <Popover id={`popover-positioned-${placement}`}>
                              <Popover.Header className="popover-header-erro" as="h3">{titulo}</Popover.Header>
                              <Popover.Body className="popover-body-erro">
                                   {msg}
                              </Popover.Body>
                         </Popover>
                    }
               >
                    <div className='input-msg-erro'>{urlLabel}</div>
               </OverlayTrigger>
          </>
     );
}

export default DicaErro;