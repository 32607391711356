// Página que detalha ofertas vindas da Swaarm
import React, { Fragment, useEffect, useState } from "react"
import { AuthContext } from "../../contexts/AuthContext"
import { useParams } from "react-router-dom"
import { useContext } from "react"
import { getDevice } from "../../components/functions/GetNavigatorInfo"
import { getUserData } from "../../components/functions/GetUserData"
import { emailSender } from "../../components/functions/MailSender"
import QRCode from "react-qr-code"
import icon from "/image/icons/icon_blk.png"
import { getPayout } from "../../components/functions/GetPayoutByPais"
import SbxSpinner from "../common/SbxSpinner"
import HowItWorks from "../common/HowItWorks"
import { useTranslation } from "react-i18next"
import { recLastActv } from "../../components/functions/RecLastActv"
import SeoTags from "../common/SeoTags"

const OfferDetail = () => {
     const { t } = useTranslation()
     const { usuario } = useContext(AuthContext)
     const { uid, email } = usuario
     const { idIdioma } = useContext(AuthContext)
     const { isoPais } = useContext(AuthContext)
     const { offer_id } = useParams()
     const [dados, setDados] = useState(null)
     const [cellNumber, setCellNumber] = useState("")
     const [userData, setUserData] = useState("")
     const [statusButton, setStatusButton] = useState(true)
     const [userName, setUserName] = useState("")
     const [showInputs, setShowInputs] = useState(true)
     const [ddiNumber, setDdiNumber] = useState("")
     const [idPais, setIdPais] = useState("")
     const [sentTo, setSentTo] = useState("")
     const [eventos, setEventos] = useState(null)
     const [tipoEvento, setTipoEvento] = useState("")
     const [totalReward, setTotalReward] = useState(null)
     const [showProgress, setShowProgress] = useState(false)
     const [totalEventos, setTotalEventos] = useState(0)
     const [percentualConquistado, setPercentualConquistado] = useState(0)
     const [device, setDevice] = useState("")
     const [deviceIcon, setDeviceIcon] = useState("")
     const [showErro, setShowErro] = useState(false)

     let nome
     let reward
     let shortDesc
     let longDesc
     let icone
     let clickUrl
     let allPayouts = []
     let totalCurrencyPayout = 0
     let totalBuxxPayout = 0
     let totalBuxxToUser = 0
     let eventType
     let userAgent = navigator.userAgent
     let userDevice = getDevice()
     let theUrl = null

     // Restaura dados do User
     useEffect(() => {
          getUserData(uid).then((data) => {
               if (data) {
                    setCellNumber(data.cell_number || '');
                    setUserName(data.user_name || t('user'))
                    setIdPais(data.id_country || '');
               }
          });
     }, [])

     
     // recupera dados da oferta via api, passando como parâmetro id da oferta (oid) + id do idioma (lid)
     async function getSwaarmAd(oid, lid, iso) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/get-offer-details/" + oid + "/" + lid + "/" + iso, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
               });
               const res = await response.json();
               //console.log("Resposta: ", res.result)
               //const ad = res.dados
               return res
          } catch (error) {
               console.error(error)
               return false
          }
     }

     async function getBuxxOffer(userId, oid) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/get-total-buxx-pb/" + userId + "/" + oid, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" }
               });
               const res = await response.json();
               return res
          } catch (error) {
               console.error(error)
               return false
          }
     }

     useEffect(() => {
          getSwaarmAd(offer_id, idIdioma, isoPais)
               .then((swaarmData) => {
                    if(swaarmData.dados) {
                         setDados(swaarmData.dados)
                         setShowErro(false)
                    } else {
                         setShowErro(true)
                    }
                    
               })
               .catch((error) => {
                    console.error("Erro ao buscar dados do Swaarm:", error);
               });
     }, [offer_id, idIdioma]);

     if (dados) {
          nome = dados.offerName
          reward = dados.payout // converter USD para SBX
          shortDesc = dados.shortDesc
          longDesc = dados.longDesc
          icone = dados.creative
          if (!icone) {
               icone = icon
          }
          clickUrl = dados.click_url + "&unique2=" + uid
          console.log(clickUrl)
     }

     // [SET 2024] - Tracking user activity
     useEffect(() => {
          if (uid && dados) {
               // Registra ação
               recLastActv(uid, "Entrou página OD: " + offer_id + " " + dados.offerName)
          }
     },[uid, dados])

     useEffect(() => {
          if (dados) {
               setEventos(dados.eventos)
               setTipoEvento(dados.tipo)

               // dados exibição do device
               const os = dados.os
               if (os == "WEB" || os == "ALL") {
                    setDevice("Android, iOS, Web")
                    setDeviceIcon("../src/assets/icons/icon-web.svg")
               }

               if (os == "ANDROID") {
                    setDevice("Android")
                    setDeviceIcon("../src/assets/icons/android.svg")
               }

               if (os == "IOS") {
                    setDevice("iOs")
                    setDeviceIcon("../src/assets/icons/ios.svg")
               }
          }
     }, [dados])

     useEffect(() => {
          if (dados) {
               if (dados.tipo === "S") {
                    setShowProgress(false)
               } else {
                    const countG = eventos.filter(evento => evento.tipo === "G").length;
                    const countM = eventos.filter(evento => evento.tipo === "M").length;
                    const countT = eventos.filter(evento => evento.tipo === "T").length;
                    if (countG > 1 || countM > 1 || countT > 1) {
                         setShowProgress(true)
                         if (countG > 1) {
                              setTotalEventos(countG)
                         }
                         if (countM > 1) {
                              setTotalEventos(countM)
                         }
                         if (countT > 1) {
                              setTotalEventos(countT)
                         }

                    } else {
                         setShowProgress(false)
                    }
               }
          }

     }, [eventos])

     // Afere progresso
     useEffect(() => {
          // Verifica na tabela postbacks todos os eventos do usuário para essa campanha
          getBuxxOffer(uid, offer_id)
               .then((total) => {
                    const completos = total.eventosCompletos
                    const pct = (completos / totalEventos) * 100
                    setPercentualConquistado(pct)
               })
               .catch((error) => {
                    console.error("Erro ao buscar dados do Swaarm:", error);
               });

     }, [totalEventos, uid, offer_id])


     // calcula o total de recompensas em buxx
     useEffect(() => {
          let total = 0
          getPayout(offer_id, isoPais)
               .then((res) => {
                    setTotalReward(res.buxxTotal)
               })
          total = totalReward
     }, [isoPais])



     // Prepara URL de redirecionamento
     function MakeSwaarmUrlRedirect(url, userid, offerid) {
          var base_url = "https://www.superbuxx.com:3030/offer-tracking"
          var param_url = url
          // Codifica a URL do parâmetro
          var encoded_url = encodeURIComponent(param_url);

          // Cria a URL final
          var final_url = base_url + "/" + userid + "/" + offerid + "/" + encoded_url;
          return final_url
     }
     theUrl = MakeSwaarmUrlRedirect(clickUrl, uid, offer_id)

     useEffect(() => {
          if (cellNumber === "" || cellNumber === null) {
               setStatusButton(true)
          } else {
               setStatusButton(false)
          }
     }, [cellNumber])

     const enviaMail = async () => {
          // envia email com link da oferta "theUrl"
          // #01 - Configura subject e body
          const titulo = "Seu link chegou! Participe da campanha " + nome + " e ganhe sua recompensa!"
          const texto = `
               Olá, ${userName}!
               Clique no link abaixo (ou copie e cole) e participe da oferta. Você pode participar usando: ${device}.
               ${theUrl}
               
               Você pode ganhar até ${totalReward} Buxx!!!
               
               Passo a passo:
               1. Clique no link acima através do Android, iOS ou Web.
               2. Responda à pesquisa.
               3. Importante: você precisa ser um novo usuário do app.
               4. Importante: a oferta só funciona em Android, iOS ou Web.
               5. Pronto! Você receberá até 5 Buxx em até 30 dias.

               Participe e boa sorte!
               Equipe SuperBuxx

          `

          const html = `
               <h1>Olá, ${userName}!</h1>
               <p>Clique no link abaixo e participe da oferta. Você pode participar usando: <strong>${device}</strong>.</p>
               <div><a href='${theUrl}'><img src='${icone}' width='100' height='auto'/></a></div>
               <span style='font-size:18px'><a href='${theUrl}'<h3><strong>${nome}</strong></h3></a></span>
               <p><a href='${theUrl}'>🔗 Clique aqui para participar</a></p>
               <p style='font-size:18px'>Você pode ganhar até <strong>${totalReward} Buxx</strong>!!!</p>
               <p><strong>Passo a passo:</strong></p>
               <ol>
                    <li>Clique no link acima através do ${device}.</li>
                    <li>${longDesc}</li>
                    <li>Importante: você precisa ser um novo usuário do app.</li>
                    <li>Importante: a oferta só funciona em ${device}.</li>
                    <li>Pronto! Você receberá até ${totalReward} Buxx em até 30 dias..</li>
               </ol>
               <br/>
               <p style='font-size:15px'>
                    Participe e boa sorte! <br/>
                    <strong>Equipe SuperBuxx</strong>
               </p>
          `

          emailSender(email, titulo, texto, html)
               .then(resposta => {
                    if (resposta.status === 200) {
                         setShowInputs(false)
                         setSentTo(t('sentto'))

                         // atualiza atividade
                         recLastActv(uid, "Pediu email link oferta: " + nome)
                    }
               })
     }

     return (
          <Fragment>
               <SeoTags url={`https://www.superbuxx.com/offerdetail/${offer_id}`} />
               {dados ? (
                    <div id="offer-details">
                         <div className="container container-offer-details">
                              <div className="row content-offer-details">
                                   <div className="col-12">
                                        <div className="title-offer-details">
                                             <h1>{t('offerdetailstitleh1')}</h1>
                                        </div>
                                        <div className="background-box-offer-details">
                                             <div className="box-offer-details">
                                                  <img src={icone} alt={nome} />
                                                  <h3>{nome}</h3>
                                                  <div className="box-info">
                                                       <div className="info-item">
                                                            <h4>{t('recompensa')}</h4>
                                                            <div className="info-content">
                                                                 {/* <img src="../src/assets/icons/reward-icon-left.svg" alt="" /> */}
                                                                 <img src="/image/icons/moeda.webp" alt="Buxx" style={{ width: "25px" }} />
                                                                 <span>{totalReward} BUXX</span>
                                                            </div>
                                                       </div>
                                                       <div className="divider"></div>
                                                       <div className="info-item">
                                                            <h4>{t('device')}</h4>
                                                            <div className="info-content">
                                                                 <img src={deviceIcon} alt="" />
                                                                 <span>{device}</span>
                                                            </div>
                                                       </div>
                                                  </div>
                                                  <p>{shortDesc}</p>
                                             </div>
                                             {(userDevice === "m" || dados.os === "WEB" || dados.os === "ALL") && (
                                                  <div className="mt-3">
                                                       <a href={theUrl} target="_blank" className="btn-custom-offer-details" style={{ textDecoration: 'none' }}><button className="buxx-btn w-100">{t('ganheseusbuxx')}</button></a>
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                                   <div className="full-height-center">
                                        <div className="container-box-qrcode">
                                             {showInputs ? (
                                                  <div className="email-box">
                                                       <div className="text-container">
                                                            <h4>{t('recbyemail')}</h4>
                                                            <div className="input-group">
                                                                 <input
                                                                      type="text"
                                                                      className="form-control email-input"
                                                                      aria-label={t('email')}
                                                                      defaultValue={email}
                                                                      disabled
                                                                 />
                                                            </div>
                                                            <button type="button" className="buxx-btn w-100" onClick={enviaMail}>{t('enviar')}</button>
                                                       </div>
                                                       <div className="qr-code">
                                                            <QRCode
                                                                 size={256}
                                                                 className="img-fluid"
                                                                 value={theUrl}
                                                                 viewBox={`0 0 256 256`}
                                                            />
                                                            <p>{t('scanqrcode')}</p>
                                                       </div>
                                                  </div>
                                             ) : (
                                                  <p>{t('wesent')} {sentTo}</p>
                                             )}
                                        </div>
                                   </div>
                                   <div className="compaing-description">
                                        <h1>{t('offerdetailssubtitle')}</h1>
                                        <button className="btn-custom-description" style={{ cursor: "default" }}>{longDesc}</button>
                                   </div>
                              </div>
                         </div>


                         <div className="container container-campaign-stages">
                              <h1>{t('offerstages')}</h1>
                              {showProgress ? (
                                   <div className="progress-container">
                                        <div className="progress-title">{t('yourprogress')}</div>
                                        <div className="progress" role="progressbar" aria-label="Success example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                             <div className="progress-bar sbx-od-progress-bar-bg" style={{ width: `${percentualConquistado}%` }}></div>
                                        </div>
                                   </div>
                              ) : (
                                   null
                              )}
                         </div>

                         {eventos ? (
                              <div className="container container-table">
                                   <table>
                                        <thead>
                                             <tr>
                                                  <th>{t('stepbaraction')}</th>
                                                  <th>{t('recompensa')} (BUXX)</th>
                                             </tr>
                                        </thead>
                                        <tbody>
                                             {eventos
                                                  .sort((a, b) => a.ordem - b.ordem)
                                                  .map((ev, index) => (
                                                       <tr>
                                                            <td>
                                                                 {ev.ename}
                                                            </td>
                                                            <td className="reward">
                                                                 {ev.payout > 0 ? (<span>{ev.payout} BUXX</span>) : null}
                                                            </td>
                                                       </tr>
                                                  ))
                                             }
                                        </tbody>
                                   </table>
                              </div>

                         ) : (null)
                         }

                         <div className="spacing-como-funciona-box">
                              <HowItWorks />
                         </div>

                         <div className="container">
                              <div className="tile-text-global">
                                   <h2>{t('globalconditionstitle')}</h2>
                              </div>
                              <div className="pragraph-global">
                                   <p>{t('globalconditionstxt')}</p>
                              </div>
                         </div>

                    </div>

               ) : (
                    showErro ? (
                         // Exibir um componente
                         <p>Esta campanha não existe ou não está ativa em seu país.</p>
                    ):(<SbxSpinner />)
                    
               )
               }

          </Fragment>
     )
}
export default OfferDetail