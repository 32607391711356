export const createImage = (url) =>
     new Promise((resolve, reject) => {
          const image = new Image()
          image.addEventListener('load', () => resolve(image))
          image.addEventListener('error', (error) => reject(error))
          image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
          image.src = url
     })

export function getRadianAngle(degreeValue) {
     return (degreeValue * Math.PI) / 180
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
     const rotRad = getRadianAngle(rotation)

     return {
          width:
               Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
          height:
               Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
     }
}

export async function getCroppedImg(imageSrc, croppedAreaPixels) {
     const image = new Image();
     image.src = imageSrc;
     await new Promise((resolve) => {
          image.onload = resolve;
     });

     const canvas = document.createElement('canvas');
     const ctx = canvas.getContext('2d');

     // Defina as dimensões do canvas para o tamanho desejado
     canvas.width = 300;
     canvas.height = 300;

     // Desenhe a imagem recortada no canvas
     ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          canvas.width,
          canvas.height
     );

     return new Promise((resolve) => {
          canvas.toBlob((file) => {
               resolve(URL.createObjectURL(file));
          }, 'image/jpeg');
     });
}

