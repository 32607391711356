import React, { useContext, useState } from "react"
import { getAuth } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../../contexts/AuthContext"
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail } from "firebase/auth"
import { updateMailStatus } from "../functions/UpdateMailStatus"
import { verifyUser } from "../functions/VerifyUser"
import { addNewAdEvent } from "../functions/AddNewAdEvent"
import { getUserGeoData } from "../../helpers/users"
import { addUserIpTracking, UpdateUserLoginIp } from "../functions/AddUserIpTracking"
import { useTranslation } from "react-i18next"
import SeoTags from "../../views/common/SeoTags"

const Login = () => {
     const { setUsuario } = useContext(AuthContext)
     const [email, setEmail] = useState("")
     const [senha, setSenha] = useState("")
     const [signupres, setSignupres] = useState("")
     const navigate = useNavigate();

     const { t } = useTranslation()

     let id_Paes = null;

     // Resgata track click id do localstorage (ADS TRACKING)
     const adsTrackClickId = localStorage.getItem('sbxclickid')

     // Coleta os dados geográficos o user
     async function fetchUserGeoData() {
          try {
               const userGeoData = await getUserGeoData();
               const nomePais = userGeoData.countryName;
               const codPais = userGeoData.countryCode;
               return { nomePais, codPais };
          } catch (error) {
               console.log(error)
          }
     }

     async function getPaisData(code, name) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/get-pais/" + code + "/" + name)
               const data = await response.json()
               const id_pais = data.cData.id_pais
               return id_pais
          } catch (error) {
               console.log(error)
          }
     }

     const handleSignIn = async (e) => {
          e.preventDefault();
          const auth = getAuth();
          await signInWithEmailAndPassword(auth, email, senha)
               .then((userCredential) => {

                    // Signed in 
                    const user = userCredential.user;

                    // Grava ip do momento do login
                    if (user.uid) {
                         UpdateUserLoginIp(user.uid)
                    }

                    setUsuario(userCredential.user);
                    // setSignupres("")
                    const verificado = user.emailVerified

                    if (verificado) {
                         // chama api para atualizar banco de dados
                         updateMailStatus(user.uid)
                         navigate("/")
                    } else {
                         navigate("/verifyemail")
                    }
               })

               .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    if (errorCode === "auth/invalid-login-credentials") {
                         setSignupres('Senha incorreta. Tente novamente ou clique em "Esqueci minha senha" para escolher outra.')
                    } else {
                         setSignupres("Ocorreu um erro inesperado. Tente novamente ou fale com o suporte. Código do erro: " + errorCode)
                    }
               });
     }

     const handleRecupera = async () => {
          // Antes de tudo, verifica se email está cadastrado
          const auth = getAuth();
          try {
               await sendPasswordResetEmail(auth, email);
               setSignupres("Verifique seu email para recuperar sua senha.");
          } catch (error) {
               console.log(error);
               const errorCode = error.code;
               const errorMessage = error.message;
               setSignupres(errorCode + ": " + errorMessage);
          }
     }

     const handleGoogle = async () => {
          const auth = getAuth();
          const provider = new GoogleAuthProvider();
          signInWithPopup(auth, provider)
               .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    // The signed-in user info.
                    const user = result.user;
                    const uid = user.uid
                    setUsuario(user)

                    // Verificar se usuário existe na tabela
                    verifyUser(uid).then(resultado => {
                         if (!resultado) {
                              // se não encontrou uid, grava dados na tabela

                              // atribuição de país
                              async function getDados() {
                                   try {
                                        const { nomePais, codPais } = await fetchUserGeoData();

                                        try {
                                             const idPais = await getPaisData(codPais, nomePais)
                                             id_Paes = idPais;
                                        } catch (erro) {
                                             console.error("Erro ao obter dados do país em banco de dados:", erro)
                                        }
                                   } catch (error) {
                                        console.error("Erro ao obter dados geográficos do usuário:", error);
                                   }
                              }
                              (async function () {
                                   await getDados(); // Aguarde a promessa ser resolvida
                                   // This gives you a Google Access Token. You can use it to access the Google API.
                                   const credential = GoogleAuthProvider.credentialFromResult(result);
                                   const token = credential.accessToken;
                                   // The signed-in user info.
                                   const user = result.user;
                                   const email = user.email;
                                   setUsuario(user)
                                   const { uid, providerId } = user || {};
                                   const user_status = 1

                                   createUser(uid, email, providerId, user_status, id_Paes);
                                   addUserIpTracking(uid)
                                   UpdateUserLoginIp(uid)

                              })();
                         } else {
                              UpdateUserLoginIp(uid)
                         }
                    });

                    navigate("/")
               }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    // const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    setSignupres(credential)
                    console.log("Erro login:", error)
               });
     }

     const enviarPostback = async (idClick, idEvent) => {
          const url = 'https://conversions.kypi.io/postback?click_id=' + idClick + '&security_token=113432ed-a639-4bd8-8c46-fac84b818b91&event_id=' + idEvent;
          try {
               await fetch(url, { method: 'HEAD' });
          } catch (error) {
               // gravar log de erro
          }
     };

     async function createUser(uid, email, provider, user_status, id_country) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/user-create", {
                    method: "POST",
                    headers: {
                         "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ uid, email, provider, user_status, id_country }),
               });
               // aqui verifica se a api gravou os dados
               const data = await response.json();
               if (data.sucesso === true) {

                    // [JUL-2024] - grava evento em ads_tracking
                    if (adsTrackClickId) {
                         addNewAdEvent(adsTrackClickId, uid, "signup")
                         enviarPostback(adsTrackClickId, "signup")
                    }

                    // [OUT - 2024] - RODA TAG GOOGLE
                    gtag('event', 'ads_conversion_S_inscrire_1', {
                         // <event_parameters>
                    });


               } else if (data.existe === "sim") {
                    console.log("Uid já existe!")
                    // não faz nada
               } else {
                    console.error("Erro ao criar o registro:", data.error);
                    // Se não criou registro
                    // 1 - grava log do erro
                    // 2 - seta mensagem, seta NAVIGATION
               }

          } catch (error) {
               // não acessou a api
               console.error("Erro ao acessar a api de gravação de dados:", error);
          }
     }

     return (
          <div className="full-screen-container">
               {
                    <SeoTags
                         title="Super Buxx Login"
                         description="Super Buxx Login"
                         url="https://www.superbuxx.com/login"
                    />
               }
               <div className="form-container">
                    <h2>{t('loginheader')}</h2>
                    <form>
                         <div className="input-with-icon mb-3">
                              <img src="../src/assets/icons/icon-email-cadastro.svg" alt="Email Icon" />
                              <input
                                   type="text"
                                   name="email"
                                   placeholder={t('email')}
                                   onChange={(e) => setEmail(e.target.value)}
                                   onFocus={(e) => { setSignupres("") }}
                              />
                         </div>
                         <div className="input-with-icon">
                              <img src="../src/assets/icons/icon-senha-cadastro.svg" alt="Email Icon" />
                              <input
                                   type="password"
                                   name="senha"
                                   className="modal-input"
                                   placeholder={t('password')}
                                   onChange={(e) => setSenha(e.target.value)}
                                   onFocus={(e) => { setSignupres("") }}
                              />
                         </div>
                         <div className="sbx-warning-area text-end">
                              <small onClick={handleRecupera} style={{ cursor: 'pointer' }}>{t('forgotpassword')}</small>
                         </div>
                         {signupres ? (
                              <div className="error-container">
                                   <div className="error-icon"><img src="../src/assets/icons/icon-error-login.svg" alt="" /></div>
                                   <div className="error-text">{signupres}</div>
                              </div>
                         ) : (null)}

                         <div className="btn-cadastro">
                              <button type="submit" className="btn-criar-conta mb-3" onClick={handleSignIn}>{t('loginentrar')}</button>
                              <button type="button" className="buxx-btn w-100" onClick={handleGoogle}>
                                   <img src="../src/assets/icons/google-icon-button.png" alt="" />
                                   <span>{t('contwithgoogle')}</span>
                              </button>
                              <p className="mb-3 mt-3">{t('orjoinfree')}</p>
                              <a href="/signupmail"><button type="button" className="btn-criar-conta mb-3">{t('joinnow')}</button></a>
                         </div>
                    </form>
               </div>
          </div>
     )
}
export default Login