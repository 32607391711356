// Formulário de edição de dados do usuário
import { AuthContext } from "../../contexts/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { brCpfTester } from "../../helpers/brcpftester";
import { useTranslation } from "react-i18next";
import SeoTags from "../common/SeoTags";

const BrPaymentInfo = props => {
     const { t } = useTranslation()
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario
     const [cellNumber, setCellNumber] = useState("")
     const [ddiNumber, setDdiNumber] = useState("")
     const [idPais, setIdPais] = useState("")
     const [verifyNumber, setVerifyNumber] = useState("")
     const limiteDiasTroca = 30 //número de dias em que é permitido trocar o número do celular
     const [show, setShow] = useState(false);
     const [modalShow, setModalShow] = useState(false)
     const handleShow = () => setModalShow(true);

     const [brCpf, setBrCpf] = useState(null)
     const [brPix, setBrPix] = useState(null)
     const [statusBtOk, setStatusBtOk] = useState(true)
     const [showModal, setShowModal] = useState(false)
     const [isInvalidCpf, setIsInvalidCpf] = useState(false)
     const [isInvalidPix, setIsInvalidPix] = useState(false)
     const [showInvalidCpf, setShowInvalidCpf] = useState(false)
     const [showInvalidPix, setShowInvalidPix] = useState(false)
     const [cpfInputClass, setCpfInputClass] = useState(false)
     const [showModalNewCpf, setShowModalNewCpf] = useState(false)
     const [showModalNewPix, setShowModalNewPix] = useState(false)

     // Restaura dados de pagamento, se houver
     useEffect(() => {
          fetch("https://www.superbuxx.com:3030/get-user-pay-data-br/" + uid, {
               method: "GET",
               headers: { "Content-Type": "application/json" }
          })
               .then(response => response.json())
               .then(data => {
                    setBrCpf(data.br_cpf)
                    setBrPix(data.br_pix)
                    if (data.br_cpf) {
                         setCpfInputClass(true)
                    }
               })
               .catch(error => {
                    console.error("Erro na requisição:", error);
               });
     }, [uid])

     useEffect(() => {
          if (brPix && brCpf) {
               setStatusBtOk(false)
          } else {
               setStatusBtOk(true)
          }

     }, [brPix, brCpf])

     // Verifica validade do CPF, abre ou não modal de confirmação
     const handlePayData = () => {
          const isCpfValid = brCpfTester(brCpf)
          if (isCpfValid == false) {
               setShowInvalidCpf(t('invalidcpf'))
               setIsInvalidCpf('is-invalid')
          } else {
               setShowModal(true)
               setShowInvalidCpf(false)
          }

     }

     const handleClose = () => {
          if (showModal) {
               setShowModal(false)
          }
     }

     const handleCloseNewCpf = () => {
          if (showModalNewCpf) {
               setShowModalNewCpf(false)
          }
     }

     const handleCloseNewPix = () => {
          if (showModalNewPix) {
               setShowModalNewPix(false)
          }
     }

     const handleGravar = async () => {
          const response = await fetch("https://www.superbuxx.com:3030/add-user-pay-data-br", {
               method: "POST",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({ uid, brCpf, brPix }),
          });
          const data = await response.json();
          if (data.iscpf) {
               setShowModal(false)
               setIsInvalidCpf('is-invalid')
               setShowInvalidCpf(t('inusecpf'))
          }
          if (data.ispix) {
               setShowModal(false)
               setIsInvalidPix('is-invalid')
               setShowInvalidPix(t('inusepixkey'))
          }

          if (data.newCpf == true) {
               setShowModal(false)
               setShowModalNewCpf(true)
               setCpfInputClass(true)
          }

          if (data.newPix == true) {
               setShowModal(false)
               setShowModalNewPix(true)
          }

     }

     const handleChange = (e) => {
          const inputValue = e.target.value;
          // Remove caracteres não numéricos (exceto números)
          const cleanedValue = inputValue.replace(/\D/g, '');
          setBrCpf(cleanedValue);
     };

     const handleClearInput = () => {
          if (isInvalidCpf) {
               setIsInvalidCpf(false)
               setShowInvalidCpf(false)
          }

          if (isInvalidPix) {
               setIsInvalidPix(false)
               setShowInvalidPix(false)
          }
     }

     return (
          <Fragment>
               <SeoTags url="https://www.superbuxx.com/brpayinfo" />
               <div className="container-fluid banner-header text-white text-center py-3">
                    <h1>{t('pixtitleh1')}</h1>
               </div>

               <div className="container-fluid container-spacing">
                    <div className="row">
                         <div className="col mb-5">
                              <div id="offer-details">
                                   <div className="container container-offer-details">
                                        <div className="row content-offer-details" style={{ marginTop: "0" }}>
                                             <div className="col-12">
                                                  <div className="background-box-offer-details">
                                                       <div className="box-offer-details">
                                                            <p><strong>{t('pixinfotitle')}</strong></p>
                                                            <p>{t('pixinfop1')}</p>
                                                            <p>{t('pixinfop2')}</p>
                                                            <p>{t('pixinfop3')}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div className="col">
                              <div className="container-fluid container-md">
                                   <div className="row">
                                        <div className="col-6 mb-3">
                                             <div className="input-field">
                                                  <label htmlFor="pixkey" className="form-label">{t('pixkeylabel')}</label>
                                                  <input
                                                       type="text"
                                                       className={`form-control ${isInvalidPix}`}
                                                       id="pixkey"
                                                       name="pix"
                                                       placeholder={t('pixkeylabel')}
                                                       onChange={(e) => setBrPix(e.target.value)}
                                                       value={brPix}
                                                       onClick={handleClearInput}
                                                  />
                                             </div>
                                             {showInvalidPix ? (
                                                  <div className="sbx-warning-area">
                                                       <small className="sbx-msg-bad">{showInvalidPix}</small>
                                                  </div>
                                             ) : (null)}
                                        </div>
                                        <div className="col-6 mb-3">
                                             <div className="input-field">
                                                  <label htmlFor="docnum" className="form-label">{t('accountcpf')}</label>
                                                  <input
                                                       type="text"
                                                       className={`form-control ${isInvalidCpf}`}
                                                       id="docnum"
                                                       name="doc"
                                                       placeholder={t('cpfplaceholder')}
                                                       onChange={handleChange}
                                                       value={brCpf}
                                                       onClick={handleClearInput}
                                                       disabled={cpfInputClass}
                                                  />
                                             </div>
                                             {showInvalidCpf ? (
                                                  <div className="sbx-warning-area">
                                                       <small className="sbx-msg-bad">{showInvalidCpf}</small>
                                                  </div>
                                             ) : (null)}
                                        </div>
                                   </div>

                                   <button
                                        className="btn btn-to-check-2 col-12"
                                        disabled={statusBtOk}
                                        onClick={() => handlePayData()}
                                   >
                                        {t('savedatabt')}
                                   </button>
                              </div>

                         </div>

                    </div>

               </div>

               {showModal && (
                    <div
                         className="modal fade show"
                         id="verifyCellModal"
                         tabIndex="-1"
                         aria-labelledby="verifyCellModalLabel"
                         aria-hidden="true"
                         style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    >
                         <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                   <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">{t('pixwarning')}</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                                   </div>
                                   <div className="modal-body text-center">
                                        <p>{t('whenclick')} <strong>"{t('confirmlink')}",</strong> {t('warningpixtxt1')}</p>
                                        <p><strong>{t('important')}</strong> {t('warningpixtxt2')}</p>
                                   </div>
                                   <div className="modal-footer">
                                        <button type="button" className="buxx-btn" data-dismiss="modal" onClick={handleClose}>{t('tocancelbt')}</button>
                                        <button type="button" className="buxx-btn outlined" onClick={handleGravar}><strong>{t('toconfirmbt')}</strong></button>
                                   </div>
                              </div>
                         </div>
                    </div>
               )}

               {
                    showModalNewCpf || showModalNewPix ? (
                         <div className="toast-container">
                              {showModalNewCpf && (
                                   <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={handleCloseNewCpf}></button>
                                        <div className="toast-body">
                                             <div className="icon">

                                                  <img src="../src/assets/icons/toast-success.svg" alt="" />

                                             </div>
                                             <strong>{t('cpfsuccess')}</strong>
                                             <p>{t('cpfsuccesstxt')}</p>
                                        </div>
                                   </div>
                              )}

                              {showModalNewPix && (
                                   <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={handleCloseNewPix}></button>
                                        <div className="toast-body">
                                             <div className="icon">

                                                  <img src="../src/assets/icons/toast-success.svg" alt="" />

                                             </div>
                                             <strong>{t('pixsuccess')}</strong>
                                             <p>{t('pixsuccesstxt')}</p>
                                        </div>
                                   </div>
                              )}

                         </div>
                    ) : (null)
               }

          </Fragment>
     )
}

export default BrPaymentInfo