import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Faq = () => {
     const { t } = useTranslation();
     const faq = [
          {
               ask: t('faqask1'),
               res: t('faqres1')
          },
          {
               ask: t('faqask2'),
               res: t('faqres2')
          },
          {
               ask: t('faqask3'),
               res: t('faqres3')
          },
          {
               ask: t('faqask4'),
               res: t('faqres4')
          },
          {
               ask: t('faqask5'),
               res: t('faqres5')
          },
          {
               ask: t('faqask6'),
               res: t('faqres6')
          },
          {
               ask: t('faqask8'),
               res: t('faqres8')
          },
          {
               ask: t('faqask9'),
               res: t('faqres9')
          },
          {
               ask: t('faqask10'),
               res: t('faqres10')
          },
          {
               ask: t('faqask11'),
               res: t('faqres11')
          },
          {
               ask: t('faqask12'),
               res: t('faqres12')
          },
          {
               ask: t('faqask13'),
               res: t('faqres13')
          },
          {
               ask: t('faqask14'),
               res: t('faqres14')
          },

     ]

     let show = "show"
     let expanded = true

     return (
          <Fragment>
               <div class="container-fluid banner-header text-white text-center py-3">
                    <h1>{t('faqtitle')}</h1>
                    <div class="paragrafo-homepage-log-out">
                         <p>{t('faqsubtitle')}</p>
                    </div>
               </div>

               <div class="container">
                    <div class="row">
                         <div className="col-12">
                              <div className="accordion" id="common-questions-accordion">
                                   {faq.map((item, index) => {
                                        // Inicialize as variáveis show e expanded
                                        show = "";
                                        expanded = false;

                                        return (
                                             <div className="accordion-item" key={index}>
                                                  <h3 className="accordion-header" id={`heading${index}`}>
                                                       <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#collapse${index}`}
                                                            aria-expanded={expanded}
                                                            aria-controls={`collapse${index}`}
                                                       >
                                                            {item.ask}
                                                            <span className="chevron-icon">
                                                                 <img src="../src/assets/icons/chevron-down.svg" alt="Chevron Icon" />
                                                            </span>
                                                       </button>
                                                  </h3>
                                                  <div
                                                       id={`collapse${index}`}
                                                       className={`accordion-collapse collapse ${show}`}
                                                       aria-labelledby={`heading${index}`}
                                                       data-bs-parent="#common-questions-accordion"
                                                  >
                                                       <div className="accordion-body">{item.res}</div>
                                                  </div>
                                             </div>
                                        );
                                   })}
                              </div>
                         </div>
                    </div>
               </div>
          </Fragment>
     )
}

export default Faq