import "./offerwalls.css"
import React, { Fragment, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { recLastActv } from "../../components/functions/RecLastActv";
import { useEffect } from "react";
import SeoTags from "../common/SeoTags";

const NotikOfferWall = props => {
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario
     const url = "https://notik.me/coins?api_key=UMupvOtl3fwgyit1y6M59ZLZzGyNToYc&pub_id=Z74HLk&app_id=UVYCLmQmEg&user_id=" + uid

     useEffect(() => {
          if (uid) {
               // Registra ação
               recLastActv(uid, "Entrou ow Notik")
          }
     }, [uid])

     return (
          <Fragment>
               <SeoTags
                    title="Notik Offerwall"
                    description="Notik Offerwall"
                    url="https://www.superbuxx.com/notik"
               />
               <div className="mt-5">
                    <div className="offerwall">
                         <iframe
                              src={url}
                              style={{
                                   height: "100vh",
                                   width: "100%",
                                   margin: 0,
                                   padding: 0,
                                   border: 0
                              }}
                         >
                         </iframe>
                    </div>
               </div>
          </Fragment>
     )
}
export default NotikOfferWall