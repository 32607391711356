import React from "react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { Row, Col, Container } from "react-bootstrap";
import("./Ticket.css")

const TicketError = () => {
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario
     const errorMsg = localStorage.getItem("sbxTicketErrorMsg")
     
     return (
          <Container fluid>
               <Row className="container-loggedin">
                    <h1 className="sbx-page-title">Erro ao abrir o ticket</h1>
                    <p>{errorMsg}</p>
               </Row>
          </Container>
     )

}

export default TicketError