export async function getPayout(offer_id, iso_pais) {
     try {
          const response = await fetch("https://www.superbuxx.com:3030/get-payout-pais/" + offer_id + "/" + iso_pais, {
               method: "GET",
               headers: { "Content-Type": "application/json" }
          });
          const res = await response.json()
          return res
     } catch (error) {
          console.error(error)
          return false
     }
}