// Componente que chama o REACT-HELMET, que configura o cabeçalho das páginas (SEO)
import React from "react";
import { Helmet } from "react-helmet";

const SeoTags = props => {

     const title = props.title
     const description = props.description
     const url = props.url

     return (
          <Helmet>
               <title>{title}</title>
               <meta name="description" content={description}/>
               <link rel="canonical" href={url} />
          </Helmet>
     )
}

export default SeoTags