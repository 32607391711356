import "./offerwalls.css"
import React, { Fragment, useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { recLastActv } from "../../components/functions/RecLastActv";
import SeoTags from "../common/SeoTags";

const RevtooOfferwall = props => {
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario
     const url = 'https://revtoo.com/offerwall/rh7x9ta80b6za8xqdoykr02evrs5mq/' + uid;

     useEffect(() => {
          if (uid) {
               // Registra ação
               recLastActv(uid, "Entrou ow Revtoo")
          }
     }, [uid])

     return (
          <Fragment>
               <SeoTags
                    title="Revtoo Offerwall"
                    description="Revtoo Offerwall"
                    url="https://www.superbuxx.com/revtoo"
               />
               <div className="mt-5">
                    <div className="offerwall">
                         <iframe
                              src={url}
                              style={{
                                   height: "100vh",
                                   width: "100%",
                                   margin: 0,
                                   padding: 0,
                                   border: 0
                              }}
                         >
                         </iframe>
                    </div>
               </div>
          </Fragment>

     )

}
export default RevtooOfferwall