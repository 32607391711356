export async function getTicketsList(uid) {
     // Função que retorna a lista de tickets por usuário
     try {
          const response = await fetch("https://www.superbuxx.com:3030/get-tickets-data/" + uid, {
               method: "GET",
          });
         
          const res = await response.json();
          return res
     } catch (error) {
          console.error(error)
          return false
     }
}