import React from "react";
import { useContext, useEffect, useState, useCallback, Fragment } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { Row, Col, Container } from "react-bootstrap";
import { getOpenTicketData } from "../../components/functions/GetOpenTicketData";
import 'react-toastify/dist/ReactToastify.css'
import("./Ticket.css")
import UserClickList from "../../components/data/UserClickList";
import { getUserClickData } from "../../components/functions/GetUserClickData";
import { getTicketsList } from "../../components/functions/GetTicketsList";
import TicketsList from "../../components/data/TicketsList";
import TicketsClosedList from "../../components/data/TicketsClosedList";
import { getTicketsClosedList } from "../../components/functions/GetTicketsClosedList";
import { useTranslation } from "react-i18next";


const TicketsAdm = () => {
     const {t} = useTranslation()
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario

     const [txn, setTxn] = useState([])
     const [hits, setHits] = useState([])
     const [ticketsClosed, setTicketsClosed] = useState([])

     const [dtIni, setDtIni] = useState("")
     const [dtFim, setDtFim] = useState("")
     const [campanha, setCampanha] = useState("")
     const [dtIni2, setDtIni2] = useState("")
     const [dtFim2, setDtFim2] = useState("")
     const [dtIni3, setDtIni3] = useState("")
     const [dtFim3, setDtFim3] = useState("")
     const [campanha2, setCampanha2] = useState("")
     const [campanha3, setCampanha3] = useState("")

     const [activeKey, setActiveKey] = useState('confirmado');


     const [selectedStartDate, setSelectedStartDate] = useState(null)
     const [selectedEndDate, setSelectedEndDate] = useState(null)
     const [selectedStartDate2, setSelectedStartDate2] = useState(null)
     const [selectedEndDate2, setSelectedEndDate2] = useState(null)
     const [selectedStartDate3, setSelectedStartDate3] = useState(null)
     const [selectedEndDate3, setSelectedEndDate3] = useState(null)

     const resetFilters = useCallback(() => {
          setSelectedStartDate(null);
          setSelectedEndDate(null);
          setCampanha('');
          setDtIni("")
          setDtFim("")
     }, []);

     const resetFilters2 = useCallback(() => {
          setSelectedStartDate2(null);
          setSelectedEndDate2(null);
          setCampanha2('');
          setDtIni2("")
          setDtFim2("")
     }, []);

     const resetFilters3 = useCallback(() => {
          setSelectedStartDate3(null);
          setSelectedEndDate3(null);
          setCampanha3('');
          setDtIni3("")
          setDtFim3("")
     }, []);

     useEffect(() => {
          getTicketsList(uid)
               .then((resultado) => {
                    //Filtrar por campanha
                    // const filterByCampanha = resultado.filter(key => {
                    //      const campanhaLowerCase = campanha.toLowerCase();
                    //      const eventNameLowerCase = key.offer_name.toLowerCase();

                    //      return eventNameLowerCase.includes(campanhaLowerCase);
                    // });

                    //Filtrar por data
                    const filterByDate = resultado.filter(key => {
                         const txnDate = new Date(key.update).setHours(0, 0, 0, 0)
                         const startDate = selectedStartDate ? new Date(selectedStartDate + 'T00:00:00').getTime() : null;
                         const endDate = selectedEndDate ? new Date(selectedEndDate + 'T23:59:59') : null;

                         // Caso não haja data inicial ou data inicial esteja preenchida e não haja data final
                         if (!startDate || (startDate && !endDate)) {
                              return (!startDate || txnDate === startDate);
                         }

                         // Caso haja data inicial e data final
                         return (txnDate >= startDate && txnDate <= endDate);
                    })

                    setTxn(filterByDate)

               })
               .catch((error) => {
                    console.error('Erro ao recuperar registros: ', error)
               })

     }, [selectedStartDate, selectedEndDate, resetFilters])

     useEffect(() => {
          getTicketsClosedList(uid)
               .then((resultado) => {

                    //Filtrar por data
                    const filterByDate = resultado.filter(key => {
                         const txnDate = new Date(key.update).setHours(0, 0, 0, 0)
                         const startDate = selectedStartDate ? new Date(selectedStartDate + 'T00:00:00').getTime() : null;
                         const endDate = selectedEndDate ? new Date(selectedEndDate + 'T23:59:59') : null;

                         // Caso não haja data inicial ou data inicial esteja preenchida e não haja data final
                         if (!startDate || (startDate && !endDate)) {
                              return (!startDate || txnDate === startDate);
                         }

                         // Caso haja data inicial e data final
                         return (txnDate >= startDate && txnDate <= endDate);
                    })

                    setTicketsClosed(filterByDate)

               })
               .catch((error) => {
                    console.error('Erro ao recuperar registros: ', error)
               })

     }, [selectedStartDate3, selectedEndDate3, resetFilters3])


     useEffect(() => {
          getUserClickData(uid)
               .then((resultado) => {
                    //Filtrar por campanha
                    const filterByCampanha = resultado.filter(key => {
                         const campanhaLowerCase = campanha2.toLowerCase();
                         const eventNameLowerCase = key.offer_name.toLowerCase();
                         return eventNameLowerCase.includes(campanhaLowerCase);
                    });

                    // Filtrar por data
                    const filterByDate = filterByCampanha.filter(key => {
                         const txnDate = new Date(key.click_time).setHours(0, 0, 0, 0)
                         const startDate = selectedStartDate2 ? new Date(selectedStartDate2 + 'T00:00:00').getTime() : null;
                         const endDate = selectedEndDate2 ? new Date(selectedEndDate2 + 'T23:59:59') : null;

                         // Caso não haja data inicial ou data inicial esteja preenchida e não haja data final
                         if (!startDate || (startDate && !endDate)) {
                              return (!startDate || txnDate === startDate);
                         }

                         // Caso haja data inicial e data final
                         return (txnDate >= startDate && txnDate <= endDate);
                    })

                    setHits(filterByDate)
               })
     }, [selectedStartDate2, selectedEndDate2, resetFilters2, campanha2])

     return (
          <Fragment>
               <div class="container-fluid banner-header text-white text-center py-3">
                    <h1>{t('ticketstitleh1')}</h1>
                    <div class="row">
                         <div className="buttons-header">
                              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                   <li className="nav-item me-3 mb-2" role="presentation">
                                        <button className="btn btn-header-1 text-uppercase active" id="pills-novo-tab" data-bs-toggle="pill" data-bs-target="#pills-novo" type="button" role="tab" aria-controls="pills-novo" aria-selected="true">{t('opennewtkt')}</button>
                                   </li>
                                   <li className="nav-item me-3 mb-2" role="presentation">
                                        <button className="btn btn-header-1 text-uppercase" id="pills-pendente-tab" data-bs-toggle="pill" data-bs-target="#pills-pendente" type="button" role="tab" aria-controls="pills-pendente" aria-selected="false">{t('openedtkt')}</button>
                                   </li>
                                   <li className="nav-item" role="presentation">
                                        <button className="btn btn-header-1 text-uppercase" id="pills-closed-tab" data-bs-toggle="pill" data-bs-target="#pills-closed" type="button" role="tab" aria-controls="pills-closed" aria-selected="false">{t('closedtkt')}</button>
                                   </li>
                              </ul>
                         </div>
                    </div>
               </div>
               <div class="tab-content container-fluid container-spacing" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-novo" role="tabpanel" aria-labelledby="pills-novo-tab" tabindex="0">
                         <div class="row align-items-end mb-4">
                              <div class="col-12 col-md-3">
                                   <div class="input-field">
                                        <label for="formControlInput" class="form-label">{t('dtini')}</label>
                                        <input
                                             className="form-control"
                                             type="date"
                                             name="dt-ini2"
                                             value={dtIni2 || ""}
                                             onChange={(e) => { setSelectedStartDate2(e.target.value); setDtIni2(e.target.value) }}
                                        />
                                   </div>
                              </div>
                              <div class="col-12 col-md-3">
                                   <div class="input-field">
                                        <label for="formControlInput" class="form-label">{t('dtfim')}</label>
                                        <input
                                             type="date"
                                             class="form-control"
                                             id="formControlInput"
                                             name="dt-fim"
                                             value={dtFim || ""}
                                             onChange={(e) => { setSelectedEndDate2(e.target.value); setDtFim2(e.target.value) }}
                                             disabled={!selectedStartDate2}
                                        />
                                   </div>
                              </div>
                              <div class="col-12 col-md-3">
                                   <div class="input-field">
                                        <label for="formControlInput" class="form-label">{t('offer')}</label>
                                        <input
                                             type="text"
                                             class="form-control"
                                             name="offername2"
                                             id="offername2"
                                             value={campanha2 || ""}
                                             onChange={(e) => setCampanha2(e.target.value)}
                                        />
                                   </div>
                              </div>
                              <div class="col-12 col-md-3">
                                   <button className="buxx-btn w-100 bt-clear-filter" type="button" onClick={resetFilters2}>{t('clsfilters')}</button>
                              </div>
                         </div>
                         {hits.length > 0 ? (
                              <Row>
                                   <UserClickList clicks={hits} />
                              </Row>
                         ) : (
                              <p>{t('udonttryoffer')}</p>
                         )}
                    </div>
                    <div className="tab-pane fade" id="pills-pendente" role="tabpanel" aria-labelledby="pills-pendente-tab" tabindex="0">
                         <div class="row align-items-end mb-4">
                              <div class="col-12 col-md-3">
                                   <div class="input-field">
                                        <label for="formControlInput" class="form-label">{t('dtini')}</label>
                                        <input
                                             className="form-control"
                                             type="date"
                                             name="dt-ini"
                                             value={dtIni || ""}
                                             onChange={(e) => { setSelectedStartDate(e.target.value); setDtIni(e.target.value) }}
                                        />
                                   </div>
                              </div>
                              <div class="col-12 col-md-3">
                                   <div class="input-field">
                                        <label for="formControlInput" class="form-label">{t('dtfim')}</label>
                                        <input
                                             type="date"
                                             class="form-control"
                                             id="formControlInput"
                                             name="dt-fim"
                                             value={dtFim || ""}
                                             onChange={(e) => { setSelectedEndDate(e.target.value); setDtFim(e.target.value) }}
                                             disabled={!selectedStartDate}
                                        />
                                   </div>
                              </div>
                              <div class="col-12 col-md-3">
                                   <div class="input-field">
                                        <label for="formControlInput" class="form-label">{t('offer')}</label>
                                        <input
                                             type="text"
                                             class="form-control"
                                             name="offername"
                                             id="offername"
                                             value={campanha || ""}
                                             onChange={(e) => setCampanha(e.target.value)}
                                        />
                                   </div>
                              </div>
                              <div class="col-12 col-md-3">
                                   <button className="buxx-btn w-100 bt-clear-filter" type="button" onClick={resetFilters}>{t('clsfilters')}</button>
                              </div>
                         </div>
                         {txn.length > 0 ? (
                              <Row>
                                   <TicketsList transactions={txn} />
                              </Row>
                         ) : (
                              <p>{t('noopenedtickets')}</p>
                         )}
                    </div>
                    <div className="tab-pane fade" id="pills-closed" role="tabpanel" aria-labelledby="pills-closed-tab" tabindex="0">
                         <div class="row align-items-end mb-4">
                              <div class="col-12 col-md-3">
                                   <div class="input-field">
                                        <label for="formControlInput" class="form-label">{t('dtini')}</label>
                                        <input
                                             className="form-control"
                                             type="date"
                                             name="dt-ini3"
                                             value={dtIni3 || ""}
                                             onChange={(e) => { setSelectedStartDate3(e.target.value); setDtIni3(e.target.value) }}
                                        />
                                   </div>
                              </div>
                              <div class="col-12 col-md-3">
                                   <div class="input-field">
                                        <label for="formControlInput" class="form-label">{t('dtfim')}</label>
                                        <input
                                             type="date"
                                             class="form-control"
                                             id="formControlInput"
                                             name="dt-fim3"
                                             value={dtFim3 || ""}
                                             onChange={(e) => { setSelectedEndDate3(e.target.value); setDtFim3(e.target.value) }}
                                             disabled={!selectedStartDate3}
                                        />
                                   </div>
                              </div>
                              <div class="col-12 col-md-3">
                                   <div class="input-field">
                                        <label for="formControlInput" class="form-label">{t('offer')}</label>
                                        <input
                                             type="text"
                                             class="form-control"
                                             name="offername3"
                                             id="offername3"
                                             value={campanha3 || ""}
                                             onChange={(e) => setCampanha3(e.target.value)}
                                        />
                                   </div>
                              </div>
                              <div class="col-12 col-md-3">
                                   <button className="buxx-btn w-100 bt-clear-filter" type="button" onClick={resetFilters}>{t('clsfilters')}</button>
                              </div>
                         </div>
                         {ticketsClosed.length > 0 ? (
                              <Row>
                                   <TicketsClosedList transactions={ticketsClosed} />
                              </Row>
                         ) : (
                              <p>{t('noclosedtickets')}</p>
                         )}
                    </div>
               </div>
          </Fragment>
     )

}

export default TicketsAdm