
export async function getMoeda(id) {
          
     // Busca dados da moeda a partir do id da mesma
     try {
          const response = await fetch("https://www.superbuxx.com:3030/get-moeda/" + id, {
               method: "GET",
               headers: { "Content-Type": "application/json" }
          });
          if (response.ok) {
               const res = await response.json();
               if (res.sucesso) {
                    return res.mData
               } else {
                    return false
               }
          } else {
               return false
          }
     } catch (error) {
          console.error(error)
     }
}