import "./Signup.css";
import React, { useState, useEffect, useContext } from "react";
import { auth } from "../../config/firebaseConfig";
import { sendEmailVerification } from "firebase/auth";
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const SignUp = props => {

     const [email, setEmail] = useState("");
     const [senha, setSenha] = useState("");
     const auth = getAuth();

     const [mailres, setMailres] = useState("");
     const [signupres, setSignupres] = useState("");
     const [verifySenha, setVerifysenha] = useState("");

     const { setUsuario } = useContext(AuthContext)

     const navigate = useNavigate();

     async function sendMailVerify(dados) {
          try {
               await sendEmailVerification(dados);
               setMailres("Email de verificação enviado com sucesso!")

          } catch (error) {
               setMailres("Problema ao enviar email de confirmação: " + error)

          }
     }

     async function createUser(uid, provider, user_status) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/user-create", {
                    method: "POST",
                    headers: {
                         "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ uid, provider, user_status }),
               });
               // aqui verifica se a api gravou os dados
               const data = await response.json();
               if (data.sucesso === true) {
                    console.log("Registro criado com sucesso.");
                    // não faz nada
                    
               } else if (data.existe === "sim") {
                    console.log("Uid já existe!")
                    // não faz nada
               } else {
                    console.error("Erro ao criar o registro:", data.error);
                    // Se não criou registro
                    // 1 - grava log do erro
                    // 2 - seta mensagem, seta NAVIGATION
               }

          } catch (error) {
               // não acessou a api
               console.error("Erro ao acessar a api de gravação de dados:", error);
          }
     };

     const handleMailSenha = async (e) => {
          e.preventDefault();
               
          // Controla a qualidade da senha
          const senhaRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).{8,}$/;
          if (!senhaRegex.test(senha)) {
               setVerifysenha('A senha deve conter pelo menos 8 caracteres com pelo menos uma letra maiúscula, uma minúscula, um caractere especial e um número.');
               return;
          } else {
               setVerifysenha("")
          }

          await createUserWithEmailAndPassword(auth, email, senha)
               .then((userCredential) => {

                    // Signed in 
                    const user = userCredential.user;
                    setUsuario(userCredential.user);
                    setSignupres("")
                    
                    // Envia email de confirmação
                    sendMailVerify(user);

                    // Grava dados na tabela
                    const uid = user.uid
                    const provider = user.providerId
                    const verificado = user.emailVerified
                    const user_status = 0
                    if(verificado) {
                         navigate("/")
                    } else {
                         navigate("/verifyemail")
                    }
                    createUser(uid, provider, user_status);
               })
               .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // console.log(errorCode)
                    if (errorCode === 'auth/email-already-in-use') {
                         setSignupres("O email já está em uso!")
                    } else {
                         setSignupres("Ocorreu um erro inesperado. Usuário não registrado.")
                         // traduzir outros códigos de erro (ver documentação)
                    }
               });
     }

     const handleGoogle = async () => {
          const auth = getAuth();
          const provider = new GoogleAuthProvider();
          signInWithPopup(auth, provider)
               .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    // The signed-in user info.
                    const user = result.user;
                    setUsuario(user)
                    // IdP data available using getAdditionalUserInfo(result)
                    // ...
                    const { uid, providerId } = user || {};
                    const user_status = 1
                    // if (emailVerified) {
                    //      user_status = 1
                    // }
                    createUser(uid, providerId, user_status);
                    
                    navigate("/")
               }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    // ...
                    console.log(error)
               });
     }

     return (
          <div>
               <h2>Sign Up</h2>
               <form>
                    <div>
                         <input
                              type="email"
                              name="email"
                              placeholder="email"
                              onChange={(e) => setEmail(e.target.value)}
                         />
                    </div>
                    <div>
                         <input
                              type="password"
                              name="senha"
                              placeholder="Senha"
                              onChange={(e) => setSenha(e.target.value)}
                         />
                    </div>
                    <div>
                         <button className="bt_signup" onClick={handleMailSenha}>Confirmar</button>
                    </div>
                    <hr />
                    <div>
                         <button className="bt_signup" type="button" onClick={handleGoogle}>Google</button>
                    </div>
                    <div>
                         <button className="bt_signup">Facebook</button>
                    </div>
               </form>
               <small>{mailres}</small>
               <small>{signupres}</small>
               <small>{verifySenha}</small>
          </div>
     );
}

export default SignUp