import React, { useEffect, useContext, useState, Fragment } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import NotificationList from "../../components/data/NotificationLists";
import { useTranslation } from "react-i18next";
import SeoTags from "../common/SeoTags";

const Notifications = () => {
     const {t} = useTranslation()
     const { usuario } = useContext(AuthContext);
     const { uid } = usuario;
     const [notes, setNotes] = useState([]);

     async function getNotificationData(user_id) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/get-notifications/" + user_id, {
                    method: "GET",
               });
               if (response.ok) {
                    const res = await response.json();
                    if (res) {
                         setNotes(res.Notifications)
                    } else {
                         return false
                    }
               } else {
                    return false
               }
          } catch (error) {
               console.error(error)
               return false
          }
     }

     useEffect(() => {
          getNotificationData(uid)
     }, [])

     return (
          <Fragment>
               <SeoTags url="https://www.superbuxx.com/notifications"/>
               <div class="container-fluid banner-header text-white text-center py-3">
                    <h1>{t('notificationtitleh1')}</h1>
               </div>
               <NotificationList mensagens={notes} />
          </Fragment>
     );
};

export default Notifications;
