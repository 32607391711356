export function getDevice() {
     // Função de retornar o device do usuário

     var userAgent = window.navigator.userAgent.toLowerCase();
     var device;

     if (/win/.test(userAgent)) device = "windows";
     if (/iphone|ipad/.test(userAgent)) device = "ios";
     if (/macos|macintosh/.test(userAgent) && !/iphone|ipad/.test(userAgent)) device = "macos";
     if (/android/.test(userAgent)) device = "android";
     if (/linux/.test(userAgent) && !/android/.test(userAgent)) device = "linux";

     var platform;
     if (device == "ios") {
          platform = "IOS";
     }
     if (device == "macos" || device == "linux" || device == "windows") {
          platform = "WEB";
     }
     if (device == "android") {
          platform = "ANDROID";
     }

     return platform ? platform : "not_know";
}
