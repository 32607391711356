import React from "react";
const SbxSpinner = () => {
     return (
          <div className="d-flex justify-content-center align-items-center">
               <div className="spinner-border m-5" role="status">
                    <span className="visually-hidden">Loading...</span>
               </div>
          </div>
     )
}
export default SbxSpinner