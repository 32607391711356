import React, { useEffect, useState, Fragment } from "react";
import { getAuth, sendEmailVerification } from "firebase/auth";


const NoIpDefinedMsg = props => {

     const [enviar, setEnviar] = useState(false)

     useEffect(() => {
          if (enviar === true) {
               async function sendMailVerify() {
                    const auth = getAuth();
                    sendEmailVerification(auth.currentUser)
                         .then(() => {
                              toast.success("Email de verificação enviado com sucesso! Veja sua caixa de emails.", { theme: "dark" });
                         });
               }
               sendMailVerify()
          }
     }, [enviar])



     return (
          <Fragment>

               <div id="offer-details">
                    <div class="container container-offer-details">
                         <div class="row content-offer-details">
                              <div class="col-12">
                                   <div class="title-offer-details text-center">
                                        <h1>Não conseguimos verificar sua localização</h1>
                                   </div>
                                   <div class="background-box-offer-details">
                                        <div class="box-offer-details">
                                             <p><strong>Prezado usuário,</strong></p>
                                             <p>Identificar sua localização é uma condição essencial para que nosso sistema exiba ofertas habilitadas para sua região e assim garantir que você receba suas recompensas e tenha uma ótima experiência.</p>
                                             <p>A única maneira de identificarmos sua localização é através do seu endereço público de Ip. Quando você usa um VPN, Proxy ou esconde seu endereço de Ip de alguma maneira, não conseguimos determinar sua localização e por esse motivo nosso sistema não exibe nenhuma oferta.</p>
                                             <p>Para que você possa participar das ofertas da sua região e ganhar suas recompensas, desligue o VPN, Proxy ou qualquer outro dispositivo que não permita ler seu Ip público verdadeiro.</p>
                                             <p className="mb-3"><strong>Contamos com sua colaboração!<br />Equipe Super Buxx.</strong></p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </Fragment>
     )
}

export default NoIpDefinedMsg