// Exibe shape com mensagem quando não houver ofertas para uma categoria na home!
import React from "react";
import { useTranslation } from "react-i18next";

export default props => {
     const { t } = useTranslation()

     return (
          <div className="warning-message">
               <div id="offer-details">
                    <div className="content-offer-details" style={{ marginTop: "0", maxWidth:"100%" }}>
                         <div className="col">
                              <div className="background-box-offer-details">
                                   <div className="box-offer-details">
                                        <p style={{ color: "white" }}><strong>{t('noofferhometitle')}</strong></p>
                                        <p style={{ color: "white" }}>{t('noofferhometxt')}</p>
                                   </div>
                                   <div className="row parceiro-container spacing-box-top2" style={{ marginBottom: "2rem" }}>
                                        <div className="col-lg-3 col-sm-6">
                                             <a href="/torox" style={{ cursor: "pointer" }}>
                                                  <div className="parceiro-itens" style={{ height: "10rem" }}>
                                                       <img className="img-fluid" src="../src/assets/temp/torox-icon.png" alt="Torox" style={{ width: "84%" }} />
                                                  </div>
                                             </a>
                                        </div>
                                        <div className="col-lg-3 col-sm-6">
                                             <a href="/adgem" style={{ cursor: "pointer" }}>
                                                  <div className="parceiro-itens" style={{ height: "10rem" }}>
                                                       <img className="img-fluid" src="../src/assets/temp/adgem.png" alt="Adgem" style={{ width: "84%" }} />
                                                  </div>
                                             </a>
                                        </div>
                                        <div className="col-lg-3 col-sm-6">
                                             <a href="/revu" style={{ cursor: "pointer" }}>
                                                  <div className="parceiros-itens-color" style={{ height: "10rem" }}>
                                                       <img className="img-fluid" src="../src/assets/temp/revu.png" alt="Revu" style={{ width: "84%" }} />
                                                  </div>
                                             </a>
                                        </div>
                                        <div className="col-lg-3 col-sm-6">
                                             <a href="/notik" style={{ cursor: "pointer" }}>
                                                  <div className="parceiros-itens-color-2" style={{ height: "10rem" }}>
                                                       <img className="img-fluid" src="../src/assets/temp/notikme.png" alt="Notik" style={{ width: "84%" }} />
                                                  </div>
                                             </a>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     );
};
