import React, { Fragment } from "react";

function CountryUpdated() {

     //const { nomePais } = props.match.params;

     return (
          <Fragment>

               <div id="offer-details">
                    <div class="container container-offer-details">
                         <div class="row content-offer-details">
                              <div class="col-12">
                                   <div class="title-offer-details text-center">
                                        <h1>Localização verificada</h1>
                                   </div>
                                   <div class="background-box-offer-details">
                                        <div class="box-offer-details">
                                             <p><strong>Prezado usuário,</strong></p>
                                             <p>Sua localização foi identificada com sucesso!</p>
                                             <p>Agora você pode aproveitar todas as ofertas oferecidas para sua região. Boa sorte!</p>
                                             <p className="mb-3"><strong>Contamos com sua colaboração!<br />Equipe Super Buxx.</strong></p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </Fragment>
     )
}

export default CountryUpdated