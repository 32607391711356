// Exibe shape com mensagem quando não houver ofertas para uma categoria.
import React from "react";
import { useTranslation } from "react-i18next";

export default props => {
     const { t } = useTranslation()
     const nomeCategoria = props.nomeCategoria

     return (
          <div className="row">
               <div className="col mb-5">
                    <div id="offer-details">
                         <div className="container container-offer-details">
                              <div className="row content-offer-details" style={{ marginTop: "0" }}>
                                   <div className="col-12">
                                        <div className="background-box-offer-details">
                                             <div className="box-offer-details">
                                                  <p style={{color:"white"}}><strong>{t('nooffercategorytitle', {
                                                       nomeCategoria: nomeCategoria,
                                                  })}</strong></p>
                                                  <p style={{color:"white"}}>{t('nooffercattxt1', {
                                                       nomeCategoria: nomeCategoria,
                                                  })}</p>
                                             </div>
                                             <div className="row parceiro-container spacing-box-top2" style={{ marginBottom: "2rem" }}>
                                                  <div className="col-lg-3 col-sm-6">
                                                       <a href="/torox" style={{ cursor: "pointer" }}>
                                                            <div className="parceiro-itens" style={{ height: "10rem" }}>
                                                                 <img className="img-fluid" src="../src/assets/temp/torox-icon.png" alt="Torox" style={{ width: "84%" }} />
                                                            </div>
                                                       </a>
                                                  </div>
                                                  <div className="col-lg-3 col-sm-6">
                                                       <a href="/adgem" style={{ cursor: "pointer" }}>
                                                            <div className="parceiro-itens" style={{ height: "10rem" }}>
                                                                 <img className="img-fluid" src="../src/assets/temp/adgem.png" alt="Adgem" style={{ width: "84%" }} />
                                                            </div>
                                                       </a>
                                                  </div>
                                                  <div className="col-lg-3 col-sm-6">
                                                       <a href="/revu" style={{ cursor: "pointer" }}>
                                                            <div className="parceiros-itens-color" style={{ height: "10rem" }}>
                                                                 <img className="img-fluid" src="../src/assets/temp/revu.png" alt="Revu" style={{ width: "84%" }} />
                                                            </div>
                                                       </a>
                                                  </div>
                                                  <div className="col-lg-3 col-sm-6">
                                                       <a href="/notik" style={{ cursor: "pointer" }}>
                                                            <div className="parceiros-itens-color-2" style={{ height: "10rem" }}>
                                                                 <img className="img-fluid" src="../src/assets/temp/notikme.png" alt="Notik" style={{ width: "84%" }} />
                                                            </div>
                                                       </a>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     );
};
