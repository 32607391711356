import { AuthContext } from "../../contexts/AuthContext";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { getUserData } from "../../components/functions/GetUserData";
import { paisById } from "../../components/functions/GetPaisData";
import { getMoeda } from "../../components/functions/GetMoeda";
import { getExchOrderData } from "../../components/functions/GetExchOrderData";
import OrderList from "../../components/data/OrderLists";
import { useTranslation } from "react-i18next";
import SeoTags from "../common/SeoTags";

const UserExchangeHistory = props => {
     const {t} = useTranslation()
     const { usuario } = useContext(AuthContext)
     const { uid, email } = usuario
     const [showModal, setShowModal] = useState(false)

     const [username, setUserName] = useState(null)
     const [nickname, setNickname] = useState(null)
     const [dtbirth, setDtBirth] = useState(null)
     const [userData, setUserData] = useState(null)
     const [imageData, setImageData] = useState({ name: '', url: '' })
     const [avatarPath, setAvatarPath] = useState(null)

     const [idPais, setIdPais] = useState(null)
     const [nomePais, setNomePais] = useState(null)
     const [idMoeda, setIdMoeda] = useState(null)
     const [nomeMoeda, setNomeMoeda] = useState(null)
     const [isoMoeda, setIsoMoeda] = useState(null)

     const [dtIni, setDtIni] = useState("");
     const [dtFim, setDtFim] = useState("");

     const [statusAtividade, setStatusAtividade] = useState("")
     const [selectedStatus, setSelectedStatus] = useState("x")
     const [selectedStartDate, setSelectedStartDate] = useState(null)
     const [selectedEndDate, setSelectedEndDate] = useState(null)
     const [resetFilters, setResetFilters] = useState(false);

     const [ordens, setOrdens] = useState([]);

     // Restaura dados do User
     useEffect(() => {
          getUserData(uid).then((data) => {
               setUserData(data);
               if (data) {
                    setUserName(data.user_name || '');
                    setNickname(data.nickname || '');
                    setDtBirth(data.dt_birth || '');
                    setAvatarPath(data.avatar_path || '');
                    setIdPais(data.id_country || '');
               }
          });
     }, []);


     const handleResetFilters = () => {
          setSelectedStatus("x");
          setSelectedStartDate("");
          setSelectedEndDate("");
          setResetFilters(!resetFilters);
          setDtIni("");
          setDtFim("");
     };

     useEffect(() => {
          if (idPais) {
               paisById(idPais)
                    .then((pais) => {
                         setNomePais(pais.nome);
                         setIdMoeda(pais.id_moeda)
                    })
                    .catch((error) => {
                         console.error('Erro ao buscar o país:', error);
                    });
          }
     }, [idPais]);

     useEffect(() => {
          if (idMoeda) {
               getMoeda(idMoeda)
                    .then((moeda) => {
                         setNomeMoeda(moeda.nome);
                         setIsoMoeda(moeda.iso_code);
                    })
                    .catch((error) => {
                         console.error('Erro ao buscar moeda:', error);
                    });
          }
     }, [idMoeda]);

     useEffect(() => {
          getExchOrderData(uid)
               .then((resultado) => {
                    // Filtrar por status
                    const filteredOrders = resultado.filter(order => selectedStatus === "x" || order.order_status.toString() === selectedStatus);

                    // Filtrar por data
                    const filterByDate = filteredOrders.filter(order => {
                         const orderDate = new Date(order.order_time).getTime();
                         const startDate = selectedStartDate ? new Date(selectedStartDate + 'T00:00:00').getTime() : null;
                         const endDate = selectedEndDate ? new Date(selectedEndDate + 'T23:59:59').getTime() : null;

                         // Caso não haja data inicial ou data inicial esteja preenchida e não haja data final
                         if (!startDate || (startDate && !endDate)) {
                              return (!startDate || orderDate >= startDate);
                         }

                         // Caso haja data inicial e data final
                         return (orderDate >= startDate && orderDate <= endDate);
                    })

                    setOrdens(filterByDate)
                    // setOrdens(filteredOrders)
               })
               .catch((error) => {
                    console.error('Erro ao recuperar registros: ', error)
               })

     }, [selectedStatus, selectedStartDate, selectedEndDate, resetFilters])

     return (
          <Fragment>
               <SeoTags url="https://www.superbuxx.com/userexchangehistory" />
               <div className="container-fluid banner-header text-white text-center py-3">
                    <h1>{t('redhisttitleh1')}</h1>
               </div>
               <div className="container-fluid container-spacing ">
                    <div className="row align-items-end mb-4">
                         <div className="col-12 col-md-3">
                              <div className="input-field">
                                   <label htmlFor="formControlInput" className="form-label">{t('statuslabel')}</label>
                                   <select
                                        className="form-select custom-select sbx-select"
                                        name="status-atividade"
                                        aria-label="Default select example"
                                        value={statusAtividade}
                                        onChange={(e) => setSelectedStatus(e.target.value)}
                                   >
                                        <option value="x">{t('todos')}</option>
                                        <option value="0">{t('underanalys')}</option>
                                        <option value="1">{t('payd')}</option>
                                        <option value="2">{t('denied')}</option>
                                   </select>
                              </div>
                         </div>
                         
                         <div className="col-12 col-md-3">
                              <div className="input-field">
                                   <label htmlFor="formControlInput" className="form-label">{t('dtini')}</label>
                                   <input
                                        className="form-control"
                                        type="date"
                                        name="dt-ini"
                                        value={dtIni}
                                        onChange={(e) => { setSelectedStartDate(e.target.value); setDtIni(e.target.value) }}
                                   />
                              </div>
                         </div>
                         <div className="col-12 col-md-3">
                              <div className="input-field">
                                   <label htmlFor="formControlInput" className="form-label">{t('dtfim')}</label>
                                   <input
                                        className="form-control"
                                        type="date"
                                        name="dt-fim"
                                        value={dtFim}
                                        onChange={(e) => { setSelectedEndDate(e.target.value); setDtFim(e.target.value) }}
                                        disabled={!selectedStartDate}
                                   />
                              </div>
                         </div>

                         <div className="col-12 col-md-3">
                              <button type="button" className="buxx-btn w-100" style={{margin:"5px"}} onClick={handleResetFilters}>{t('clsfilters')}</button>
                         </div>
                    </div>
                    <OrderList orders={ordens} isoMoeda={isoMoeda} />
               </div>
          </Fragment>
     )
}

export default UserExchangeHistory