export async function getTicketAllData(ticket_id, uid) {
     // Função que retorna todos os dados de um ticket (cabeçalho e mensagens)
     try {
          const response = await fetch("https://www.superbuxx.com:3030/get-ticket-all-data/" + ticket_id + "/" + uid, {
               method: "GET",
          });
         
          const res = await response.json();
          return res
     } catch (error) {
          console.error(error)
          return false
     }
}