import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
     const { i18n } = useTranslation();
     const browserLanguage = (window.navigator.language).substring(0, 2)
     const [showDropdown, setShowDropdown] = useState(false);
     const [defaultLanguageSet, setDefaultLanguageSet] = useState(false);

     const storedLanguage = localStorage.getItem('selectedLanguage') || browserLanguage;

     function getLang(isoLang) {
          if (!defaultLanguageSet) {
               i18n.changeLanguage(isoLang);
               setDefaultLanguageSet(true);
          }
     }
     getLang(storedLanguage)

     const handleLanguageChange = (language) => {
          i18n.changeLanguage(language);
          setShowDropdown(false);
          localStorage.setItem('selectedLanguage', language);
     };

     const toggleDropdown = () => {
          setShowDropdown(!showDropdown);
     };

     return (
          // <div className="language-selector">
          //      <span className="active-language" onClick={() => setShowDropdown(!showDropdown)}>{i18n.language.toUpperCase()}</span>
          //      <div className="language-dropdown">
          //           <button onClick={() => handleLanguageChange('en')}>EN</button>
          //           <button onClick={() => handleLanguageChange('pt')}>PT</button>
          //      </div>
          // </div>
          <div className="language-selector">
               <span className="active-language" onClick={toggleDropdown}>
                    {i18n.language.toUpperCase()}
               </span>
               {showDropdown && (
                    <div className="language-dropdown">
                         <button onClick={() => handleLanguageChange('en')}>EN</button>
                         <button onClick={() => handleLanguageChange('pt')}>PT</button>
                    </div>
               )}
          </div>
     );
};

export default LanguageSelector;
