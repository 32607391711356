export async function getUserBlackListData(uid) {
     // Função que retorna informações da tabela users_black_list, de um user específico
     try {
          const response = await fetch("https://www.superbuxx.com:3030/get-user-permission-status/" + uid, {
               method: "GET",
          });
          const res = await response.json();
          return res
     } catch (error) {
          console.error(error)
          return false
     }
}