import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getStats } from "../../components/functions/getStats";

const Stats = () => {
     const { t } = useTranslation();
     const [totalTasks, setTotalTasks] = useState(0)
     const [totalPaid, setTotalPaid] = useState(0)
     const [totalUsers, setTotalUsers] = useState(0)

     useEffect(()=>{
          getStats().then((data) => {
               setTotalTasks(data.totalTasks)
               setTotalPaid(data.totalRewards)
               setTotalUsers(data.totalUsers)
          })
     },[])

     return (
          <div className="container spacing-box-top">
               <div className="row campanha-container">
                    <div className="col-lg-4 col-md-12 mb-4">
                         <div className="campanha-itens">
                              <p className="stats-numbers">{totalTasks}</p>
                              <p>{t('offerscompleted')}</p>
                         </div>
                    </div>
                    <div className="col-lg-4 col-md-12 mb-4">
                         <div className="campanha-itens">
                              <p className="stats-numbers">{ totalPaid } Buxx</p>
                              <p>{t('paidrewards')}</p>
                         </div>
                    </div>
                    <div className="col-lg-4 col-md-12 mb-4">
                         <div className="campanha-itens">
                              <p className="stats-numbers">{ totalUsers }</p>
                              <p>{t('users')}</p>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default Stats