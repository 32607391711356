import React from "react";
import { useTranslation } from "react-i18next";

const AboutBox = () => {
     const { t } = useTranslation()
     return (
          <div className="container spacing-box-top">
               <div className="row">
                    <div className="col-12">
                         <div className="box-text">
                              <p className="text-superbuxx-custom">{t('aboutus')}</p>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default AboutBox