import React, { Fragment } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";

const OrderList = ({ orders, isoMoeda }) => {
     const {t} = useTranslation()
     const sortedOrders = [...orders].sort((a, b) => {
          const timeA = new Date(a.order_time).getTime();
          const timeB = new Date(b.order_time).getTime();
          return timeB - timeA; // Ordena do mais recente para o mais antigo
     });
     return (
          <Fragment>
               <div className="buxx-table">
                    <table className="custom-table">
                         <thead>
                              <tr>
                                   <th>
                                        <div className="table-label">#</div>
                                   </th>
                                   <th>
                                        <div className="table-label">{t('datetime')}</div>
                                   </th>
                                   <th>
                                        <div className="table-label">{t('statuslabel')}</div>
                                   </th>
                                   <th>
                                        <div className="table-label">Buxx</div>
                                   </th>
                                   <th>
                                        <div className="table-label">{t('money')}</div>
                                   </th>
                              </tr>
                         </thead>
                         <tbody>
                              {sortedOrders.map((order) => (
                                   <tr key={order.order_id}>
                                        <td>{order.order_id}</td>
                                        <td>{moment(order.order_time).format("DD/MM/YY - HH:mm")}</td>
                                        <td>
                                             {
                                                  order.order_status === 0 ? t('underanalys') :
                                                       order.order_status === 1 ? t('payd') :
                                                            order.order_status === 2 ? t('denied') :
                                                                 order.order_status === null ? t('underanalys') : t('underanalys')
                                             }
                                        </td>
                                        <td>{order.total_buxx}</td>
                                        <td>{isoMoeda} {Number(order.total_fiat_paid).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                   </tr>
                              ))}
                         </tbody>
                    </table>
               </div>
          </Fragment>
     )
}
export default OrderList;