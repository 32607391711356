import "./logincards.css"
import React, { useContext, useState } from "react"
import GoogleLogo from "/image/icons/google01.png"
import FaceLogo from "/image/icons/face01.png"
import { getAuth } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../../contexts/AuthContext"
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail } from "firebase/auth"
import { updateMailStatus } from "../functions/UpdateMailStatus"
import { verifyUser } from "../functions/VerifyUser"
import { getUserGeoData } from "../../helpers/users"


const LoginNoUser = ({ toggleJoinComponent }) => {
     const { setUsuario } = useContext(AuthContext)
     const [email, setEmail] = useState("")
     const [senha, setSenha] = useState("")
     const [signupres, setSignupres] = useState("")
     const [userExist, setUserExist] = useState(false)
     const auth = getAuth()
     const navigate = useNavigate();

     let id_Paes = null;

     // Coleta os dados geográficos o user
     async function fetchUserGeoData() {
          try {
               const userGeoData = await getUserGeoData();
               const nomePais = userGeoData.countryName;
               const codPais = userGeoData.countryCode;
               return { nomePais, codPais };
          } catch (error) {
               console.log(error)
          }
     }

     async function getPaisData(code, name) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/get-pais/" + code + "/" + name)
               const data = await response.json()
               const id_pais = data.cData.id_pais
               return id_pais
          } catch (error) {
               console.log(error)
          }
     }

     function handleJoin() {
          toggleJoinComponent()
     }

     const handleSignIn = async (e) => {
          e.preventDefault();
          const auth = getAuth();
          await signInWithEmailAndPassword(auth, email, senha)
               .then((userCredential) => {

                    // Signed in 
                    const user = userCredential.user;
                    setUsuario(userCredential.user);
                    // setSignupres("")
                    const verificado = user.emailVerified

                    if (verificado) {
                         // chama api para atualizar banco de dados
                         updateMailStatus(user.uid)
                         navigate("/")
                    } else {
                         navigate("/verifyemail")
                    }
               })
               .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    if (errorCode === "auth/invalid-login-credentials") {
                         setSignupres('Senha incorreta. Tente novamente ou clique em "Esqueci minha senha" para escolher outra.')
                    } else {
                         setSignupres("Ocorreu um erro inesperado. Tente novamente ou fale com o suporte. Código do erro: " + errorCode)
                    }
               });
     }

     const handleGoogle = async () => {
          const auth = getAuth();
          const provider = new GoogleAuthProvider();
          signInWithPopup(auth, provider)
               .then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const token = credential.accessToken;
                    // The signed-in user info.
                    const user = result.user;
                    const uid = user.uid
                    setUsuario(user)

                    // Verificar se usuário existe na tabela
                    verifyUser(uid).then(resultado => {
                         if (!resultado) {
                              // se não encontrou uid, grava dados na tabela

                              // atribuição de país
                              async function getDados() {
                                   try {
                                        const { nomePais, codPais } = await fetchUserGeoData();
                                        try {
                                             const idPais = await getPaisData(codPais, nomePais)
                                             id_Paes = idPais;
                                        } catch (erro) {
                                             console.error("Erro ao obter dados do país em banco de dados:", erro)
                                        }
                                   } catch (error) {
                                        console.error("Erro ao obter dados geográficos do usuário:", error);
                                   }
                              }
                              (async function () {
                                   await getDados(); // Aguarde a promessa ser resolvida
                                   // This gives you a Google Access Token. You can use it to access the Google API.
                                   const credential = GoogleAuthProvider.credentialFromResult(result);
                                   const token = credential.accessToken;
                                   // The signed-in user info.
                                   const user = result.user;
                                   const email = user.email;
                                   setUsuario(user)
                                   const { uid, providerId } = user || {};
                                   const user_status = 1

                                   createUser(uid, email, providerId, user_status, id_Paes);
                              })();

                         }
                    });

                    navigate("/")
               }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    setSignupres(credential)
               });
     }

     async function createUser(uid, email, provider, user_status, id_country) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/user-create", {
                    method: "POST",
                    headers: {
                         "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ uid, email, provider, user_status, id_country }),
               });
               // aqui verifica se a api gravou os dados
               const data = await response.json();
               if (data.sucesso === true) {
                    console.log("Registro criado com sucesso.");
                    // recupera id do país e grava dados

               } else if (data.existe === "sim") {
                    console.log("Uid já existe!")
                    // não faz nada
               } else {
                    console.error("Erro ao criar o registro:", data.error);
                    // Se não criou registro
                    // 1 - grava log do erro
                    // 2 - seta mensagem, seta NAVIGATION
               }

          } catch (error) {
               // não acessou a api
               console.error("Erro ao acessar a api de gravação de dados:", error);
          }
     }

     const handleRecupera = async () => {
          // Antes de tudo, verifica se email está cadastrado
          const auth = getAuth();
          try {
               await sendPasswordResetEmail(auth, email);
               setSignupres("Verifique seu email para recuperar sua senha.");
          } catch (error) {
               console.log(error);
               const errorCode = error.code;
               const errorMessage = error.message;
               setSignupres(errorCode + ": " + errorMessage);
          }
     }

     return (
          <div className="sbx-login-card-shape-nouser">
               <p className="sbx-login-card-title">Faça Login no Super Buxx</p>

               <div className="row mb-3">
                    <div className="col-sm text-center sbx-midle">
                         <p className="sbx-card-terms">
                              Ao clicar em ENTRAR, concordo com os <a href="#" target="_blank">Termos de uso</a> e com a <a href="#" target="_blank">Política de Privacidade.</a>
                         </p>
                    </div>
               </div>

               <button className="sbx-login-card-social-button" onClick={handleGoogle}>
                    <span><img src={GoogleLogo} className="sbx-logo-size" /></span>
                    <span>Fazer login com Google</span>
               </button>

               <p className="mb-1">OU</p>

               <input
                    type="text"
                    name="email"
                    className="modal-input"
                    placeholder="EMAIL"
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={(e) => { setSignupres("") }}
               />

               <input
                    type="password"
                    name="senha"
                    className="modal-input"
                    placeholder="SENHA"
                    onChange={(e) => setSenha(e.target.value)}
                    onFocus={(e) => { setSignupres("") }}
               />

               <button className="sbx-login-card-button mb-3" onClick={handleSignIn}>ENTRAR</button>
               <div className="row sbx-input-warning-area">
                    <div className="input-msg-erro">{signupres}</div>
               </div>

               <div className="sbx-tright"><small><p style={{ textDecoration:'none', cursor:'pointer' }} href="#" onClick={handleRecupera}>Esqueci minha senha</p></small></div>

               <div className="mt-2 text-center">
                    <p style={{ margin: '0' }}>
                         Não é membro? <span onClick={handleJoin} style={{ cursor: 'pointer' }}><strong>Cadastre-se agora!</strong></span>
                    </p>
               </div>

          </div>
     )
}
export default LoginNoUser