// Função que regista a atividade do user, disparada em momentos específicos
// Recebe o parâmetro (click_desc): click description, que irá descrever a atividade e uid
// Deve ser definida a string no momento em que for chamada a função

export async function recLastActv(uid, click_desc) {
     try {
          // Obtém a data atual
          const dataAtual = new Date();

          // Ajusta a data para o fuso horário GMT-3 (America/Sao_Paulo)
          const options = {
               timeZone: 'America/Sao_Paulo',
               year: 'numeric',
               month: '2-digit',
               day: '2-digit',
               hour: '2-digit',
               minute: '2-digit',
               second: '2-digit',
               hour12: false, // Formato 24 horas
          };

          // Formata a data/hora de acordo com as opções definidas
          const dataHoraGMT3 = dataAtual.toLocaleString('pt-BR', options);

          // Converte para o formato MySQL DATETIME
          const [data, hora] = dataHoraGMT3.split(', ');
          const dataFormatada = `${data.split('/').reverse().join('-')} ${hora}`;

          // Converte a data formatada para UTC
          const utcData = new Date(dataFormatada + ' GMT-0300'); // Adiciona o fuso horário
          const utcDataFormatada = utcData.toISOString().slice(0, 19).replace('T', ' '); // Formato YYYY-MM-DD HH:MM:SS

          const desc = click_desc.slice(0, 90);
          let userAgent = navigator.userAgent;
          userAgent = userAgent.substring(0,255);

          const response = await fetch('https://www.superbuxx.com:3030/rec-last-actv', {
               method: 'POST',
               headers: {
                    'Content-Type': 'application/json'
               },
               body: JSON.stringify({
                    uid: uid,
                    click_desc: desc,
                    user_agent: userAgent,
                    time: utcDataFormatada
               })
          });
          return response
     } catch (erro) {
          // gravar em log
          console.log("erro: ", erro)
     }
}
