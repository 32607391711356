import React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const Terms = () => {
     const { t } = useTranslation();

     return (
          <Fragment>
               <div className="container-fluid banner-header text-white text-center py-3">
                    <h1>{t('footertermos')}</h1>

               </div>

               <div className="container container-termos">
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle1')}</h2>
                         <p className="text-termos">{t('termsparag01')}</p>
                         <p className="text-termos">{t('termsparag02')}</p>
                         <p className="text-termos">{t('termsparag03')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle2')}</h2>
                         <p className="text-termos">{t('termsparag04')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle3')}</h2>
                         <p className="text-termos">{t('termsparag05')}</p>
                         <p className="text-termos">{t('termsparag06')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle4')}</h2>
                         <p className="text-termos">{t('termsparag07')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle5')}</h2>
                         <p className="text-termos">{t('termsparag08')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle6')}</h2>
                         <p className="text-termos">{t('termsparag09')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle7')}</h2>
                         <p className="text-termos">{t('termsparag10')}</p>
                         <p className="text-termos">
                         <ul>
                              <li>{t('termsparag11')}</li>
                              <li>{t('termsparag12')}</li>
                              <li>{t('termsparag13')}</li>
                              <li>{t('termsparag14')}</li>
                              <li>{t('termsparag15')}</li>
                              <li>{t('termsparag16')}</li>
                              <li>{t('termsparag17')}</li>
                              
                         </ul>
                         </p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle8')}</h2>
                         <p className="text-termos">{t('termsparag18')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle9')}</h2>
                         <p className="text-termos">{t('termsparag19')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle10')}</h2>
                         <p className="text-termos">{t('termsparag20')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle11')}</h2>
                         <p className="text-termos">{t('termsparag21')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle12')}</h2>
                         <p className="text-termos">{t('termsparag22')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle13')}</h2>
                         <p className="text-termos">{t('termsparag23')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle14')}</h2>
                         <p className="text-termos">{t('termsparag24')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle15')}</h2>
                         <p className="text-termos">{t('termsparag25')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle16')}</h2>
                         <p className="text-termos">{t('termsparag26')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle17')}</h2>
                         <p className="text-termos">{t('termsparag27')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle18')}</h2>
                         <p className="text-termos">{t('termsparag28')}</p>
                    </div>
                    <div className="row">
                         <h2 className="title-termos">{t('termstitle19')}</h2>
                         <p className="text-termos">{t('termsparag29')} <a href="mailto:contact@superbuxx.com"><strong>contact@superbuxx.com</strong></a></p>
                    </div>
               </div>
          </Fragment>
     )
}

export default Terms