import React, { useEffect, useState, useContext } from "react";
import { getTimeStamp } from "../helpers/converters";

const formatada = getTimeStamp("America/Sao_Paulo")


const About = ()=> {
     return (
          <div>
               <h1>Sobre</h1>
               <p>Aqui entra o conteúdo...</p>
               <p>{formatada}</p>

          </div>
     )
}

export default About