import React, { Fragment, useEffect, useState, useRef } from "react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotificationList = ({ mensagens }) => {
     const {t} = useTranslation()
     mensagens.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
     const [modalShow, setModalShow] = useState(false);
     const [selectedMsg, setSelectedMsg] = useState(null);
     const [reloadPage, setReloadPage] = useState(false);

     const navigate = useNavigate()

     const lixeira = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
          <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
     </svg>

     const envelope = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z" />
     </svg>


     // Atualiza notifications (com o campo especificado no parâmetro)
     // Se é is_read, atualiza para true. Se é Status, atualiza para false (apagado)
     async function gravaDados(id, campo) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/notification-update", {
                    method: "PUT",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ id, campo }),
               });
          } catch (error) {
               console.error(error)
               // Grava dados em log
          }
     }

     const handleClick = (note) => {
          setSelectedMsg(note);
          setModalShow(true);
          gravaDados(note.id, "is_read")
     };

     const handleCloseModal = () => {
          setModalShow(false);
          setReloadPage(true); // Configura o estado para recarregar a página quando o modal for fechado
     };

     const handleDelete = (note) => {
          gravaDados(note.id, "status")
          setModalShow(false);
          setReloadPage(true);
          navigate("/notifications")
     }

     useEffect(() => {
          if (reloadPage) {
               window.location.reload();
          }
     }, [reloadPage]);

     return (
          <Fragment>
               <div id="notifications">
                    <div className="container-fluid container-spacing">
                         <div className="buxx-table">
                              <table className="custom-table">
                                   <thead>
                                        <tr>
                                             <th>
                                                  <div className="table-label">{t('date')}</div>
                                             </th>
                                             <th>
                                                  <div className="table-label">{t('subject')}</div>
                                             </th>
                                        </tr>
                                   </thead>

                                   <tbody>
                                        {mensagens && mensagens.length > 0 ? (
                                             mensagens.map((note) => {
                                                  let bulletColor
                                                  if (note.is_read == true) {
                                                       bulletColor = 'darkgray'

                                                  } else {
                                                       bulletColor = 'green'
                                                  }
                                                  return (
                                                       <tr key={note.id} style={{ cursor: 'pointer' }}>
                                                            <td onClick={(e) => handleClick(note)}><span style={{ color: bulletColor, fontSize: "0.9rem", marginRight: "1rem" }}>●</span><span>{format(new Date(note.created_at), 'dd-MM-yy | HH:mm')}</span></td>
                                                            <td onClick={(e) => handleClick(note)}>{note.subject}</td>
                                                       </tr>
                                                  )
                                             })
                                        ) : (
                                             <tr>
                                                  <td colSpan="3">{t('nonotifications')}</td>
                                             </tr>
                                        )}
                                   </tbody>
                              </table>
                         </div>
                    </div>
               </div>
               {modalShow && selectedMsg && (
                    <div
                         className="modal fade show"
                         id="notificationModal"
                         tabIndex="-1"
                         aria-labelledby="notificationModalLabel"
                         aria-hidden="true"
                         style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    >
                         <div className="modal-dialog modal-lg">
                              <div className="modal-content" style={{backgroundColor:"#333"}}>
                                   <div className="modal-header">
                                        <h5 className="modal-title" id="notificationModalLabel">
                                             {selectedMsg.subject}
                                        </h5>
                                        <button type="button" className="btn-close" aria-label="Close" onClick={handleCloseModal}></button>
                                   </div>
                                   <div className="modal-body">
                                        <div dangerouslySetInnerHTML={{ __html: selectedMsg.body }} />
                                   </div>
                                   <div className="modal-footer">
                                        <button type="button" className="btn btn-light" onClick={handleCloseModal}>
                                             {t('fechatbt')}
                                        </button>
                                        <button type="button" className="btn btn-light" onClick={() => handleDelete(selectedMsg)}>
                                             {lixeira} {t('excluirbt')}
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </div>
               )}
          </Fragment>
     )
}
export default NotificationList;