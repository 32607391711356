import React from "react";
import { useContext, useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { getOpenTicketData } from "../../components/functions/GetOpenTicketData";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import SeoTags from "../common/SeoTags";

const Ticket = () => {
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario
     const { offer_id } = useParams()
     const [offerName, setOfferName] = useState("")
     const [offerData, setOfferData] = useState(null)
     const [wordsLeft, setWordsLeft] = useState(0)
     const [imagens, setImagens] = useState([])
     const [message, setMessage] = useState("")
     const [selectedEvent, setSelectedEvent] = useState('')
     const [msgErro, setMsgErro] = useState("")
     const navigate = useNavigate()


     // Verifica de offer_id está na tabela de cliques. Se não estiver exibe mensagem de erro. Se estiver, exibe form.
     useEffect(() => {
          getOpenTicketData(uid, offer_id)
               .then((resultado) => {
                    if (resultado.success == false) {
                         setMsgErro(resultado.message)
                         setOfferData(false)
                    } else {
                         setOfferData(resultado)
                         setOfferName(resultado[0].offer_name)
                    }
               })
               .catch((error) => {
                    console.log("erro: ", error)
               })

     }, [])

     useEffect(() => {
          if (offerData === false) {
               localStorage.setItem("sbxTicketErrorMsg", msgErro)
               navigate("/ticketerror")
          }
     }, [offerData])



     useEffect(() => {
          // Calcula quantas letras faltam no campo de mensagens
          setWordsLeft(400 - message.length)
     }, [message])

     const handleImageChange = (e) => {
          if (e.target.files && e.target.files[0]) {
               const file = e.target.files[0]
               const fileSize = file.size / 1024
               if (fileSize > 600) {
                    alert('O arquivo é muito grande. Por favor, envie um arquivo de até 600k.')
               } else if (imagens.length < 5) {
                    setImagens(imagens => [...imagens, e.target.files[0]]);
               } else {
                    alert('Você pode enviar no máximo 5 imagens');
               }
          }
     }

     const handleDelete = (index) => {
          setImagens(imagens => imagens.filter((_, i) => i !== index));
     }

     useEffect(() => {
          if (offerData && offerData[0].events_list.length > 0) {
               setSelectedEvent(offerData[0].events_list[0].event_id);
          }
     }, [offerData]);

     const handleChange = (event) => {
          setSelectedEvent(event.target.value);
     };

     async function enviaDados() {
          if (message !== "") {
               try {
                    const formData = new FormData();
                    formData.append('uid', uid)
                    formData.append('offerId', offer_id)
                    formData.append('eventsList', offerData[0].events_list)
                    formData.append('msg', message)
                    formData.append('event_id', selectedEvent)

                    for (let i = 0; i < imagens.length; i++) {
                         const imagem = imagens[i];
                         if (imagem) {
                              const response = await fetch(URL.createObjectURL(imagem));
                              const blob = await response.blob();
                              formData.append('imagem' + i, blob, 'imagem' + i + '.jpg');
                         }
                    }

                    // Envia o FormData para o servidor
                    const response = await fetch("https://www.superbuxx.com:3030/send-ticket", {
                         method: "POST",
                         body: formData,
                    });

                    if (!response.ok) {
                         throw new Error(`HTTP error! status: ${response.status}`);
                    } else {
                         navigate('/tickets')
                    }

               } catch (error) {
                    console.error(error)
                    // Grava dados em log
               }
          } else {
               toast.warning("O campo 'Mensagem' não pode estar vazio", { theme: "dark" });
          }
     }

     return (
          <Fragment>
               <SeoTags url="https://www.superbuxx.com/tickets" />
               <div id="new-ticket" class="container">
                    <div class="row">
                         <div class="col-12">
                              <div class="title-ticket">
                                   <h1>Novo ticket</h1>
                              </div>

                              <div class="box-paragraph-title">
                                   <h2>Termos de Elegibilidade</h2>
                                   <p>
                                        Atenção, se você completou uma oferta e não recebeu sua recompensa, por favor, tome
                                        nota das seguintes informações. Para
                                        avaliar seu caso da melhor maneira possível, precisamos que as seguintes informações
                                        sejam enviadas para nós. Se alguma
                                        dessas informações estiver faltando, não será possível concluir a
                                        análise:<br /><br />
                                        • Comprovação de ser um novo usuário e não ter instalado o app anteriormente. E-mail
                                        de boas-vindas junto com a data
                                        (caso exista).<br /><br />
                                        • Comprovante do primeiro depósito junto com a data, se a campanha requerer
                                        depósito.<br /><br />
                                        • Prova de que o valor depositado também foi utilizado conforme solicitado na
                                        descrição da campanha.<br /><br />
                                        Sem essas informações, podemos não conseguir analisar seu caso.
                                   </p>
                              </div>
                         </div>


                         <div className="col-md-6">
                              <label>CAMPANHA</label>
                              <div className="sbx-title-container">
                                   <div className="sbx-title-container-box">
                                        <h5>{offerName}</h5>
                                   </div>

                              </div>

                              <div className="form-group input-select-card">
                                   <label>EVENTO</label>
                                   <select
                                        className="form-select custom-select"
                                        name="event_id"
                                        onChange={handleChange}
                                   >
                                        {offerData && offerData[0].events_list.map((evento) => (
                                             <option key={evento.event_id} value={evento.event_id}>
                                                  {evento.event_name}
                                             </option>
                                        ))}
                                   </select>
                              </div>

                              <label>MENSAGEM</label>
                              <div class="message-box">
                                   <div class="textarea-container">
                                        <textarea
                                             id="message"
                                             placeholder="Digite sua mensagem..."
                                             name="msg"
                                             maxLength="400"
                                             required
                                             onChange={(e) => setMessage(e.target.value)}
                                        />

                                        <small>{wordsLeft}/400</small>
                                   </div>
                              </div>
                              <div class="container-upload-box">
                                   <div class="upload-box">
                                        <div class="content-upload-box">
                                             <h4>CARREGUE AQUI SUAS IMAGENS A SEREM ENVIADAS.</h4>
                                             <input
                                                  type="file"
                                                  name="img"
                                                  id="img"
                                                  accept=".jpg,.jpeg,.png,.webp"
                                                  onChange={handleImageChange}
                                                  style={{ display: 'none' }}
                                             />
                                             <label htmlFor="img" className="mt-3 ms-0">
                                                  <span className="btn btn-to-check mb-0">CARREGUE IMAGENS</span>
                                             </label>

                                             <p><small>Arquivos apenas em JPEG, JPG ou WebP e menores que 600k.</small></p>
                                        </div>
                                   </div>
                              </div>
                              <button type="button" onClick={() => enviaDados()} class="btn-submit">ENVIAR</button>
                         </div>
                         <div class="col-md-6">
                              <div class="images-container">
                                   <label>IMAGENS</label>
                                   <div class="images-box">
                                        <div class="row">
                                             {imagens.map((imagem, index) => (
                                                  <div class="col-6 content-imagens-box spacing-bottom-images" key={index}>
                                                       <div class="image-wrapper">
                                                            <img src={URL.createObjectURL(imagem)} alt={`Imagem ${index + 1}`} />
                                                            <span class="close-icon">
                                                                 <img src="../src/assets/icons/x-img-profile.svg" alt="" onClick={() => handleDelete(index)} />
                                                            </span>
                                                       </div>
                                                  </div>
                                             ))}
                                        </div>
                                   </div>
                              </div>
                         </div>


                    </div>
               </div>

          </Fragment>






          // <Container fluid>
          //      <Row className="container-loggedin">
          //           <h1 className="sbx-page-title">Abertura de Ticket</h1>
          //           <Row>
          //                <p>Campanha: <strong>{offerName}</strong> (<em>#{offer_id}</em>)</p>
          //                <hr />
          //           </Row>
          //           <Col sm={4}>
          //                <div className="form-group">
          //                     <div className="form-label sbx-form-label">Evento</div>
          //                     <select
          //                          name="event_id"
          //                          className="form-select sbx-standart-input"
          //                          aria-label="Default select example"
          //                          onChange={handleChange}
          //                     >
          //                          {offerData && offerData[0].events_list.map((evento) => (
          //                               <option key={evento.event_id} value={evento.event_id}>
          //                                    {evento.event_name}
          //                               </option>
          //                          ))}
          //                     </select>
          //                </div>

          //                <div className="form-group">
          //                     <div className="form-label sbx-form-label">Mensagem</div>
          //                     <textarea
          //                          className="sbx-textarea-input"
          //                          type="textarea"
          //                          name="msg"
          //                          maxLength="400"
          //                          rows="9"
          //                          required
          //                          onChange={(e) => setMessage(e.target.value)}
          //                     />
          //                     <small>({wordsLeft}/400)</small>
          //                </div>

          //                <div className="form-group mb-1">
          //                     <div className="form-label sbx-form-label">Envie provas</div>
          //                     <input
          //                          className="form-control sbx-group-input"
          //                          type="file"
          //                          name="img"
          //                          id="img"
          //                          accept=".jpg,.png,.webp"
          //                          onChange={handleImageChange}
          //                     />
          //                     <label className="sbx-label-file mb-2" htmlFor="img">UPLOAD</label>
          //                     <small>.jpg, .png, .webp | Max. 600k</small>
          //                </div>
          //           </Col>
          //           <Col style={{ paddingLeft: '3rem' }}>
          //                <strong>Provas</strong>
          //                <hr />
          //                <Row>
          //                     {imagens.map((imagem, index) => (
          //                          <Col sm={3} md={3} key={index} className="ticket-img-thumb mb-3">
          //                               <div className="thumb">
          //                                    <img src={URL.createObjectURL(imagem)} alt={`Imagem ${index + 1}`} style={{ width: '100%', maxHeight: '100%' }} />
          //                                    <div className="thumb-footer">
          //                                         <small onClick={() => handleDelete(index)} className="cursor-pointer-on"><strong>Excluir</strong></small>
          //                                    </div>
          //                               </div>
          //                          </Col>
          //                     ))}
          //                </Row>
          //           </Col>
          //           <hr />
          //           <Row>
          //                <Col sm={2}>
          //                     <button type="button" onClick={() => enviaDados()} className="sbx-label-file w-100">GRAVAR</button>
          //                </Col>
          //           </Row>
          //      </Row>
          // </Container>
     )

}

export default Ticket