import React from "react";
import Truncate from "./Truncate";

const DiveCashback = () => {
     const text01 = (
          <>
          <h5 className="card-subtitle">O que é cashback e como funciona?</h5>
                         <p className="card-text">O cashback é uma maneira incrível de economizar dinheiro em suas compras online. Mas afinal, cashback como funciona? Basicamente, quando você faz uma compra através de um site ou aplicativo parceiro, uma porcentagem do valor gasto é devolvida para você. Esse valor pode ser usado para futuras compras ou retirado em dinheiro.</p>
                         <p className="card-text">Por exemplo, ao utilizar um cashback app como o Superbuxx, você pode acumular cashback dinheiro de volta em cada compra realizada. É uma forma simples e eficaz de economizar e obter mais valor em suas compras diárias. Além disso, o cashback é uma forma justa e transparente de recompensar os consumidores por suas compras, tornando-se uma escolha popular entre os compradores inteligentes.</p>
          </>
     )
     const text02 = (
          <>
          <h5 className="card-subtitle">Cashback em Cartões de Crédito: Vantagens e Como Usar.</h5>
                         <p className="card-text">Os cashback cartão de crédito estão se tornando cada vez mais populares entre os consumidores. Com eles, você pode obter cashback de cartão de crédito em cada compra realizada, seja em lojas físicas ou online. Isso significa que uma parte do dinheiro que você gasta volta para você, aumentando suas economias.</p>
                         <p className="card-text">
                         Além disso, algumas plataformas, como o cashback mercado pago, oferecem opções ainda mais vantajosas para quem utiliza seus serviços de pagamento. Utilizando esses cartões de crédito em combinação com o Superbuxx, você maximiza suas recompensas e faz seu dinheiro render mais. Explorar essas opções pode ser uma excelente maneira de economizar e obter benefícios adicionais em suas compras.</p>
          </>
     )
     const text03 = (
          <>
          <h5 className="card-subtitle">Cashback em Diversas Categorias: Como Aproveitar ao Máximo</h5>
          <p className="card-text">O cashback não se limita apenas a compras regulares. Você pode obter cashback ao alugar ou comprar filmes online, assim como em cashback eletrodomesticos ao adquirir produtos eletrônicos e de uso doméstico. Além disso, muitos serviços oferecem cashback gift card, permitindo que você compre cartões presente e ainda receba uma parte do valor de volta.</p>
          </>
     )
     return (
          <div className="container spacing-box-top" id="mergulhe">
               <div className="row">
                    <div className="col-12">
                         <div className="text-center card-title ">
                              <h2 class="block-title">Mergulhe mais fundo com Super Buxx</h2>
                         </div>
                    </div>
               </div>
               <div className="row">
                    <div className="col-12 col-lg-4 mb-5">
                         <Truncate text={text01} maxLength={150} />
                    </div>

                    <div className="col-12 col-lg-4 mb-5">
                         <Truncate text={text02} maxLength={150} />
                    </div>

                    <div className="col-12 col-lg-4 mb-5">
                         <Truncate text={text03} maxLength={150} />
                    </div>
               </div>
          </div>
     )
}

export default DiveCashback