import React, { useEffect, useState, Fragment } from "react";
import { getAuth, sendEmailVerification } from "firebase/auth";
import { useTranslation } from "react-i18next";


const VerifyEmailMsg = props => {
     const {t} = useTranslation()
     const [enviar, setEnviar] = useState(false)

     useEffect(() => {
          if (enviar === true) {
               async function sendMailVerify() {
                    const auth = getAuth();
                    sendEmailVerification(auth.currentUser)
                         .then(() => {
                              toast.success(t('seemailbox'), { theme: "dark" });
                         });
               }
               sendMailVerify()
          }
     }, [enviar])



     return (
          <Fragment>

               <div id="offer-details">
                    <div class="container container-offer-details">
                         <div class="row content-offer-details">
                              <div class="col-12">
                                   <div class="title-offer-details">
                                        <h1>{t('mailverifytitleh1')}</h1>
                                   </div>
                                   <div class="background-box-offer-details">
                                        <div class="box-offer-details">
                                             <p><strong>{t('dearuser')}</strong></p>
                                             <p>{t('vermailp1')}</p>
                                             <p>{t('vermailp2')}</p>
                                             <p>{t('vermailp3')} <span style={{ cursor: 'pointer' }} onClick={() => { setEnviar(true) }}><strong>{t('clickhere')}</strong></span> {t('vermailp4')}</p>
                                             <p className="mb-3"><strong>{t('vermailp5')}<br />{t('vermailp6')}.</strong></p>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>



          </Fragment>

          // <Container fluid>
          //      <Row className="container-loggedin">
          //           <img src={losangoCinza} className="losango-top-cinza" />
          //           <h1 className="sbx-page-title">Verifique seu email</h1>
          //           <Col sm="auto" className="mb-3 userinfobox-col">
          //                <UserInfoBox />
          //           </Col>
          //           <Col sm className="col-msg-verif-mail">
          //                <div className="mb-5 msg-verif-mail">
          //                     <p><strong>Prezado usuário,</strong></p>
          //                     <p>Enviamos um link para verificar seu e-mail. Dê uma olhadinha na sua caixa de email (inclusive o spam).</p>
          //                     <p>Você precisa verificar seu e-mail junto à SuperBuxx para poder participar de nossas campanhas e aproveitar todos os benefícios da SuperBuxx.</p>
          //                     <p>Se já passou um tempinho e você não recebeu o email com o link de verificação, <span style={{ cursor: 'pointer' }} onClick={() => { setEnviar(true) }}><strong>clique aqui</strong></span> para que possamos reenviar.</p>
          //                     <p className="mb-3"><strong>Boa sorte!<br />Equipe SuperBuxx.</strong></p>

          //                     <div className="sbx-bt-awesome" onClick={() => { setEnviar(true) }}>Verificar e-mail</div>

          //                </div>
          //           </Col>

          //      </Row>
          // </Container >
     )
}

export default VerifyEmailMsg