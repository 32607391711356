export async function updateMailStatus(uid) {
     try {
          const response = await fetch("https://www.superbuxx.com:3030/user-update-mail-status", {
               method: "PUT",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({ uid }),
          });

          if (response.ok) {
               const data = await response.json();
          } else {
               console.error(`Erro ao acessar a API: ${response.status}`);
               // grava log de erro
          }
     } catch (error) {
          // não acessou a api
          // grava log de erro
          console.error("Erro ao acessar a api de gravação de dados:", error);
     }
};