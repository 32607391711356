import React, { useEffect, useState } from "react";
import { getSwaarm } from "../../components/functions/GetSwaarm";
import { Container, Row } from "react-bootstrap";
import OfferCard from "../../components/misc/OfferCard";
import { getDevice } from "../../components/functions/GetDevice";
import { AuthContext } from "../../contexts/AuthContext";
import { useContext } from "react";

const Debugger = () => {
     const { idIdioma } = useContext(AuthContext)
     const language = window.navigator.language;
     const [swaarmOffers, setSwaarmOffers] = useState(null);
     const [currentPage, setCurrentPage] = useState(0);
     const itemsPerPage = 30;
     let device = getDevice();
     const idCategoria = 8;


     useEffect(() => {
          getSwaarm(idCategoria, idIdioma, device)
               .then((swaarmData) => {
                    setSwaarmOffers(swaarmData);
               })
               .catch((error) => {
                    console.error("Erro ao buscar dados do Swaarm:", error);
               });
     }, [idIdioma]);

     const totalPages = Math.ceil(
          (swaarmOffers?.adsTrans.length || 0) / itemsPerPage
     );

     const handlePageChange = (page) => {
          setCurrentPage(page);
     };


     return (
          <Container fluid>
               <Row className="campanha-container-gray">
                    <h1 className="sbx-page-title">Debugger!</h1>


                    <p><strong>Navigator Language: </strong>{language}</p>
                    <p><strong>Language Id: </strong>{idIdioma}</p>


                    {swaarmOffers === null ? (
                         <div className="spinner-border m-5" role="status">
                              <span className="visually-hidden">Loading...</span>
                         </div>
                    ) : (
                         <>
                              {swaarmOffers.adsTrans
                                   .slice(
                                        currentPage * itemsPerPage,
                                        currentPage * itemsPerPage + itemsPerPage
                                   )
                                   .map((ad, index) => (
                                        <div key={index} className="col-lg-2 col-md-4 col-sm-6 col-12 mb-3">
                                             <OfferCard
                                                  cor="w"
                                                  oName={ad.offer_name}
                                                  creative={ad.creative}
                                                  info={ad.short_desc}
                                                  payout={ad.payout}
                                                  offer_id={ad.offer_id}
                                                  os={ad.os}
                                             />
                                        </div>
                                   ))}
                         </>
                    )}

                    {totalPages > 1 && (
                         <Row className="mt-3">
                              <div className="dot-container">
                                   <nav aria-label="Page navigation">
                                        <ul className="pagination justify-content-center">
                                             {[...Array(totalPages)].map((_, index) => (
                                                  <li
                                                       key={index}
                                                       className={`page-item ${index === currentPage ? "active" : ""}`}
                                                  >
                                                       <button
                                                            className="page-link"
                                                            onClick={() => handlePageChange(index)}
                                                       >
                                                            <span className="visually-hidden">{`Page ${index + 1}`}</span>
                                                       </button>
                                                  </li>
                                             ))}
                                        </ul>
                                   </nav>
                              </div>
                         </Row>
                    )}


               </Row>


          </Container>
     );
};

export default Debugger;
