export async function addOfferImpression(offer_id, pais_id) {
     try {
          const response = await fetch('https://www.superbuxx.com:3030/add-offer-imp', {
               method: 'POST',
               headers: {
                    'Content-Type': 'application/json'
               },
               body: JSON.stringify({
                    offer_id: offer_id,
                    pais_id: pais_id
               })
          });
          return response
     } catch (erro) {
          // gravar em log
          console.log("erro: ", erro)
     }  
}