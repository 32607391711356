// Funções de apoio relativas aos dados dos usuários

// FUNÇÃO QUE RETORNA O IP DO USUÁRIO
export async function getIpAddr() {
     const url = 'https://api64.ipify.org?format=json'
     try {
          const response = await fetch(url);
          const data = await response.json();
          return data;
     } catch (error) {
          return null;
     }
}

// FUNÇÃO QUE RETORNA DADOS GEOGRÁFICOS A PARTIR DO IP DO USUÁRIO
export async function getUserGeoData() {
     try {
          const getIp = await getIpAddr();
          const ip = getIp.ip
          const accessKey = "673fd7eb-6696-42fb-9471-13e8c6603a16";
          const url = `https://apiip.net/api/check?ip=${ip}&accessKey=${accessKey}`;

          // Faça a requisição e armazene a resposta
          const response = await fetch(url);

          // Decodifique a resposta JSON:
          const result = await response.json();
          return result;
     } catch (error) {
          console.error("Erro ao obter dados geográficos:", error);
          // Trate o erro conforme necessário
     }
}

// FUNÇÃO QUE RETORNA DADOS GEOGRÁFICOS A PARTIR DO IP DO USUÁRIO, AO CONTRÁRIO DA FUNÇÃO ANTERIOR, ELA RECEBE O IP POR PARÂMETRO.
// Isso foi feito para evitar acionar a api 'apiip' caso não tenha sido informado um ip corretamente. Isso diminui o uso da api,
// que é paga.
export async function getIpGeoData(ip) {
     try {
          const accessKey = "673fd7eb-6696-42fb-9471-13e8c6603a16";
          const url = `https://apiip.net/api/check?ip=${ip}&accessKey=${accessKey}`;

          // Faça a requisição e armazene a resposta
          const response = await fetch(url);

          // Decodifique a resposta JSON:
          const result = await response.json();
          return result;
     } catch (error) {
          console.error("Erro ao obter dados geográficos:", error);
          // Trate o erro conforme necessário
     }
}