import React, { useEffect, useState, useRef } from "react";
import { getSwaarm } from "../../components/functions/GetSwaarm";
import OfferCard from "../../components/misc/OfferCard";
import { getDevice } from "../../components/functions/GetDevice";
import { AuthContext } from "../../contexts/AuthContext";
import { useContext } from "react";
import { Fragment } from "react";
import Parceiros from "../common/Parceiros";
import HowCashback from "../common/HowCashback";
import Stats from "../common/Stats";
import DiveCashback from "../common/DiveCashback";
import PageNavigator from "../../components/pagination/PageNavigator";
import SbxSpinner from "../common/SbxSpinner";
import SeoTags from "../common/SeoTags";
import FaqCashback from "../common/FaqCashback";
import AboutCashback from "../common/AboutCashback";

const Cashback = () => {
     const { idIdioma } = useContext(AuthContext)
     const { isoPais } = useContext(AuthContext)
     const [swaarmOffers, setSwaarmOffers] = useState(null)
     const [currentPage, setCurrentPage] = useState(0)
     const itemsPerPage = 8
     let device = getDevice()
     const idCategoria = 8
     const listRef = useRef(null)

     // TAGS SEO
     const title = "Superbuxx: Para Ganhar Dinheiro de Volta em Suas Compras Online"
     const description = "Descubra Superbuxx, o site de cashback para economizar e ganhar dinheiro extra. Transforme suas compras online em oportunidades de poupança!"

     useEffect(() => {
          if (idIdioma && isoPais) {
               getSwaarm(idCategoria, idIdioma, device, isoPais)
                    .then((swaarmData) => {
                         setSwaarmOffers(swaarmData);
                    })
                    .catch((error) => {
                         console.error("Erro ao buscar dados do Swaarm:", error);
                    });
          }
     }, [idIdioma, isoPais]);

     const totalPages = Math.ceil(
          (swaarmOffers?.adsTrans.length || 0) / itemsPerPage
     );

     const handlePageChange = (page) => {
          setCurrentPage(page);
          if (listRef.current) {
               listRef.current.scrollIntoView({ behavior: 'smooth' });
          }
     };

     const handleNextClick = () => {
          if (currentPage + 1 < totalPages) {
               setCurrentPage(currentPage + 1);
          }
          if (listRef.current) {
               listRef.current.scrollIntoView({ behavior: 'smooth' });
          }
     };

     const handlePreviousClick = () => {
          if (currentPage > 0) {
               setCurrentPage(currentPage - 1);
          }
          if (listRef.current) {
               listRef.current.scrollIntoView({ behavior: 'smooth' });
          }
     };

     return (
          <Fragment>
               <SeoTags
                    title = {title}
                    description = {description}
               />
               <div className="container-fluid banner-header text-white text-center py-3">
                    <h1>Cashback em suas compras online</h1>
                    <div className="paragrafo-homepage-log-out">
                         <p>Descubra Superbuxx, o site de cashback para economizar e ganhar dinheiro extra. Transforme suas compras online em oportunidades de poupança!</p>
                    </div>
               </div>

               <div className="container block-bottom-spacing" ref={listRef}>
                    <div className="row justify-content-center">
                         <div className="col-12">
                              <div className="block-header">
                                   <h1 className="block-title"></h1>

                              </div>
                         </div>
                    </div>
                    <div className="row">
                         {swaarmOffers === null ? (
                             <SbxSpinner />
                         ) : (
                              <>
                                   {swaarmOffers.adsTrans
                                        .slice(
                                             currentPage * itemsPerPage,
                                             currentPage * itemsPerPage + itemsPerPage
                                        )
                                        .map((ad, index) => (

                                             <div className="col-6 mb-4" key={index}>
                                                  <OfferCard
                                                       cor="w"
                                                       oName={ad.offer_name}
                                                       creative={ad.creative}
                                                       info={ad.short_desc}
                                                       payout={ad.payout}
                                                       offer_id={ad.offer_id}
                                                       os={ad.os}
                                                  />
                                             </div>
                                        ))}
                              </>
                         )}
                    </div>

                    <PageNavigator
                         totalPages={totalPages}
                         currentPage={currentPage}
                         handlePreviousClick={handlePreviousClick}
                         handleNextClick={handleNextClick}
                         handlePageChange={handlePageChange}
                    />

                    <div className="container">
                         <div className="row parceiro-container spacing-box-top">
                              <div className="title-parceiros">
                                   <h2>Cashback, recompensas extras e muitas novidades! Teste as walls dos nossos parceiros:</h2>
                              </div>
                              <Parceiros />
                         </div>
                    </div>
                    <HowCashback />
                    <Stats />
                    <AboutCashback />
                    <FaqCashback />
                    <DiveCashback />

               </div>
          </Fragment >
     );
};

export default Cashback;
