import { getIpAddr } from "../../helpers/users";

export async function addUserIpTracking(uid) {
     // Função que grava ip do user no momento do signup
     try {
          const userIp = await getIpAddr()
          console.log('ip:', userIp)
          
          const response = await fetch('https://www.superbuxx.com:3030/add-user-su-ip-track', {
               method: 'POST',
               headers: {
                    'Content-Type': 'application/json'
               },
               body: JSON.stringify({
                    uid: uid,
                    suIp: userIp.ip,
                    suErr: ""
               })
          });
     } catch (erro) {
          // gravar em log
          console.log("erro: ", erro)
     }  
}

export async function UpdateUserLoginIp(uid) {
     // Função que grava ip do user no momento do último login
     try {
          const userIp = await getIpAddr()          
          const response = await fetch('https://www.superbuxx.com:3030/add-user-login-ip-track', {
               method: 'POST',
               headers: {
                    'Content-Type': 'application/json'
               },
               body: JSON.stringify({
                    uid: uid,
                    uIp: userIp.ip,
               })
          });
     } catch (erro) {
          // gravar em log
          console.log("erro: ", erro)
     }  

}