import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import LoginNoUser from "../../components/logincards/LoginNoUser";
import JMainNoUser from "../../components/logincards/JMainNoUser";
import JoinEmailNoUser from "../../components/logincards/JoinEmailNoUser";

const NoUser = () => {
     const [showJoinComponent, setShowJoinComponent] = useState(true)
     const [showJoinMain, setShowJoinMain] = useState(true)

     const toggleJoinComponent = () => {
          setShowJoinComponent(!showJoinComponent);
     }

     const toggleMailComponent = () => {
          setShowJoinMain(false);
     }

     const toggleCancel = () => {
          setShowJoinMain(true);
     }

     

     return (
          <Container fluid>
               <Row className="nouser-container">

                    {showJoinComponent ? (
                         showJoinMain ? (
                              <JMainNoUser toggleJoinComponent={toggleJoinComponent} toggleMailComponent={toggleMailComponent} />
                         ) : (
                              <JoinEmailNoUser toggleCancel={toggleCancel} toggleJoinComponent={toggleCancel} />
                         )
                    ) : (
                         <LoginNoUser toggleJoinComponent={toggleJoinComponent} />
                    )}
               </Row>

          </Container>
     )
}

export default NoUser