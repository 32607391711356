import React from "react";
import Truncate from "./Truncate";
import { useTranslation } from "react-i18next";

const DiveGames = () => {
     const {t} = useTranslation()
     const text01 = (
          <>
               <h5 className="card-subtitle">{t('deepgamestitle1')}</h5>
               <p className="card-text">{t('deepgamestxt1p1')}</p>
               <p className="card-text">{t('deepgamestxt1p2')}</p>
          </>
     )

     const text02 = (
          <>
               <h5 className="card-subtitle">{t('deepgamestitle2')}</h5>
               <p className="card-text">{t('deepgamestxt2p1')}</p>
               <p className="card-text">{t('deepgamestxt2p2')}</p>
          </>
     )

     const text03 = (
          <>
               <h5 className="card-subtitle">{t('deepgamestitle3')}</h5>
               <p className="card-text">{t('deepgamestxt3p1')}</p>
               <p className="card-text">{t('deepgamestxt3p2')}</p>
          </>
     )
     return (
          <div className="container spacing-box-top" id="mergulhe">
               <div className="row">
                    <div className="col-12">
                         <div className="text-center card-title ">
                              <h2 class="block-title">{t('divedeepertitleh1')}</h2>
                         </div>
                    </div>
               </div>
               <div className="row">
                    <div className="col-12 col-lg-4 mb-5">
                         <Truncate text={text01} maxLength={150} />
                    </div>

                    <div className="col-12 col-lg-4 mb-5">
                         <Truncate text={text02} maxLength={150} />
                    </div>


                    <div className="col-12 col-lg-4 mb-5">
                         <Truncate text={text03} maxLength={150} />
                    </div>

               </div>
          </div>
     )
}

export default DiveGames