import "./logincards.css"
import { auth } from "../../config/firebaseConfig";
import React from "react";
import { useState, useEffect, useContext } from "react";
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification, fetchSignInMethodsForEmail } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import DicaErro from '../misc/Dicas';
import { getUserGeoData } from "../../helpers/users";
import { AuthContext } from "../../contexts/AuthContext";

const JoinEmailNoUser = ({ toggleCancel, toggleJoinComponent }) => {
     const [verifySenha, setVerifysenha] = useState("");
     const [confClass, setConfClass] = useState("");
     const [avisoSenha, setAvisoSenha] = useState("");
     const [confSenha, setConfSenha] = useState("");
     const [email, setEmail] = useState("");
     const [senha, setSenha] = useState("");
     const [senhaClass, setSenhaclass] = useState("");
     const [mailClass, setMailClass] = useState("");
     const [signupres, setSignupres] = useState("");
     const [mailres, setMailres] = useState("");
     const { setUsuario } = useContext(AuthContext);
     const auth = getAuth();
     const navigate = useNavigate();

     let id_Paes = null;

     // Coleta os dados geográficos o user
     async function fetchUserGeoData() {
          try {
               const userGeoData = await getUserGeoData();
               const nomePais = userGeoData.countryName;
               const codPais = userGeoData.countryCode;
               return { nomePais, codPais };
          } catch (error) {
               console.log(error)
          }
     }

     async function getPaisData(code, name) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/get-pais/" + code + "/" + name)
               const data = await response.json()
               const id_pais = data.cData.id_pais
               return id_pais
          } catch (error) {
               console.log(error)
          }
     }

     useEffect(() => {
          if (confSenha !== senha) {
               setConfClass("entrada-invalida")
          } else {
               setConfClass("")
               setAvisoSenha("")
          }
     }, [confSenha])

     function handleJoin() {
          toggleCancel()
     }

     function handleConectar() {
          toggleJoinComponent();
     }

     const handleMailSenha = async (e) => {
          e.preventDefault();

          // Controla a qualidade da senha
          const senhaRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[@$!%*#?&]).{8,}$/;
          if (!senhaRegex.test(senha)) {
               setVerifysenha(
                    <ul className='popover-lista'>
                         <li>8 caracteres.</li>
                         <li>1 maiúscula.</li>
                         <li>1 minúscula.</li>
                         <li>1 número.</li>
                         <li>1 caractere especial.</li>
                    </ul>
               );
               setSenhaclass("entrada-invalida");
               return;
          } else {
               setSenhaclass("");
               setVerifysenha("")
          }

          // Compara senhas digitadas
          if (senha !== confSenha) {
               setAvisoSenha("As senhas estão diferentes!");
               return
          }

          await createUserWithEmailAndPassword(auth, email, senha)
               .then((userCredential) => {

                    // atribuição de país
                    async function getDados() {
                         try {
                              const { nomePais, codPais } = await fetchUserGeoData();
                              try {
                                   const idPais = await getPaisData(codPais, nomePais)
                                   id_Paes = idPais;
                              } catch (erro) {
                                   console.error("Erro ao obter dados do país em banco de dados:", erro)
                              }
                         } catch (error) {
                              console.error("Erro ao obter dados geográficos do usuário:", error);
                         }
                    }
                    (async function () {
                         await getDados(); // Aguarde a promessa ser resolvida

                         // Signed in 
                         const user = userCredential.user;
                         setUsuario(userCredential.user);
                         setSignupres("")

                         // Envia email de confirmação
                         sendMailVerify(user);

                         // Grava dados na tabela
                         const uid = user.uid
                         const provider = user.providerId
                         const verificado = user.emailVerified
                         const user_status = 0

                         createUser(uid, email, provider, user_status, id_Paes);

                         // redireciona
                         if (verificado) {
                              navigate("/")
                         } else {
                              navigate("/verifyemail")
                         }

                    })();
               })
               .catch((error) => {
                    // gravar erros em log nosql
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode, errorMessage)
                    if (errorCode === 'auth/email-already-in-use') {
                         setSignupres("O email já está em uso! Usuário não registrado.")
                         setMailClass("entrada-invalida")
                    } else {
                         setSignupres("Usuário não registrado. Ocorreu um erro inesperado:", errorCode)
                         setMailClass(null);
                    }
               });
     }

     async function createUser(uid, email, provider, user_status, id_country) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/user-create", {
                    method: "POST",
                    headers: {
                         "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ uid, email, provider, user_status, id_country }),
               });
               // aqui verifica se a api gravou os dados
               const data = await response.json();
               if (data.sucesso === true) {
                    console.log("Registro criado com sucesso.");
                    // recupera id do país e grava dados

               } else if (data.existe === "sim") {
                    console.log("Uid já existe!")
                    // não faz nada
               } else {
                    console.error("Erro ao criar o registro:", data.error);
                    // Se não criou registro
                    // 1 - grava log do erro
                    // 2 - seta mensagem, seta NAVIGATION
               }

          } catch (error) {
               // não acessou a api
               console.error("Erro ao acessar a api de gravação de dados:", error);
          }
     }

     async function sendMailVerify(dados) {
          // ### AQUI: implementar função que cria log em caso de erro de envio de email de verificação
          try {
               await sendEmailVerification(dados);
               setMailres("Email de verificação enviado com sucesso!")

          } catch (error) {
               setMailres("Problema ao enviar email de confirmação: " + error)
          }
     }

     return (
          <div className="sbx-login-card-shape-nouser">
               <p className="sbx-login-card-title">Crie sua conta grátis agora!</p>

               <input
                    className="modal-input"
                    required
                    name='email'
                    type="email"
                    placeholder='EMAIL'
                    onChange={(e) => setEmail(e.target.value)}
                    onBlur={(e) => { setMailClass(""); setSignupres("") }}
                    onFocus={(e) => { setMailClass(""); setSignupres("") }}
               />
               <div className="row sbx-input-warning-area"></div>

               <input
                    className="modal-input"
                    required
                    name='senha'
                    type="password"
                    placeholder='SENHA'
                    onChange={(e) => setSenha(e.target.value)}
                    onFocus={(e) => { setSenhaclass(""); setVerifysenha("") }}
               />
               <div className="row sbx-input-warning-area">
                    {verifySenha !== "" && (<DicaErro titulo="A senha deve ter:" msg={verifySenha} urlLabel="Senha incorreta. Clique para ver a dica" placement="left" />)}
               </div>

               <input
                    className={`modal-input ${confClass}`}
                    type="password"
                    placeholder='CONFIRMAR SENHA'
                    onChange={(e) => setConfSenha(e.target.value)}
                    onBlur={(e) => setConfClass("")}
                    onFocus={(e) => setAvisoSenha("")}
               />
               <div className="row sbx-input-warning-area">
                    <div className="input-msg-erro">{avisoSenha}</div>
               </div>
     
               <button className="sbx-login-card-button mb-3" onClick={handleMailSenha}>CRIAR CONTA</button>
               <div className="row sbx-input-warning-area">
                    <div className="input-msg-erro">{signupres}</div>
               </div>

               <p><strong><span onClick={handleJoin} style={{ cursor: 'pointer' }}>CANCELAR</span></strong></p>

               <div className="mt-2 text-center">
                    <p style={{ margin: '0' }}>
                         Você já tem uma conta?<br />
                         <strong style={{ fontSize: '1.2rem', cursor: 'pointer' }} onClick={handleConectar}>Conecte-se</strong>
                    </p>
               </div>

          </div>
     )
}
export default JoinEmailNoUser