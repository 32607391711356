import React, { useEffect, useState, useRef } from "react";
import { getSwaarm } from "../../components/functions/GetSwaarm";
import OfferCard from "../../components/misc/OfferCard";
import { getDevice } from "../../components/functions/GetDevice";
import { AuthContext } from "../../contexts/AuthContext";
import { useContext } from "react";
import { Fragment } from "react";
import Parceiros from "../common/Parceiros";
import HowGames from "../common/HowGames";
import Stats from "../common/Stats";
import AboutGames from "../common/AboutGames";
import DiveGames from "../common/DiveGames";
import PageNavigator from "../../components/pagination/PageNavigator";
import SbxSpinner from "../common/SbxSpinner";
import SeoTags from "../common/SeoTags";
import FaqGame from "../common/FaqGame";
import { useTranslation } from "react-i18next";

const Games = () => {
     const { t } = useTranslation()
     const { idIdioma } = useContext(AuthContext)
     const { isoPais } = useContext(AuthContext)
     const [swaarmOffers, setSwaarmOffers] = useState(null)
     const [currentPage, setCurrentPage] = useState(0)
     const itemsPerPage = 12
     let device = getDevice()
     let totalPages = 1
     const idCategoria = 4
     const listRef = useRef(null)

     // TAGS SEO
     const title = t('seogamestitle')
     const description = t('seogamesdesc')

     useEffect(() => {
          if (idIdioma && isoPais) {
               getSwaarm(idCategoria, idIdioma, device, isoPais)
                    .then((swaarmData) => {
                         setSwaarmOffers(swaarmData);
                    })
                    .catch((error) => {
                         console.error("Erro ao buscar dados do Swaarm:", error);
                    });
          }
     }, [idIdioma, isoPais]);

     if (swaarmOffers == null) {
          totalPages = 1
     } else {
          if (swaarmOffers.adsTrans !== undefined) {
               totalPages = Math.ceil(
                    (swaarmOffers?.adsTrans.length || 0) / itemsPerPage
               );
          }
     }

     const handlePageChange = (page) => {
          setCurrentPage(page);
          if (listRef.current) {
               listRef.current.scrollIntoView({ behavior: 'smooth' });
          }
     };

     const handleNextClick = () => {
          if (currentPage + 1 < totalPages) {
               setCurrentPage(currentPage + 1);
          }
          if (listRef.current) {
               listRef.current.scrollIntoView({ behavior: 'smooth' });
          }
     };

     const handlePreviousClick = () => {
          if (currentPage > 0) {
               setCurrentPage(currentPage - 1);
          }
          if (listRef.current) {
               listRef.current.scrollIntoView({ behavior: 'smooth' });
          }
     };

     return (
          <Fragment>
               <SeoTags
                    title={title}
                    description={description}
                    url="https://www.superbuxx.com/games"
               />
               <div className="container-fluid banner-header text-white text-center py-3">
                    <h1>{t('gamestitleh1')}</h1>
                    <div className="paragrafo-homepage-log-out">
                         <p>{t('gamestxthead')}</p>
                    </div>
               </div>
               <div className="container-fluid container-spacing block-spacing-pages" ref={listRef}>
                    <ul className="row cards-list">
                         {swaarmOffers === null ? (
                              <SbxSpinner />
                         ) : (
                              <>
                                   {swaarmOffers.adsTrans
                                        .slice(
                                             currentPage * itemsPerPage,
                                             currentPage * itemsPerPage + itemsPerPage
                                        )
                                        .map((ad, index) => (

                                             <li key={index} className="col-12 col-xl-3 col-lg-6 mb-4">

                                                  <OfferCard
                                                       cor="w"
                                                       oName={ad.offer_name}
                                                       creative={ad.creative}
                                                       info={ad.short_desc}
                                                       payout={ad.payout}
                                                       offer_id={ad.offer_id}
                                                       os={ad.os}
                                                  />
                                             </li>
                                        ))}
                              </>
                         )}
                    </ul>

                    <PageNavigator
                         totalPages={totalPages}
                         currentPage={currentPage}
                         handlePreviousClick={handlePreviousClick}
                         handleNextClick={handleNextClick}
                         handlePageChange={handlePageChange}
                    />

                    <div className="container">
                         <div className="row parceiro-container spacing-box-top">
                              <div className="title-parceiros">
                                   <h2>{t('gamesowtitle')}</h2>
                              </div>
                              <Parceiros />
                         </div>
                    </div>
                    <HowGames />
                    <Stats />
                    <AboutGames />
                    <FaqGame />
                    <DiveGames />

               </div>
          </Fragment >
     );
};

export default Games;
