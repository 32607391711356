// Formulário de edição de dados do usuário
import { AuthContext } from "../../contexts/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { getUserData } from "../../components/functions/GetUserData";
import { paisById } from "../../components/functions/GetPaisData";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const UserPayPalInfo = props => {
     const { t } = useTranslation()
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario
     const [userName, setUserName] = useState(null)
     const [payPalMail, setPayPalMail] = useState(null)
     const [statusBtOk, setStatusBtOk] = useState(true)
     const [showModal, setShowModal] = useState(false)
     const [isInvalidClass, setIsInvalidClass] = useState(false)
     const [showNameMsg, setShowNameMsg] = useState("loading")
     const [NameMsg, setNameMsg] = useState("")
     const [showModalNewPayPal, setShowModalNewPayPal] = useState(false)
     const [invalidPPalMsg, setInvalidPPalMsg] = useState(false)
     const [disablePPal, setDisablePPal] = useState(false)
     const [isComplete, setIsComplete] = useState(false)

     // Restaura dados de pagamento, se houver
     useEffect(() => {
          fetch("https://www.superbuxx.com:3030/get-user-pay-data-br/" + uid, {
               method: "GET",
               headers: { "Content-Type": "application/json" }
          })
               .then(response => response.json())
               .then(data => {
                    setPayPalMail(data.global_paypal)
                    if (data.global_paypal) {
                         setIsComplete(true)
                    }
               })
               .catch(error => {
                    console.error("Erro na requisição:", error);
               });
     }, [uid])

     // Set estado inicial dos elementos se o cadastro estiver completo (e não pode ser alterado)
     useEffect(() => {
          if (isComplete) {
               setDisablePPal(true)
               setStatusBtOk(true)
          } else {
               let valido = (validateEmail(payPalMail));
               if (!valido) {
                    if (payPalMail !== null) {
                         setInvalidPPalMsg(t('invalidppalmsg1'))
                    }
                    setStatusBtOk(true)
               } else {
                    setInvalidPPalMsg(false)
                    setStatusBtOk(false)
               }
          }
     }, [isComplete, payPalMail])

     // Recupera nome de usuário
     useEffect(() => {
          getUserData(uid)
               .then(data => {
                    setUserName(data.user_name)
                    if (data.user_name == "") {
                         setShowNameMsg(true)
                    }
               })
     }, [uid])

     const validateEmail = (email) => {
          const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return regex.test(email);
     };

     const handleClose = () => {
          if (showModal) {
               setShowModal(false)
          }
     }

     const handleCloseNewPayPal = () => {
          if (showModalNewPayPal) {
               setShowModalNewPayPal(false)
          }
     }

     // Lida com modal de confirmação da email PayPal
     const handleConfirmModal = () => {
          setShowModal(true)
     }

     // Gravar dados (criar outro endpoint específico para gravar paypal)
     const handleGravar = async () => {
          const response = await fetch("https://www.superbuxx.com:3030/add-user-paypal-data-global", {
               method: "POST",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({ uid, payPalMail }),
          });
          const data = await response.json();

          if (data.paypalexist) {
               setInvalidPPalMsg(t('supwrng1'))
               setIsInvalidClass('is-invalid')
          }

          if (data.paypalisinusepix) {
               setInvalidPPalMsg(t('invalidppalmsg2'))
               setIsInvalidClass('is-invalid')
          }

          if (data.recpaypal) {
               setStatusBtOk(true)
               setDisablePPal(true)
               setShowModalNewPayPal(true)
          }
          setShowModal(false)

     }

     const handleClearInput = () => {
          if (invalidPPalMsg) {
               setIsInvalidClass("")
               setInvalidPPalMsg(false)
               setPayPalMail("")
          }
     }

     return (
          <Fragment>
               <div className="container-fluid banner-header text-white text-center py-3">
                    <h1>{t('ppalinfotitleh1')}</h1>
               </div>

               <div className="container-fluid container-spacing">
                    <div className="row">
                         <div className="col mb-5">
                              <div id="offer-details">
                                   <div className="container container-offer-details">
                                        <div className="row content-offer-details" style={{ marginTop: "0" }}>
                                             <div className="col-12">
                                                  <div className="background-box-offer-details">
                                                       <div className="box-offer-details">
                                                            <p style={{color:"white"}}><strong>{t('pixinfotitle')}</strong></p>
                                                            <p>{t('paypalinfop1')}</p>
                                                            <p>{t('paypalinfop2')}</p>
                                                            <p>{t('paypalinfop3')}</p>
                                                            <p>{t('paypalinfop4')}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         <div className="col">
                              <div className="container-fluid container-md">
                                   <div className="row">
                                        <div className="col-6 mb-3">
                                             <div className="input-field">
                                                  <label htmlFor="paypalkey" className="form-label">{t('paypalkeylabel')}</label>
                                                  <input
                                                       type="email"
                                                       className={`form-control ${isInvalidClass}`}
                                                       id="paypalkey"
                                                       name="paypalkey"
                                                       placeholder={t('paypalkeylabel')}
                                                       onChange={(e) => setPayPalMail(e.target.value)}
                                                       value={payPalMail}
                                                       disabled={disablePPal}
                                                       onClick={handleClearInput}
                                                  />
                                             </div>
                                             {invalidPPalMsg ? (
                                                  <div className="sbx-warning-area">
                                                       <small className="sbx-msg-bad">{invalidPPalMsg}</small>
                                                  </div>
                                             ) : (null)}
                                             {/* {invalidPPalMsg && payPalMail !== "" && payPalMail !== null ? (
                                                  <div className="sbx-warning-area">
                                                       <small className="sbx-msg-bad">{invalidPPalMsg}</small>
                                                  </div>
                                             ) : (null)} */}
                                        </div>
                                        <div className="col-6 mb-3">
                                             <div className="input-field">
                                                  <label htmlFor="username" className="form-label">{t('nameilabel')}</label>
                                                  <input
                                                       type="text"
                                                       className="form-control"
                                                       id="username"
                                                       name="username"
                                                       placeholder={t('namepholder')}
                                                       value={userName}
                                                       disabled="true"
                                                  />
                                             </div>
                                             {showNameMsg ? (
                                                  <div className="sbx-warning-area">
                                                       <small className="sbx-msg-bad">{NameMsg}</small>
                                                  </div>
                                             ) : (null)}
                                        </div>
                                   </div>

                                   <button
                                        className="btn btn-to-check-2 col-12"
                                        disabled={statusBtOk}
                                        onClick={() => handleConfirmModal()}
                                   >
                                        {t('savedatabt')}
                                   </button>
                              </div>
                         </div>
                    </div>
               </div>

               {showModal && (
                    <div
                         className="modal fade show"
                         id="verifyCellModal"
                         tabIndex="-1"
                         aria-labelledby="verifyCellModalLabel"
                         aria-hidden="true"
                         style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    >
                         <div className="modal-dialog modal-dialog-centered">
                              <div className="modal-content">
                                   <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">{t('pixwarning')}</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                                   </div>
                                   <div className="modal-body text-center">
                                        <p>{t('whenclick')} <strong>"{t('confirmlink')}",</strong> {t('warningppaltxt1')}</p>
                                        <p><strong>{t('important')}</strong> {t('warningppaltxt2')}</p>
                                   </div>
                                   <div className="modal-footer">
                                        <button type="button" className="buxx-btn" data-dismiss="modal" onClick={handleClose}>{t('tocancelbt')}</button>
                                        <button type="button" className="buxx-btn outlined" onClick={handleGravar}><strong>{t('toconfirmbt')}</strong></button>
                                   </div>
                              </div>
                         </div>
                    </div>
               )}

               {
                    showModalNewPayPal ? (
                         <div className="toast-container">
                              <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                                   <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={handleCloseNewPayPal}></button>
                                   <div className="toast-body">
                                        <div className="icon">

                                             <img src="../src/assets/icons/toast-success.svg" alt="" />

                                        </div>
                                        <strong>{t('paypalok')}</strong>
                                        <p>{t('paypaloktxt')}</p>
                                   </div>
                              </div>
                         </div>
                    ) : (null)
               }

          </Fragment>
     )
}

export default UserPayPalInfo