import "./logincards.css"
import React from "react"
import GoogleLogo from "/image/icons/google01.png"
import MailLogo from "/image/icons/mail01.png"
import { Form } from "react-bootstrap"
import { useEffect, useState, useContext } from "react"
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../../contexts/AuthContext"
import { getUserGeoData } from "../../helpers/users"

const JMainNoUser = ({ toggleJoinComponent, toggleMailComponent }) => {
     const [switchStatus, setSwitchStatus] = useState(false)
     const [btnStyle, setBtnStyle] = useState(null);
     const [termsStyle, setTermsStyle] = useState({ color: 'black' })
     const [swStyle, setSwStyle] = useState('')
     const [exibeAviso, setExibeAviso] = useState(false)
     const aviso = "Por favor, selecione para continuar"
     const navigate = useNavigate()
     const { setUsuario } = useContext(AuthContext)
     let id_Paes = null;

     // Coleta os dados geográficos o user
     async function fetchUserGeoData() {
          try {
               const userGeoData = await getUserGeoData();
               const nomePais = userGeoData.countryName;
               const codPais = userGeoData.countryCode;
               return { nomePais, codPais };
          } catch (error) {
               console.log(error)
          }
     }

     async function getPaisData(code, name) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/get-pais/" + code + "/" + name)
               const data = await response.json()
               const id_pais = data.cData.id_pais
               return id_pais
          } catch (error) {
               console.log(error)
          }
     }

     // Comportamento dos botões de acordo com o switch
     function handleSwitch() {
          if (switchStatus == false) {
               setSwitchStatus(true)
               setTermsStyle({ color: 'black' })
               setSwStyle('')
               setExibeAviso(false)
          } else {
               setSwitchStatus(false)
          }
     }
     useEffect(() => {
          if (switchStatus == false) {
               setBtnStyle({ cursor: 'default' })
          } else {
               setBtnStyle({ cursor: 'pointer' })
               setExibeAviso(false)
          }

     }, [switchStatus])

     // Comportamento dos Botões de Acesso
     function handleGoogle() {
          if (switchStatus == false) {
               setStyle()
          } else {
               signupGoogle()
          }
     }

     function handleMail() {
          if (switchStatus == false) {
               setStyle()
          } else {
               // abre card JoinEmail
               toggleMailComponent()
          }
     }

     function handleConectar() {
          toggleJoinComponent();
     }

     function setStyle() {
          // configura estilos de elementos do card
          setTermsStyle({ color: 'red' })
          setSwStyle('switch-error')
          setExibeAviso(true)
     }

     const signupGoogle = async () => {
          const auth = getAuth();
          const provider = new GoogleAuthProvider();
          signInWithPopup(auth, provider)
               .then((result) => {

                    // atribuição de país
                    async function getDados() {
                         try {
                              const { nomePais, codPais } = await fetchUserGeoData();
                              try {
                                   const idPais = await getPaisData(codPais, nomePais)
                                   id_Paes = idPais;
                              } catch (erro) {
                                   console.error("Erro ao obter dados do país em banco de dados:", erro)
                              }
                         } catch (error) {
                              console.error("Erro ao obter dados geográficos do usuário:", error);
                         }
                    }
                    (async function () {
                         await getDados(); // Aguarde a promessa ser resolvida
                         // This gives you a Google Access Token. You can use it to access the Google API.
                         const credential = GoogleAuthProvider.credentialFromResult(result);
                         const token = credential.accessToken;
                         // The signed-in user info.
                         const user = result.user;
                         const email = user.email;
                         setUsuario(user)
                         const { uid, providerId } = user || {};
                         const user_status = 1

                         createUser(uid, email, providerId, user_status, id_Paes);
                         navigate("/")
                    })();

               }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    // ...
                    console.log(error)
               });
     }

     async function createUser(uid, email, provider, user_status, id_country) {
          try {
               const response = await fetch("https://www.superbuxx.com:3030/user-create", {
                    method: "POST",
                    headers: {
                         "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ uid, email, provider, user_status, id_country }),
               });
               // aqui verifica se a api gravou os dados
               const data = await response.json();
               if (data.sucesso === true) {
                    console.log("Registro criado com sucesso.");
                    // recupera id do país e grava dados

               } else if (data.existe === "sim") {
                    console.log("Uid já existe!")
                    // não faz nada
               } else {
                    console.error("Erro ao criar o registro:", data.error);
                    // Se não criou registro
                    // 1 - grava log do erro
                    // 2 - seta mensagem, seta NAVIGATION
               }

          } catch (error) {
               // não acessou a api
               console.error("Erro ao acessar a api de gravação de dados:", error);
          }
     }


     return (
          <div className="sbx-login-card-shape-nouser">
               <p className="sbx-login-card-title">Conecte-se para participar.</p>
               <hr></hr>
               <p className="sbx-login-card-title">Crie sua conta grátis agora!</p>

               <div className="row mb-3">
                    <div className="col-3 text-center sbx-midle">
                         <div className={`triangle ${exibeAviso ? 'triangle-error' : ''}`}></div>
                         <Form>
                              <Form.Check
                                   className={`p-0 custom-switch ${swStyle}`}
                                   type="switch"
                                   id="terms-switch"
                                   onClick={handleSwitch}
                              />
                         </Form>
                    </div>
                    <div className="col text-left sbx-midle">
                         <p className="sbx-card-terms" style={termsStyle}>
                              Eu aceito os <a href="#" target="_blank" style={termsStyle}>Termos e condições de uso</a> de Super Buxx e também aceito a <a href="#" target="_blank" style={termsStyle}>Política de Privacidade.</a>
                         </p>
                    </div>

                    {exibeAviso && (
                         <div className="w-100 text-start mt-2">
                              <p className="sbx-card-terms" style={{ color: 'red' }}>{aviso}</p>
                         </div>
                    )}
               </div>


               <button className="sbx-login-card-social-button" style={btnStyle} onClick={handleGoogle}>
                    <span><img src={GoogleLogo} className="sbx-logo-size" /></span>
                    <span>Continuar com Google</span>
               </button>

               <p>OU</p>

               <button className="sbx-login-card-social-button" style={btnStyle} onClick={handleMail}>
                    <span><img src={MailLogo} className="sbx-logo-size" /></span>
                    <span>Continuar com Email</span>
               </button>

               <div className="mt-3 text-center">
                    <p style={{ margin: '0' }}>
                         Você já tem uma conta?<br />
                         <strong style={{ fontSize: '1.2rem', cursor: 'pointer' }} onClick={handleConectar}>Conecte-se</strong>
                    </p>
               </div>

          </div>
     )
}
export default JMainNoUser