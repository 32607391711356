import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext';
import { useContext } from 'react';
import { getDevice } from '../functions/GetDevice';

import { useTranslation } from 'react-i18next';

export default props => {
     const { t } = useTranslation()
     let controle = 1
     const cor = props.cor
     let oName = props.oName
     const offer_id = props.offer_id
     const info = props.info
     const os = props.os
     const payout = props.payout
     let iconOs
     let labelOs
     let creative = props.creative
     const navigate = useNavigate()
     const { usuario } = useContext(AuthContext);
     const { uid } = usuario
     const url = props.url
     
     // Handle url things
     let tag
     const device = getDevice();
     if(device == "WEB") {
          tag = 'desktop'
     } else {
          tag = 'mobile'
     }

     // Handle url (click_track)
     const finalUrl = url.replace("[USER_ID", uid).replace("[tag]", tag)

     // Tratamento de exibição
     if (!oName) {
          oName = "Campanha"
     }

     // Device
     if (os == "android") {
          iconOs = "/image/icons/android.svg"
          labelOs = "ANDROID"
     }
     if (os == "web" || os == "all") {
          iconOs = "/image/icons/icon-web.svg"
          labelOs = "WEB"
     }
     if (os == "iphone" || os == 'ipad' || os == 'iphone_ipad') {
          iconOs = "/image/icons/ios.svg"
          labelOs = "iOS"
     }


     function handleClick() {
          if (usuario) {
               window.open(finalUrl, '_blank')
          } else {
               window.location.href = '/login';
          }
     }

     return (
          <div className="card-campain" onClick={handleClick}>
               <div className="card-image">
                    <div className="os-badge">
                         <img src={iconOs} alt="" />
                         <span>{labelOs}</span>
                    </div>
                    <div className="background-image">
                         <img src={creative} alt="" title="" className="img-fluid" />
                    </div>
               </div>
               <div className="card-info">
                    <div className="os-badge">
                         <img src={iconOs} alt="" />
                         <span>{labelOs}</span>
                    </div>
                    <h3>{oName}</h3>
                    <p>{info}</p>
                    <button className="btn btn-custom">{t('earn')} {payout} BUXX</button>
               </div>
          </div>

     )

}