export async function getUserClickData(uid) {
     try {
          const response = await fetch("https://www.superbuxx.com:3030/get-user-click-data/" + uid, {
               method: "GET",
               headers: { "Content-Type": "application/json" }
          });
          if (response.ok) {
               const res = await response.json();
               return res
          } else {
               return false
          }
     } catch (error) {
          console.error(error)
          return false
     }
}