import React from "react";

const AboutCashback = () => {
     return (
          <div className="container spacing-box-top">
               <div className="row">
                    <div className="col-12">
                         <div className="box-text">
                              <p className="text-superbuxx-custom">Você já se surpreendeu com a ideia de receber dinheiro de volta apenas por gastá-lo? Bem-vindo ao mundo do cashback, onde as compras do dia a dia podem guardar secretamente pequenos tesouros para você. O cashback oferece uma maneira inteligente de aumentar seu dinheiro, transformando transações rotineiras em oportunidades de ganhos inesperados. Se você está se perguntando como tirar o máximo proveito dessa joia financeira, está no lugar certo! No Superbuxx, você pode aproveitar o cashback cartão de crédito, cashback mercado pago, cashback app, e muito mais. Transforme suas compras online em uma fonte de renda adicional e veja seu dinheiro crescer a cada transação. Inscreva-se agora e descubra as diversas formas de economizar e ganhar dinheiro com o Superbuxx!</p>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default AboutCashback