// Funções que transformam dados brutos vindos da Swaarm para formato de exibição.

export const getOfferName = (fullname) => {
     // Retorna nome da oferta sem tags
     const regex = /\[(.*?)\]/;
     const resultado = regex.exec(fullname);
     return resultado ? resultado[1] : '';
}

export const getOfferDesc = (bruteInfo, idioma) => {
     // Retorna o conteúdo para uma descrição completa da oferta, incluindo idioma.

     const longRegex = /\[DESC\]([\s\S]*?)\[\/DESC\]/;
     const longMatch = bruteInfo.match(longRegex);
     if (!longMatch) {
          return 'Description not found. (Descrição não encontrada).';
     }
     const longContent = longMatch[1];

     // Em seguida, extrai o conteúdo dentro da tag do idioma especificado
     const langRegex = new RegExp('<' + idioma + '>([\\s\\S]*?)<\\/' + idioma + '>');
     const langMatch = longContent.match(langRegex);
     if (!langMatch) {
          return 'No description for this offer. (Sem descrição para essa oferta).';
     }
     return langMatch[1].trim();
}

export const getEventData = (bruteInfo, idioma) => {
     // Retorna um ARRAY contento event_id e name_id no idioma escolhido.
     // #01 - Pega dados entre as tags [EVENT]|<id>
     const regex = /\[EVENT <(\d+)>\](.*?)\[\/EVENT <\1>\]/g;
     let match;
     const events = [];
     while ((match = regex.exec(bruteInfo)) !== null) {
          let event_name;
          if (idioma === "pt") {
               event_name = match[2].match(/<pt>(.*?)<\/pt>/)[1].trim();
          } else if (idioma === "en") {
               event_name = match[2].match(/<en>(.*?)<\/en>/)[1].trim();
          }

          events.push({
               event_id: +match[1],
               event_name,
          });
     }

     return events;
}
