import { useContext, useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { getTicketAllData } from "../../components/functions/GetTicketAllData";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import moment from "moment-timezone";
import { getUserDetail } from "../../components/functions/getUserNavDetails";

const TicketReply = () => {
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario
     const { ticket_id } = useParams()
     const [offerData, setOfferData] = useState(null)
     const [wordsLeft, setWordsLeft] = useState(0)
     const [imagens, setImagens] = useState([])
     const [message, setMessage] = useState("")
     const [selectedEvent, setSelectedEvent] = useState('')
     const [msgErro, setMsgErro] = useState("")
     const [avatar, setAvatar] = useState('/image/icons/avatar.png')
     const [userDetail, setUserDetail] = useState(false)
     const navigate = useNavigate()

     // Variáveis de exibição
     const [offerName, setOfferName] = useState("")
     const [eventName, setEventName] = useState("")
     const [messages, setMessages] = useState([])
     const [status, setStatus] = useState("")
     const [uploads, setUploads] = useState([])
     const [statusLabel, setStatusLabel] = useState("")
     const [statusColor, setStatusColor] = useState({ backgroundColor: "darkgray" })
     const sbxAvatar = '/image/icons/moeda.webp'
     const [newRec, setNewRec] = useState()

     useEffect(() => {
          getUserDetail(uid).then((data) => {
               setUserDetail(data)
          })
     }, [uid])

     useEffect(() => {
          if (userDetail) {
               setAvatar(userDetail[0].imageUrl)
          }
     }, [userDetail])

     // Recupera dados do ticket
     useEffect(() => {
          getTicketAllData(ticket_id, uid)
               .then((resultado) => {
                    // Preparar variáveis para exibição
                    setOfferName(resultado[0].offer_name)
                    setEventName(resultado[0].event_name)
                    setStatus(resultado[0].status)
                    setUploads(resultado[0].uploads)
                    const mensagens = resultado[0].messages
                    const formatedMessages = []

                    // Status
                    switch (resultado[0].status) {
                         case "pending":
                              setStatusLabel("Em análise")
                              setStatusColor({ backgroundColor: "yellow", color: "black" })
                              break;
                         case "user_pending":
                              setStatusLabel("Esperando sua resposta")
                              setStatusColor({ backgroundColor: "orange", color: "black" })
                              break;
                         case "closed":
                              setStatusLabel("Fechado")
                              setStatusColor({ backgroundColor: "darkgray", color: "black" })
                              break;
                         case "accepted":
                              setStatusLabel("Aceito")
                              setStatusColor({ backgroundColor: "green", color: "white" })
                              break;
                         case "denied":
                              setStatusLabel("Recusado")
                              setStatusColor({ backgroundColor: "red", color: "white" })
                              break;
                    }

                    // Array de mensagens
                    mensagens.map((msg) => {
                         // Remetente
                         let from = msg.from;
                         let remetente
                         if (from === uid) {
                              remetente = "Você"
                         } else {
                              remetente = "Super Buxx"
                         }

                         // Data / Hora
                         let msgtime = moment(msg.updated_at).tz('UTC').format('DD/MM/YYYY | HH:mm');

                         // Array de retorno
                         formatedMessages.push({
                              remetente: remetente,
                              body: msg.message,
                              msgtime: msgtime,
                              msgId: msg.msg_id
                         })
                    })
                    setMessages(formatedMessages)
               })
               .catch((error) => {
                    console.log("erro: ", error)
               })
     }, [newRec])

     useEffect(() => {
          if (offerData === false) {
               localStorage.setItem("sbxTicketErrorMsg", msgErro)
               navigate("/ticketerror")
          }
     }, [offerData])



     useEffect(() => {
          // Calcula quantas letras faltam no campo de mensagens
          setWordsLeft(400 - message.length)
     }, [message])

     const handleImageChange = (e) => {
          if (e.target.files && e.target.files[0]) {
               const file = e.target.files[0]
               const fileSize = file.size / 1024
               if (fileSize > 600) {
                    alert('O arquivo é muito grande. Por favor, envie um arquivo de até 600k.')
               } else if (imagens.length < 5) {
                    setImagens(imagens => [...imagens, e.target.files[0]]);
               } else {
                    alert('Você pode enviar no máximo 5 imagens');
               }
          }
     }

     const handleDelete = (index) => {
          setImagens(imagens => imagens.filter((_, i) => i !== index));
     }

     useEffect(() => {
          if (offerData && offerData[0].events_list.length > 0) {
               setSelectedEvent(offerData[0].events_list[0].event_id);
          }
     }, [offerData]);

     const handleChange = (event) => {
          setSelectedEvent(event.target.value);
     };

     async function enviaDados() {
          if (message !== "") {
               try {
                    const formData = new FormData();
                    formData.append('uid', uid)
                    formData.append('ticketId', ticket_id)
                    formData.append('msg', message)


                    for (let i = 0; i < imagens.length; i++) {
                         const imagem = imagens[i];
                         if (imagem) {
                              const response = await fetch(URL.createObjectURL(imagem));
                              const blob = await response.blob();
                              formData.append('imagem' + i, blob, 'imagem' + i + '.jpg');
                         }
                    }

                    // Envia o FormData para o servidor
                    const response = await fetch("https://www.superbuxx.com:3030/send-ticket-reply", {
                         method: "POST",
                         body: formData,
                    });

                    if (!response.ok) {
                         throw new Error(`HTTP error! status: ${response.status}`);
                    } else {
                         //navigate('/tickets')
                         setNewRec(Date.now())
                         setMessage("")
                    }

               } catch (error) {
                    console.error(error)
                    // Grava dados em log
               }
          } else {
               toast.warning("O campo 'Mensagem' não pode estar vazio", { theme: "dark" });
          }
     }

     return (
          <Fragment>
               <div class="container">
                    <div class="col-12">
                         <div class="title-ticket-36">
                              <h1>ticket #{ticket_id}</h1>
                         </div>
                    </div>
                    <div class="row">
                         <div class="col-md-6 order-md-1 order-2">
                              <div class="container-adjust-chat">
                                   <div class="chat-box">
                                        <div class="header-box-chat">
                                             <div class="title-chat">
                                                  <h1>{offerName} - Ticket #{ticket_id}</h1>
                                             </div>
                                             <div class="chat-header">
                                                  <div class="chat-status">
                                                       <div class="status-item">
                                                            <span class="status-label" style={statusColor}>{statusLabel}</span>
                                                            <div class="divider"></div>
                                                       </div>
                                                       <div class="status-item">
                                                            <span class="campaign-name" style={{ textTransform: "none" }}>Evento: {eventName}</span>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="chat-body">
                                             {
                                                  // Exibe mensagens
                                                  messages.map((msg, index) => {
                                                       // Aqui, lógica desabilitar campo de resposta se o ticket estiver em análise

                                                       // Lógica para determinar se mostra avatar do user ou sbx
                                                       const senderImg = msg.remetente === "Super Buxx" ? sbxAvatar : avatar;

                                                       return (
                                                            <>
                                                                 <div className="message w-100" key={index}>

                                                                      <div className="comment-container">
                                                                           <div className="col">
                                                                                <img src={senderImg} alt="User Image" className="user-img-ticket" />
                                                                                <span className="comment-author">{msg.remetente}</span>
                                                                           </div>
                                                                           <div className="col message-time-content">
                                                                                <span className="comment-timestamp">{msg.msgtime}</span>
                                                                           </div>
                                                                      </div>

                                                                      <div className="ticket-message">
                                                                           <div class="comment-text">{msg.body}</div>
                                                                      </div>

                                                                      {
                                                                           uploads.filter(upload => upload.msg_id === msg.msgId).length > 0 && (
                                                                                <div class="message-images">
                                                                                     {
                                                                                          uploads.filter(upload => upload.msg_id === msg.msgId).map((upload, index) => (
                                                                                               <img key={index} src={`https://www.superbuxx.com:3030${upload.img_path}`} />
                                                                                          ))
                                                                                     }
                                                                                </div>
                                                                           )
                                                                      }
                                                                      <hr />
                                                                 </div>


                                                            </>
                                                       )
                                                  })
                                             }
                                        </div>
                                   </div>

                                   <div class="chat-input-box mb-2">
                                        <img src={avatar} alt="User Image" class="user-image" />
                                        <input
                                             type="text"
                                             class="message-input"
                                             placeholder="Escreva sua mensagem aqui..."
                                             name="reply"
                                             maxLength="400"
                                             value={message}
                                             onChange={(e) => setMessage(e.target.value)}
                                        />
                                        <div class="icons">
                                             <input
                                                  type="file"
                                                  name="img"
                                                  id="img"
                                                  accept=".jpg,.png,.webp"
                                                  onChange={handleImageChange}
                                                  style={{ display: 'none' }}
                                             />
                                             <label className="mb-2" htmlFor="img">
                                                  <div class="icon-button">
                                                       <img src="../src/assets/icons/clip-chat.svg" alt="Clip" />
                                                  </div>
                                             </label>
                                        </div>
                                        <button type="button" class="send-button" onClick={() => enviaDados()}>
                                             <img src="../src/assets/icons/arrow-send-chat.svg" alt="" />
                                        </button>
                                   </div>
                                   <small>({wordsLeft}/400)</small>

                                   <div class="images-container mt-3">
                                        <label>IMAGENS</label>
                                        <div class="images-box">
                                             <div class="row">
                                                  {imagens.map((imagem, index) => (
                                                       <div class="col-6 content-imagens-box spacing-bottom-images">
                                                            <div class="image-wrapper">
                                                                 <img src={URL.createObjectURL(imagem)} alt={`Imagem ${index + 1}`} />
                                                                 <span class="close-icon">
                                                                      <img src="../src/assets/icons/x-img-profile.svg" alt="x" onClick={() => handleDelete(index)} />
                                                                 </span>
                                                            </div>
                                                       </div>
                                                  ))}
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         <div class="col-md-6 order-md-2 order-1">
                              <div class="custom-container-profile">
                                   <div class="custom-ticket-status">
                                        <h3 class="custom-ticket-title">Ticket Status</h3>
                                        <div class="custom-ticket-detail">
                                             <span class="custom-ticket-label">Campanha:</span>
                                             <span class="custom-ticket-value">{offerName}</span>
                                        </div>
                                        <div class="custom-ticket-detail">
                                             <span class="custom-ticket-label">Evento Pago:</span>
                                             <span class="custom-ticket-value">{eventName}</span>
                                        </div>
                                        <div class="custom-ticket-detail">
                                             <span class="custom-ticket-label">Status:</span>
                                             <span class="custom-ticket-value">
                                                  <span class="custom-status-indicator" style={statusColor}></span>
                                                  {statusLabel}
                                             </span>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </Fragment>
     )
}

export default TicketReply