import React from "react";

const FaqCashback = () => {
     const x = [
          "Existem vários tipos de cashback, incluindo:",
          <ul><li>Cashback em cartões de crédito: Algumas instituições financeiras oferecem cashback em compras feitas com seus cartões.</li>Cashback em compras online: Plataformas como o Superbuxx oferecem cashback em uma ampla variedade de lojas online.<li>Cashback em compras físicas: Algumas lojas físicas também oferecem programas de cashback.</li><li>Cashback por categoria de produto: Alguns programas de cashback oferecem diferentes porcentagens de devolução dependendo da categoria do produto.</li><li>Cashback em serviços: Serviços de assinatura e streaming também podem oferecer cashback.</li></ul>,
          "Na Superbuxx, o tipo de cashback utilizado é o online cashback, onde você recebe uma porcentagem do valor gasto em compras online."
     ]
     // Perguntas e respostas
     const faq = [
          {
               ask: "Cashback o que é e como funciona?",
               res: "Cashback é um sistema que devolve ao consumidor uma parte do dinheiro gasto em suas compras. Funciona da seguinte maneira: você se inscreve em uma plataforma como o Superbuxx, faz suas compras online através dos links fornecidos e, após a confirmação da compra, recebe uma porcentagem do valor gasto de volta. Esse dinheiro pode ser retirado ou usado em futuras compras."
          },
          {
               ask: "Como receber dinheiro com as compras?",
               res: "Para receber dinheiro com suas compras, basta se inscrever no Superbuxx, escolher as lojas parceiras e fazer suas compras através dos links da plataforma. Após a confirmação da compra, você receberá uma porcentagem do valor gasto como cashback, que será creditado em sua conta no Superbuxx."
          },
          {
               ask: "Cashback filme onde assistir?",
               res: "Você pode obter cashback em serviços de streaming como HBO Max e Disney+ através do Superbuxx. Basta acessar a seção de cashback filme onde assistir na plataforma, escolher o serviço desejado e fazer sua assinatura ou aluguel de filmes. Uma parte do valor gasto será devolvida para você como cashback."
          },
          {
               ask: "Como usar cashback?",
               res: "Usar cashback é simples. Primeiro, inscreva-se no Superbuxx e faça suas compras através dos links fornecidos na plataforma. Após a confirmação da compra, você receberá uma porcentagem do valor gasto de volta. Esse valor pode ser acumulado e retirado para sua conta bancária ou ser utilizado em futuras compras."
          },
          {
               ask: "Quais são os diferentes tipos de cashback?",
               res: x
          },
          {
               ask: "Implicações Fiscais do Cashback",
               res: "No Brasil, o valor recebido como cashback pode ser considerado um desconto na compra e, portanto, não é tributado como renda. No entanto, é sempre bom consultar um contador ou especialista em tributação para entender melhor como o cashback pode afetar sua situação fiscal específica."
          },
          {
               ask: "Regulamentações sobre Cashback no Brasil",
               res: "O cashback é regulamentado pelas mesmas leis que regem os programas de fidelidade e descontos no Brasil. As empresas que oferecem cashback devem seguir as regras estabelecidas pelo Código de Defesa do Consumidor, garantindo transparência e segurança nas transações."
          },
          {
               ask: "O Cashback é Seguro?",
               res: "Sim, o cashback é seguro, especialmente quando você utiliza plataformas confiáveis como o Superbuxx. As transações são protegidas e a privacidade dos seus dados é garantida. Sempre verifique a reputação da plataforma antes de se inscrever."
          },
          {
               ask: "Qual a Diferença entre Cashback e Desconto?",
               res: "A principal diferença entre cashback e desconto é que o desconto reduz o preço da compra no momento da transação, enquanto o cashback devolve uma parte do valor gasto após a compra ser confirmada. Ambos oferecem economia, mas o cashback permite acumular valores que podem ser usados em compras futuras."
          },
          {
               ask: "Como Escolher a Melhor Plataforma de Cashback?",
               res: "Para escolher a melhor plataforma de cashback, considere fatores como a variedade de lojas parceiras, a porcentagem de devolução oferecida, a facilidade de uso da plataforma e a reputação da empresa. O Superbuxx é uma excelente escolha, oferecendo uma ampla gama de lojas e serviços, além de uma interface amigável e segura."
          },
          {
               ask: "O Cashback Expira?",
               res: "Algumas plataformas de cashback podem ter um prazo para utilizar os valores acumulados. No Superbuxx, é importante verificar os termos e condições para entender se há um prazo de expiração para o seu cashback. Em geral, quanto mais cedo você usar seu cashback, melhor."
          },
          {
               ask: "Posso Receber Cashback em Compras Internacionais?",
               res: "Sim, é possível receber cashback em compras internacionais através do Superbuxx. Certifique-se de verificar se a loja internacional é parceira da plataforma e siga os passos indicados para garantir que sua compra qualifique para cashback."
          }
     ]

     let show = "show"
     let expanded = true

     return (
          <div className="container">
               <div className="row">

                    <div className="col-12">
                         <div className="block-header">
                              <h2 className="block-title">Perguntas Frequentes</h2>
                         </div>
                    </div>

                    <div className="col-12">
                         <div className="accordion" id="common-questions-accordion">
                              {faq.map((item, index) => {
                                   // Inicialize as variáveis show e expanded
                                   show = "";
                                   expanded = false;

                                   return (
                                        <div className="accordion-item" key={index}>
                                             <h2 className="accordion-header" id={`heading${index}`}>
                                                  <button
                                                       className="accordion-button"
                                                       type="button"
                                                       data-bs-toggle="collapse"
                                                       data-bs-target={`#collapse${index}`}
                                                       aria-expanded={expanded}
                                                       aria-controls={`collapse${index}`}
                                                  >
                                                       {item.ask}
                                                       <span className="chevron-icon">
                                                            <img src="../src/assets/icons/chevron-down.svg" alt="Chevron Icon" />
                                                       </span>
                                                  </button>
                                             </h2>
                                             <div
                                                  id={`collapse${index}`}
                                                  className={`accordion-collapse collapse ${show}`}
                                                  aria-labelledby={`heading${index}`}
                                                  data-bs-parent="#common-questions-accordion"
                                             >
                                                  <div className="accordion-body">{item.res}</div>
                                             </div>
                                        </div>
                                   );
                              })}
                         </div>
                    </div>
               </div>
               <div className="row">
                    <div className="col-12">
                         <div className="button-see-all">
                              <button className="btn btn-custom">Ver todas</button>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default FaqCashback