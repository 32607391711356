import React, { useEffect, useState } from "react";
import { getSwaarm } from "../../components/functions/GetSwaarm";
import OfferCardPremium from "../../components/misc/OfferCardPremium";
import { getDevice } from "../../components/functions/GetDevice";
import { AuthContext } from "../../contexts/AuthContext";
import { useContext } from "react";
import SbxSpinner from "../common/SbxSpinner";

export default props => {
     const idCategoria = props.id
     const cor = props.cor
     const max = props.max
     const { idIdioma } = useContext(AuthContext)
     const { isoPais } = useContext(AuthContext)
     const [swaarmOffers, setSwaarmOffers] = useState(null);
     let device = getDevice()

     useEffect(() => {
          if (idIdioma && isoPais) {
               getSwaarm(idCategoria, idIdioma, device, isoPais)
                    .then((swaarmData) => {
                         setSwaarmOffers(swaarmData);
                    })
                    .catch((error) => {
                         console.error("Erro ao buscar dados do Swaarm:", error);
                    });
          }
     }, [idIdioma, isoPais]);


     const filteredOffers = swaarmOffers && swaarmOffers.adsTrans ? (max ? swaarmOffers.adsTrans.slice(0, max) : swaarmOffers.adsTrans) : [];

     return (
          <>
               {swaarmOffers === null ? (
                    <SbxSpinner />
               ) : (
                    <>
                         {filteredOffers
                              .map((ad, index) => (
                                   <div key={index}>
                                        <OfferCardPremium
                                             cor={cor}
                                             oName={ad.offer_name}
                                             creative={ad.creative}
                                             info={ad.short_desc}
                                             payout={ad.payout}
                                             offer_id={ad.offer_id}
                                             os={ad.os}
                                             uk={index}
                                        />
                                   </div>
                              ))}
                    </>
               )}
          </>
     );
};
