// Coleção de funções que fazem conversões de dados
import moment from "moment-timezone";

export function getTimeStamp(timezone) {
     // Retorna a data atual em formado de timestamp, já com o horário do time zone recebido como parâmetro
     var agora = new Date();
     var timestamp = agora.toISOString().slice(0, 19).replace('T', ' ');
     var resposta = moment.parseZone(timestamp).tz(timezone).format('YYYY-MM-DD HH:mm:ss');
     return resposta
}

export function getYear4Y(timezone) {
     // Retorna o ano em quatro dígitos YYYY a partir da timezone informada
     var agora = new Date();
     var timestamp = agora.toISOString().slice(0, 19).replace('T', ' ');
     var resposta = moment.parseZone(timestamp).tz(timezone).format('YYYY');
     return resposta
}

export function getMonth2M(timezone) {
     // Retorna o mês em dois digitos MM a partir da timezone informada
     var agora = new Date();
     var timestamp = agora.toISOString().slice(0, 19).replace('T', ' ');
     var resposta = moment.parseZone(timestamp).tz(timezone).format('MM');
     return resposta
}