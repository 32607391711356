import React from "react";

const FaqPopular = () => {
     // Perguntas e respostas
     const faq = [
          {
               ask: "Como posso começar a ganhar dinheiro online com pouco investimento inicial?",
               res: "Começar a ganhar dinheiro online com pouco investimento é possível com plataformas como o Superbuxx. Inscreva-se gratuitamente, explore ofertas de cashback, participe de pesquisas remuneradas e jogue jogos para ganhar dinheiro. Você também pode ganhar dinheiro assistindo a vídeos e utilizando aplicativos de jogos que pagam."
          },
          {
               ask: "É possível ganhar dinheiro online rapidamente ou é um processo gradual?",
               res: "Ganhar dinheiro online pode ser rápido se você se dedicar a atividades como responder pesquisas, assistir a vídeos e participar de ofertas de cashback. No entanto, a consistência é essencial para aumentar seus ganhos ao longo do tempo. Utilize plataformas como o Superbuxx para ganhar dinheiro online agora de forma eficiente."
          },
          {
               ask: "Maneiras de ganhar dinheiro na Internet hoje.",
               res: "O Superbuxx oferece várias maneiras de ganhar dinheiro na Internet, incluindo cashback, pesquisas remuneradas, apostas e jogos online que pagam dinheiro. Explore todas essas opções para maximizar seus ganhos e economizar em suas compras. Descubra como ganhar dinheiro no Instagram, como ganhar dinheiro no YouTube e muito mais."
          },
          {
               ask: "Como posso ganhar dinheiro com o Google?",
               res: "Ganhar dinheiro com o Google pode ser feito através de várias atividades, como Google AdSense para sites e blogs, YouTube para criação de conteúdo e Google Opinion Rewards para responder pesquisas. Use o Superbuxx para descobrir mais maneiras de integrar essas opções e aumentar seus ganhos. Aprenda como ganhar dinheiro na internet e transformar suas atividades online em fontes de renda."
          },
          {
               ask: "Como ganhar dinheiro testando aplicativos e sites?",
               res: "Ganhar dinheiro testando aplicativos e sites é simples com o Superbuxx. Inscreva-se na plataforma, explore as ofertas disponíveis e complete tarefas de usabilidade para ganhar dinheiro na internet testando produtos. Feedback honesto é recompensado pelas empresas que buscam melhorar seus produtos. Descubra os melhores sites para ganhar dinheiro no PayPal e ganhe dinheiro online clicando em anúncios."
          },
          {
               ask: "Como ganhar dinheiro online com jogos?",
               res: "Existem muitos jogos online que pagam dinheiro real. No Superbuxx, você pode encontrar jogos para ganhar dinheiro jogando e completando missões. Explore títulos populares e participe de torneios para maximizar seus ganhos jogando. Aprenda como ganhar dinheiro jogando e aproveite aplicativos de jogos que pagam."
          },
          {
               ask: "Quais aplicativos para ganhar dinheiro online?",
               res: "A Superbuxx reúne os melhores aplicativos para ganhar dinheiro online. Desde apps de cashback até jogos que pagam, você encontrará diversas opções para transformar seu tempo livre em dinheiro. Baixe os aplicativos recomendados e comece a ganhar hoje mesmo. Explore aplicativos de jogos para ganhar dinheiro em 2024 e mini trabalhos online para aumentar sua renda."
          },
          {
               ask: "Qual é o melhor site de apostas?",
               res: "Para encontrar o melhor site de apostas, registre-se na Superbuxx e aproveite as ofertas especiais e saques rápidos de nossos parceiros de apostas. A plataforma conecta você aos melhores sites de apostas online, garantindo uma experiência segura e lucrativa. Descubra cassinos online, apostas esportivas e sites de bet confiáveis."
          },
          {
               ask: "O que significa cashback?",
               res: "Cashback significa “dinheiro de volta” em inglês. É um sistema onde você recebe uma porcentagem do valor gasto em compras de volta. No Superbuxx, aproveite as ofertas de cashback em diversas categorias e economize enquanto faz suas compras. Explore cashback em cartões de crédito, cashback no mercado pago e muito mais."
          },
          {
               ask: "Como ganhar dinheiro respondendo a questionários?",
               res: "Responder a questionários é uma maneira eficaz de ganhar dinheiro online. No Superbuxx, você pode participar de pesquisas remuneradas oferecidas por empresas que buscam suas opiniões. Inscreva-se, complete as pesquisas e acumule dinheiro rapidamente. Utilize as melhores plataformas de pesquisas remuneradas e siga dicas para maximizar seus ganhos."
          },
          
          
     ]

     let show = "show"
     let expanded = true

     return (
          <div className="container">
               <div className="row">

                    <div className="col-12">
                         <div className="block-header">
                              <h2 className="block-title">Perguntas Frequentes</h2>
                         </div>
                    </div>

                    <div className="col-12">
                         <div className="accordion" id="common-questions-accordion">
                              {faq.map((item, index) => {
                                   // Inicialize as variáveis show e expanded
                                   show = "";
                                   expanded = false;

                                   return (
                                        <div className="accordion-item" key={index}>
                                             <h2 className="accordion-header" id={`heading${index}`}>
                                                  <button
                                                       className="accordion-button"
                                                       type="button"
                                                       data-bs-toggle="collapse"
                                                       data-bs-target={`#collapse${index}`}
                                                       aria-expanded={expanded}
                                                       aria-controls={`collapse${index}`}
                                                  >
                                                       {item.ask}
                                                       <span className="chevron-icon">
                                                            <img src="../src/assets/icons/chevron-down.svg" alt="Chevron Icon" />
                                                       </span>
                                                  </button>
                                             </h2>
                                             <div
                                                  id={`collapse${index}`}
                                                  className={`accordion-collapse collapse ${show}`}
                                                  aria-labelledby={`heading${index}`}
                                                  data-bs-parent="#common-questions-accordion"
                                             >
                                                  <div className="accordion-body">{item.res}</div>
                                             </div>
                                        </div>
                                   );
                              })}
                         </div>
                    </div>
               </div>
               <div className="row">
                    <div className="col-12">
                         <div className="button-see-all">
                              <button className="btn btn-custom">Ver todas</button>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default FaqPopular