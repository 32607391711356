export async function emailSender(email, titulo, texto, html) {
     try {
          const response = await fetch('https://www.superbuxx.com:3030/send-mail', {
               method: 'POST',
               headers: {
                    'Content-Type': 'application/json'
               },
               body: JSON.stringify({
                    destino: email,
                    titulo: titulo,
                    texto: texto,
                    html: html,
               })
          });
          return response
     } catch (erro) {
          // gravar em log
          console.log("erro: ", erro)
     }  
}