export async function addNewAdEvent(click_id, uid, event_id) {
     // Função que grava trackeamento de evento de anúncio
     try {
          const response = await fetch('https://www.superbuxx.com:3030/add-new-ad-event', {
               method: 'POST',
               headers: {
                    'Content-Type': 'application/json'
               },
               body: JSON.stringify({
                    clickId: click_id,
                    eventId: event_id,
                    uid: uid
               })
          });
          return response
     } catch (erro) {
          // gravar em log
          console.log("erro: ", erro)
     }  
}