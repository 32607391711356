import React, { Fragment, useEffect, useState } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

const TicketsClosedList = ({ transactions }) => {
     const {t} = useTranslation()
     const sortedTxn = [...transactions].sort((a, b) => {
          const timeA = new Date(a.update).getTime();
          const timeB = new Date(b.update).getTime();
          return timeB - timeA; // Ordena do mais recente para o mais antigo
     });


     return (
          <Fragment>
               <div class="buxx-table">
                    <table class="custom-table">
                         <thead>
                              <tr>
                                   <th>
                                        <div class="table-label">{t('lastupdate')}</div>
                                   </th>
                                   <th>
                                        <div class="table-label">#{t('ticketsing')}</div>
                                   </th>
                                   <th>
                                        <div class="table-label">{t('offer')}</div>
                                   </th>
                                   <th>
                                        <div class="table-label">{t('statuslabel')}</div>
                                   </th>
                              </tr>
                         </thead>
                         <tbody>
                              {sortedTxn.map((txn) => (
                                   <tr key={txn.ticket_id}>
                                        <td><span style={{ fontWeight: `${txn.font_style}` }}>{format(new Date(txn.update), 'dd-MM-yy | HH:mm')}</span></td>
                                        <td><span style={{ fontWeight: `${txn.font_style}` }}><a href={`ticketreply/${txn.ticket_id}`}>{txn.ticket_id}</a></span></td>
                                        <td><span style={{ fontWeight: `${txn.font_style}` }}>{txn.offer_name}</span></td>
                                        <td><span style={{ fontWeight: `${txn.font_style}` }}>{txn.status}</span></td>
                                   </tr>
                              ))}
                         </tbody>
                    </table>
               </div>

          </Fragment>
     )
}
export default TicketsClosedList;