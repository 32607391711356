export const getProvider = (providerId) => {
     const providers = {
          1: {id: 1, nome: "Notik Offerwall"},
          2: {id: 2, nome: "Torox Offerwall"},
          3: {id: 3, nome: "Adgem Offerwall"},
          4: {id: 4, nome: "Revu Offerwall"},
          10: {id: 10, nome: "Super Buxx"},
          20: {id: 20, nome: "AppsPrize"},
          21: {id: 21, nome: "Revtoo"},
          22: {id: 22, nome: "MyChips"},
          23: {id: 23, nome: "AdGate"},
     }
     return providers[providerId] ? providers[providerId].nome : null;
}