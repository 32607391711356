export async function getIsIpBlackList(ip) {
     // Função que retorna se ip está ok para uso ou não (isipok = true / false)
     try {
          const response = await fetch("https://www.superbuxx.com:3030/get-is-blacklist-ip/" + ip, {
               method: "GET",
          });
          const res = await response.json();
          return res
     } catch (error) {
          console.error(error)
          return false
     }
}