import React, { Fragment } from "react";
import { getDevice } from "../../components/functions/GetDevice";


const Pagina1 = props => {

     const userAgent = window.navigator.userAgent;
     const device = getDevice();
    
     return (
          <Fragment>
               <div className="p-5" style={{marginTop:"6rem"}}>
                    <h1>Ambiente de verificação</h1>
                    <p><strong>User-Agent: </strong>{userAgent}</p>
                    <p><strong>Platform: </strong>{device}</p>
               </div>
          </Fragment>
     )
}

export default Pagina1