import React from "react";
import { Fragment } from "react";

const Parceiros = () => {
     return (
          <Fragment>
               <div className="row mb-3">
                    <div className="col-lg-3 col-sm-6">
                         <a href="/torox" style={{ cursor: "pointer" }}>
                              <div className="parceiro-itens">
                                   <img className="img-fluid" src="../src/assets/temp/torox-icon.png" alt="Torox" width="100%"  height="100%" />

                              </div>
                         </a>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                         <a href="/adgem" style={{ cursor: "pointer" }}>
                              <div className="parceiro-itens">
                                   <img className="img-fluid" src="../src/assets/temp/adgem.png" alt="Adgem" width="100%"  height="100%" />
                              </div>
                         </a>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                         <a href="/revu" style={{ cursor: "pointer" }}>
                              <div className="parceiros-itens-color">
                                   <img className="img-fluid" src="../src/assets/temp/revu.png" alt="Revu" width="100%"  height="100%" />
                              </div>
                         </a>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                         <a href="/notik" style={{ cursor: "pointer" }}>
                              <div className="parceiros-itens-color-2">
                                   <img className="img-fluid" src="../src/assets/temp/notikme.png" alt="Notik" width="100%"  height="100%" />
                              </div>
                         </a>
                    </div>
               </div>
               <div className="row">
                    <div className="col-lg-3 col-sm-6">
                         <a href="/revtoo" style={{ cursor: "pointer" }}>
                              <div className="parceiros-itens-color-2">
                                   <img className="img-fluid" src="../src/assets/temp/revtoo.webp" alt="Revtoo" width="100%"  height="100%" />
                              </div>
                         </a>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                         <a href="/appsprize" style={{ cursor: "pointer" }}>
                              <div className="parceiros-itens-color">
                                   <img className="img-fluid" src="../src/assets/temp/appsprize.png" alt="Appsprize" width="100%"  height="100%" />
                              </div>
                         </a>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                         <a href="/mychips" style={{ cursor: "pointer" }}>
                              <div className="parceiros-itens-color-2">
                                   <img className="img-fluid" src="../src/assets/temp/mychips.webp" alt="MyChips" width="100%"  height="100%" />
                              </div>
                         </a>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                         <a href="/adgate" style={{ cursor: "pointer" }}>
                              <div className="parceiro-itens">
                                   <img className="img-fluid" src="../src/assets/temp/adgate.png" alt="Adgate" width="100%"  height="100%" />
                              </div>
                         </a>
                    </div>
               </div>

          </Fragment>
     )
}

export default Parceiros