import Header from "../components/layout/Header";
import Content from "../components/layout/Content";
import Footer from "../components/layout/Footer";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "../contexts/AuthContext";
import { ToastContainer } from "react-toastify";

const App = () => (
     
          <BrowserRouter>
               <AuthProvider>
                    <Header /> 
                    <Content />
                    <Footer />
               </AuthProvider>
               <ToastContainer autoClose={3000} />
          </BrowserRouter>
          

)

export default App