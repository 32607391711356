import "./offerwalls.css"
import React, { Fragment, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { recLastActv } from "../../components/functions/RecLastActv";
import { useEffect } from "react";
import SeoTags from "../common/SeoTags";

const AppSamuraiOfferwall = props => {
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario
     const url = 'https://web-offerwall.appsprize.com/?token=as9GullxZxEpFPud3f35iRZdZOVCHgVH96MHLBpyx7&userid=' + uid

     useEffect(() => {
          if (uid) {
               // Registra ação
               recLastActv(uid, "Entrou ow AppsPrize")
          }
     }, [uid])

     return (
          <Fragment>
               <SeoTags
                    title="Appsprize Offerwall"
                    description="Appsprize Offerwall"
                    url="https://www.superbuxx.com/appsprize"
               />
               <div className="mt-5">
                    <div className="offerwall">
                         <iframe
                              src={url}
                              style={{
                                   height: "100vh",
                                   width: "100%",
                                   margin: 0,
                                   padding: 0,
                                   border: 0
                              }}
                         >
                         </iframe>
                    </div>
               </div>
          </Fragment>

     )

}
export default AppSamuraiOfferwall