// Formulário de edição de dados do usuário
import { AuthContext } from "../../contexts/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { getUserData } from "../../components/functions/GetUserData";
import { paisById } from "../../components/functions/GetPaisData";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";


const VerifyCell = props => {
     const { t } = useTranslation()
     const { usuario } = useContext(AuthContext)
     const { uid } = usuario
     const [cellNumber, setCellNumber] = useState("")
     const [ddiNumber, setDdiNumber] = useState("")
     const [idPais, setIdPais] = useState("")
     const [verifyNumber, setVerifyNumber] = useState("")
     const [avisoCell, setAvisoCell] = useState("")
     const [avisoCodigo, setAvisoCodigo] = useState("")
     const [codeDisabled, setCodeDisabled] = useState(true)
     const [cellDisabled, setCellDisabled] = useState(false)
     const [classeAvisoCell, setClasseAvisoCell] = useState("")
     const [classeAvisoCode, setClasseAvisoCode] = useState("")
     const [isCellVerified, setIsCellVerified] = useState(false)
     const [spinner, setSpinner] = useState(true)
     const [verifyTime, setVerifyTime] = useState(null)
     const limiteDiasTroca = 30 //número de dias em que é permitido trocar o número do celular
     const [show, setShow] = useState(false);
     const [modalShow, setModalShow] = useState(false)
     const handleShow = () => setModalShow(true);
     const handleClose = () => setModalShow(false);



     // Recupera dados de user e detecta se Cell já é verificado
     async function pegaDados() {
          try {
               const data = await getUserData(uid);
               if (!data) {
                    setSpinner(true)
               } else {
                    setSpinner(false)
                    setIsCellVerified(data.is_cell_verified)
                    setIdPais(data.id_country || '');
                    if (isCellVerified) {
                         setCellNumber(data.cell_number)
                         setVerifyTime(data.cell_verify_time)
                         setCellDisabled(true)
                         setAvisoCell(t('isphoneverified'))
                         setClasseAvisoCell("sbx-input-msg-good")
                    }
               }
          } catch (error) {
               console.error("Erro ao obter os dados do usuário:", error);
          }
     }
     pegaDados()

     const handleVerify = async () => {
          if (cellNumber === "") {
               return null
          }
          try {
               const response = await fetch('https://www.superbuxx.com:3030/cell-verify', {
                    method: 'POST',
                    headers: {
                         'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                         phone: cellNumber,
                         uid: uid,
                         ddi: ddiNumber,
                         id_country: idPais,
                    })

               });
               // Exibe mensagem com link para abrir modal onde será digitado o código enviado por SMS
               console.log("response: ", response)
               if (response.ok === true) {
                    setCodeDisabled(false)
                    setAvisoCell(t('smsenvsucesso'))
                    setAvisoCodigo(t('typesmscodesent'))
                    setClasseAvisoCell("sbx-input-msg-good")
                    setClasseAvisoCode("sbx-input-msg-good")
                    setCellDisabled(true)
               }

               if (response.ok === false) {
                    setAvisoCell(t('smsresperro1'))
                    setClasseAvisoCell("input-msg-erro")
               }

               const responseData = await response.json();
               if (responseData.status === "exist") {
                    setAvisoCell(t('smsresperro2'))
                    setClasseAvisoCell("input-msg-erro")
               }
          } catch (error) {
               console.error('Erro ao enviar SMS:', error);
          }
     };

     const handleToken = async () => {
          try {
               const response = await fetch('https://www.superbuxx.com:3030/twilio-token-verify', {
                    method: 'POST',
                    headers: {
                         'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                         token: verifyNumber,
                         phone: cellNumber,
                         uid: uid,
                         ddi: ddiNumber,
                    })

               });
               // Exibe mensagem com link para abrir modal onde será digitado o código enviado por SMS
               console.log("response: ", response)
               if (response.ok === true) {
                    setAvisoCodigo(t('smsresponseok1'))
                    setClasseAvisoCode("input-msg-good")
               } else {
                    setAvisoCodigo(t('smsresperro4'))
                    setClasseAvisoCode("input-msg-erro")
               }
          } catch (error) {
               console.error('Erro ao enviar SMS:', error);
          }
     }

     useEffect(() => {
          if (idPais) {
               paisById(idPais)
                    .then((pais) => {
                         setDdiNumber(pais.ddi)
                    })
                    .catch((error) => {
                         console.error('Erro ao buscar o país:', error);
                    });
          }
     }, [idPais]);

     const handleCellNumberChange = (e) => {
          const { value } = e.target;
          const lastChar = value.charAt(value.length - 1);
          const isNumeric = /^\d+$/.test(lastChar);
          const isDeletingFirstChar = e.target.selectionStart === 0;
          const isEmptyChar = lastChar === "";

          if (isEmptyChar) {
               setAvisoCell("");
          } else if (isNumeric && !isDeletingFirstChar) {
               setAvisoCell("")
          } else {
               e.preventDefault()
               setAvisoCell(t('onlynumbers2'))
               setClasseAvisoCell("input-msg-erro")
          }

          const formattedNumber = value.replace(/\D/g, "");
          setCellNumber(formattedNumber);

          return formattedNumber;
     };

     // Controla permissão de troca de número do celular em relação ao prazo determinado (30 dias - vide variável no topo da função)
     const prazoTroca = () => {
          if (verifyTime) {
               if (verifyTime) {
                    let currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0); // set time to midnight
                    const dtInicial = new Date(verifyTime)
                    dtInicial.setHours(0, 0, 0, 0); // set time to midnight
                    let timeGap = currentDate - dtInicial
                    const diasEmMilisegundos = 1000 * 60 * 60 * 24;
                    const diasTotais = Math.floor(Math.abs(timeGap) / diasEmMilisegundos);
                    const prazo = limiteDiasTroca - diasTotais
                    return prazo
               }
          }
     }
     let diasRestantes = prazoTroca()

     const setActionWhenVerified = () => {
          if (diasRestantes <= 0) {
               // mostra link
               return <div className="input-msg-link" onClick={handleShow}>
                    {t('clickheretochangephone')}
               </div>
          } else {
               // mostra aviso
               return <div className="input-msg-erro">{t('verifyphonecountertxt1', {daysleft: diasRestantes,})} <strong>{t('verifyphonecountertxt2')} {new Date(verifyTime).toLocaleDateString('pt-BR')}</strong></div>
          }
     }
     let PrizeOrLink = setActionWhenVerified()

     const handleApagar = async () => {
          try {
               const response = await fetch('https://www.superbuxx.com:3030/cell-number-reset', {
                    method: 'PUT',
                    headers: {
                         'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                         uid: uid
                    })

               });
               // Exibe mensagem com link para abrir modal onde será digitado o código enviado por SMS
               console.log("response: ", response)
               if (response.ok === true) {
                    setAvisoCell(t('avisocell1'))
                    setClasseAvisoCell("input-msg-good")
                    setIsCellVerified(false)
                    setVerifyNumber("")
                    setCellNumber("")
                    setCellDisabled(false)
                    setShow(false)
               }

          } catch (error) {
               console.error('Erro ao excluir número:', error);
          }
     }

     return (
          <Fragment>
               <div className="container-fluid banner-header text-white text-center py-3">
                    <h1>{t('verifyphonetitleh1')}</h1>
               </div>

               <div className="container-fluid container-spacing">
                    <div className="container-phone-form">
                         <div className="col-12 mb-4">
                              <div className="input-field">
                                   <label htmlFor="celular" className="form-label">{t('telnumber')}</label>
                                   <div className="input-group">
                                        <span className="input-group-text">+{ddiNumber}</span>
                                        <input
                                             type="text"
                                             className="form-control"
                                             name="celular"
                                             id="celular"
                                             value={cellNumber}
                                             maxLength={50}
                                             onChange={handleCellNumberChange}
                                             disabled={cellDisabled}
                                             onFocus={() => { setAvisoCell("") }}
                                             style={{margin:"0"}}
                                        />
                                   </div>
                              </div>
                              <div className="row sbx-warning-area mt-2">
                                   <div className={classeAvisoCell} style={{ cursor: "default" }}>{avisoCell}</div>
                              </div>
                         </div>

                         {isCellVerified ? (
                              <div className="row sbx-warning-area">
                                   <small>{PrizeOrLink}</small>
                              </div>
                         ) : (
                              <>
                                   <button className="btn btn-to-check" onClick={handleVerify} disabled={cellDisabled}>{t('verify')}</button>
                                   <div className="col-12 mb-5">
                                        <div className="input-field">
                                             <label htmlFor="formControlInput" className="form-label">{t('verificode')}</label>
                                             <input
                                                  type="text"
                                                  className="form-control"
                                                  name="verifycode"
                                                  id="verifycode"
                                                  value={verifyNumber}
                                                  maxLength={6}
                                                  onChange={(e) => setVerifyNumber(e.target.value)}
                                                  onClick={(e) => setAvisoCodigo("")}

                                             />
                                        </div>
                                        <div className="row sbx-warning-area mt-1">
                                             <div className={classeAvisoCode} style={{ cursor: "default" }}>{avisoCodigo}</div>
                                        </div>
                                   </div>
                                   <button type="button" className="btn btn-light col-12 mt-2 btn-to-check-2" onClick={handleToken} disabled={codeDisabled}>{t('validate')}</button>

                                   <div className="col-12 container-sms">
                                        <span className="me-2">{t('nosmsrecvd')}</span><span className='sbx-custom-link' onClick={handleVerify}>{t('clickhere')}</span>
                                   </div>
                              </>

                         )}
                    </div>

                    <div className="col-12 banner-footer mt-5">
                         <img src="../src/assets/images/banner-footer.png" alt="" className="img-fluid" />
                    </div>
               </div>

               {modalShow && (
                    <div
                         className="modal fade show"
                         id="verifyCellModal"
                         tabIndex="-1"
                         aria-labelledby="verifyCellModalLabel"
                         aria-hidden="true"
                         style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    >
                         <div className="modal-dialog">
                              <div className="modal-content">
                                   <div className="modal-header">
                                        <h5 className="modal-title" id="verifyCellModalLabel">{t('verifyphonemodaltitle')}</h5>
                                   </div>
                                   <div className="modal-body text-center">
                                        <p>{t('aoclicarem')} <strong>"{t('alterarmaiusc')}"</strong> {t('verifyphonemodaltxt1')}</p>
                                        <p><strong>{t('atencaomaiusc')}:</strong> {t('verifyphonemodaltxt2')}</p>
                                        <p>{t('verifyphonemodaltxt3')}</p>
                                   </div>
                                   <div className="modal-footer">
                                        <button type="button" className="buxx-btn" data-dismiss="modal" onClick={handleClose}>{t('tocancelbt')}</button>
                                        <button type="button" className="buxx-btn outlined" onClick={handleApagar}><strong>{t('alterarmaiusc')}</strong></button>
                                   </div>
                              </div>
                         </div>
                    </div>
               )}

          </Fragment>
     )
}

export default VerifyCell