import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyD0zP9ozD943ZghQ2ie_Ihk9ZeUmdDglUY",
  authDomain: "super-buxx.firebaseapp.com",
  projectId: "super-buxx",
  storageBucket: "super-buxx.appspot.com",
  messagingSenderId: "407944602765",
  appId: "1:407944602765:web:07da90e359ce594a44aec4"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);