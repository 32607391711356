import React from "react";
import { useTranslation } from "react-i18next";

const HowPopular = () => {
     const {t} = useTranslation()
     return (

          <div id="como-funciona" className="container text-center mt-5">

               <h2 className="title">COMO GANHAR DINHEIRO RAPIDAMENTE ONLINE</h2>

               <div className="cards">
                    <div className="card-content">
                         <div className="icon mb-3">
                              <img src="../src/assets/images/como-funciona-1.png" alt="Register Icon" className="img-fluid" />
                         </div>
                         <h5 className="card-title">INSCREVA-SE NA SUPERBUXX</h5>
                         <p className="card-text">Crie uma conta na nossa plataforma para acessar diversas maneiras de como ganhar dinheiro online rapidamente.</p>
                    </div>
                    <div className="card-divider">
                         <div className="card-divider-icon">
                              <img src="../src/assets/icons/chevron-rigth.svg" alt="" />
                         </div>
                    </div>
                    <div className="card-content">
                         <div className="icon mb-3">
                              <img src="../src/assets/images/como-funciona-2.png" alt="Earn Buxx Icon" className="img-fluid" />
                         </div>
                         <h5 className="card-title">EXPLORE AS OFERTAS DISPONÍVEIS</h5>
                         <p className="card-text">Baixe aplicativos, participe de pesquisas remuneradas, jogue jogos que pagam e aproveite as ofertas de cashback.</p>
                    </div>
                    <div className="card-divider">
                         <div className="card-divider-icon">
                              <img src="../src/assets/icons/chevron-rigth.svg" alt="" />
                         </div>
                    </div>
                    <div className="card-content">
                         <div className="icon mb-3">
                              <img src="../src/assets/images/como-funciona-3.png" alt="" className="img-fluid" />
                         </div>
                         <h5 className="card-title">GANHE DINHEIRO</h5>
                         <p className="card-text">Complete as atividades e veja seu saldo crescer. Retire seus ganhos facilmente através do PayPal ou PIX.</p>
                    </div>
               </div>
          </div>
     )
}

export default HowPopular